import React, { useState, useEffect,Component } from "react";
import { Link } from 'react-router-dom';

import aboutimg1 from '../../../assets/img/about/blog/255x200/about-section-1.jpg';
import aboutimg2 from '../../../assets/img/about/blog/255x200/about-section-2.jpg';
import aboutimg3 from '../../../assets/img/about/blog/255x200/about-section-3.jpg';
import axios from 'axios';
import ReactCountryFlag from "react-country-flag"

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
  } from "react-google-maps";
  import mapStyles from './mapStyles'


  function Map(props) {
    const [selectedPark, setSelectedPark] = useState(null);
  
    useEffect(() => {
      const listener = e => {
        if (e.key === "Escape") {
          setSelectedPark(null);
        }
      };
      window.addEventListener("keydown", listener);
  
      return () => {
        window.removeEventListener("keydown", listener);
      };
    }, []);
  
console.log("lksdnkf",props)
    return (
      <GoogleMap
        defaultZoom={4}
        defaultCenter={{ lat: 48.8114633 , lng: 34.8990059}}
        options={{ styles: mapStyles }}
      >
        {props.dealerData.map(park => (
          <Marker
            key={park.ID}
            position={{
              lat: park.Dealer_Lat,
              lng: park.Dealer_Long
            }}
            onClick={() => {
              setSelectedPark(park);
              console.log("park123",park)
            }}
            icon={{
              url:  "https://img.icons8.com/color/48/000000/map-pin.png",
              scaledSize: new window.google.maps.Size(50, 50)
            }}
          >

<InfoWindow
            onCloseClick={() => {
              setSelectedPark(null);
            }}
            position={{
              lat: park.Dealer_Lat,
              lng: park.Dealer_Lng
            }}
          >
            <div>
              <h2 className="font-[Lato-Medium] ">{park.Dealer_Name}</h2>
            </div>
          </InfoWindow>
          </Marker>

          
        ))}
  

      </GoogleMap>
      
    );
  }
  
  const MapWrapped = withScriptjs(withGoogleMap(Map));

class History extends Component {    
    constructor(props) {
    super(props);
   this.state = {
     dealerData:[],
     selectedLat:37.8604039,
     selectedLng:32.5372508,

    defaultProps : {
        center: {
          lat: 37.8604039,
          lng: 32.5372508
        },
        zoom: 11
      },
      showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {}
     }
}

onMarkerClick = (props, marker, e) =>{
console.log("nsldlfk",props, marker, e)
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    })
}

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

async componentDidMount() {
   await this._getBlog()
   window.scrollTo(0, 0);

}

_changeMaps= async (lat,long) => {
    this.setState({defaultProps: {
        center: {
          lat: lat,
          lng: long
        },
        zoom: 11
      } ,
      selectedLat:lat,
      selectedLng:long,

    
    },()=> {
        console.log("sjndfjkls",this.state.defaultProps)
    })
    }
    _setMap = async (item) => {

    this.setState({
        openDetail:true,
        mapDetail:item
    })
}
    

_getBlog = async () => {
try{
await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getDealer/select/`)
 .then(res => {
     this.setState({dealerData: res.data })})
}
catch (error) {
 
 console.log("errorAS" + error)
}
}

    render() {
       
          const AnyReactComponent = ({ text }) => <div>{text}</div>;

        return (
            <section className="aboutus section-padding">
                        
                                     
                        <div className="section-header-style-2 ">
                        <h6 className="text-light-green sub-title font-[Lato-Bold] m-1 text-[#c5aa77]">Bayilerimiz</h6>
                    </div>
      <div className="col-lg-12">
    

       <div style={{ height: '80vh', width: '100%' }} className="ex-collection-box mb-xl-20">

       <MapWrapped
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC51aK_7Y-OBpJzZ-O_5-qDkxTsx8vEW3s&v=3.exp&libraries=geometry,drawing,places}`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          dealerData = {this.state.dealerData}
          currentLat={this.state.selectedLat}
          currentLng={this.state.selectedLng}
        />

     


  
                            </div>
                            </div>

              

                <div className="container">


                <div className="grid grid-cols-4 gap-4">

{this.state.dealerData.map((item, i) => (
    <button onClick={()=> this._changeMaps(item.Dealer_Lat,item.Dealer_Long)} key={i} className="items-center justify-center">
        <div  style={{backgroundColor:'white',borderRadius:10,flexDirection:'row',margin:10}} className="row align-items-center items-center justify-center">
        <h5 className="text-light-black p-1 font-[Lato-Medium]">{i + 1}.Bayi </h5>
        <span className="text-light-green">{item.Dealer_Name}</span>
        <div className="border-2 rounded-lg m-1">
           <ReactCountryFlag svg style={{ width: '5em', height: '5em' }} countryCode={item.Dealer_Country} />
           </div>
        </div>
    </button>
))}
</div>
            

               
                 
                </div>
            </section>
        );
    }
}

export default History