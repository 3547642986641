import React, { Component } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #000',
  boxShadow: 24,
};

class ContentKVKK extends Component {

  constructor(props) {
      super(props)
      this.state = {
          open: false,
          recipesData:null,
          certifica:[]
      }
  }

  async componentDidMount() {
    await this._getCertifica()
}


_getCertifica = async () => {
  try{
    
 await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getCertifica/select/`).then((res) => {
       this.setState({certifica: res.data })   
          
      })
 }
 catch (error) {
   
   console.log("_getCertifica" + error)
 }

}

render(){
  return (
<Modal
  open={this.props.openKVKKVisible}
  onClose={this.props._closeKVKKVisible}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box className='w-1/2' sx={style}>
    <div className="bg-[#fff] p-3" >
      <p className="text-center sm:text-[10px] md:text-[20px] lg:text-[30px] font-['Alfa'] text-[#1b2542]">Kalite Belgelerimiz</p>
    </div>
    <div className="bg-[#ebe5df] w-full p-4" >

    <div  className="grid lg:grid-cols-3  md:grid-cols-3 sm:grid-cols-3 grid-cols w-full p-1">
    {this.state.certifica.map((item, i) => (
      <div>
                                 <img key={i} src={'https://buharasubdomain.stechomeyazilim.info//' + item.Certifica_Url} className="img-fluid" alt="product-img" />
                             </div>
                              ))}
                               </div>
   
    </div>
  </Box>
</Modal>
  )
}
};

export default ContentKVKK;
