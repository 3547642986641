import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Content from '../sections/restaurant/Content';
import Sidebar from "../../Sidebar";
import { useLocation, Link } from "react-router-dom";

const Restaurant = () => {
        const location = useLocation();
        const state = location.state;
        window.scrollTo(0, 0);

        return (
            <Fragment>
                <MetaTags>
                    <title> { state != null ? state._item.Recipes_Title :null } - Buhara Baharat | Tarif</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
               
                { state != null ?  <Content _recipesReview={state._recipesReview} _item={state._item}/> : <Content /> }
                <Footer/>
            </Fragment>
        );
    }


export default Restaurant;