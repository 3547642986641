import React, { Component } from 'react';
import { processRatingStars, fetchFlavorIcon } from '../../../helper/helper';

class Details extends Component {
    render() {

        return (
            <section className="restaurent-details  u-line">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading padding-tb-10">
                                <h3 className="text-light-black title fw-700 no-margin">{this.props.recipesData.Recipes_Title}</h3>
                              
                                {/*this.props._recipesReview.length > 0 ? <div className="head-rating">
                                {processRatingStars(this.props._recipesReview[0].reviewValue)}
                                <span className="text-light-black fs-12 rate-data">{this.props.recipesData.Recipes_Rewies.length} değerlendirme</span>
                                </div> :  <div className="rating"> <span className="fs-16 text-yellow">
                                        <i className="fas fa-star" />
                                    </span>
                                        <span className="fs-16 text-yellow">
                                            <i className="fas fa-star" />
                                        </span>
                                        <span className="fs-16 text-yellow">
                                            <i className="fas fa-star" />
                                        </span>
                                        <span className="fs-16 text-yellow">
                                            <i className="fas fa-star" />
                                        </span>
                                        <span className="fs-16 text-dark-white">
                                            <i className="fas fa-star" />
                                        </span>
                                        <span className="text-light-black fs-12 rate-data">{this.props.recipesData.Recipes_Rewies.length} değerlendirme</span>
                                    </div> */}
                            </div>
                            <div className="restaurent-logo">
                                <img src={'https://buharasubdomain.stechomeyazilim.info/' + this.props.recipesData.Recipes_Image} className="img-fluid" alt="#" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Details;