import React, { Component } from 'react';
import HowToPrepareMatter from "./HowToPrepareMatter";

import WhatToShop from "./WhatToShop";
import ShareModal from "./ShareModal";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faHeart, faShare } from '@fortawesome/free-solid-svg-icons'
import Moment from 'moment'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';


class HowToPrepare extends Component {
  constructor(props) {
      super(props)
      this.state = {
          open: false,
          recipesData:null,
          recipesDataShoppingList:[],
          openShareModalVisible:false
      }
  }

  async componentDidMount() {
    await this._getReciepsData()
    await this._getReciepsShopping()
}

_getReciepsData = async () => {
  try{
      console.log("c1232c13",this.props.recipesData.ID)

  await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipesData/search/${this.props.recipesData.ID}`)
   .then((res) => {
      console.log("lknsdlkfn",res.data[0])
       this.setState({ recipesData : res.data[0] })   
          
      })
 }
 catch (error) {
   console.log("errorAS_getReciepsData" + error)
 }
}

_getReciepsShopping = async () => {
  try{
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();

  await axios.get(`https://buharaapi.stechomeyazilim.info:5210/shoppingListCheck/select/${this.props.recipesData.ID}`)
   .then((res) => {
       this.setState({recipesDataShoppingList: res.data, ipAdress : data.ip })   
          
      })
 }
 catch (error) {
   console.log("_getReciepsShopping" + error)
 }
}



  _saveShoppingList= async (ID) => {

    var testDateUtc = Moment.utc(new Date());       
    var localDate = Moment(testDateUtc).local();

    try{
        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipesProducts/select/${this.props.recipesData.ID}`)
         .then(async(res) => {
          const response = await fetch('https://api.ipify.org?format=json');
          const data = await response.json();

            res.data.map((item, i) => 
            {                 

                return(
            fetch("https://buharaapi.stechomeyazilim.info:5210/recipesShoppingList/insert", {
            method: "post",
            body: JSON.stringify({
                User_UUID: data.ip, // This is the body part
                Recipes_Products_ID: item.ID,
               Created_DateTime: localDate,
               Recipes_ID:this.props.recipesData.ID
            }),
            headers: { "Content-Type": "application/json" }
            })
            .then(async(res) => {
              await this._getReciepsData()
              await this._getReciepsShopping()
   
               })
            .catch(err => console.log(err))
             )})
           

             this.showToast('Buhara Baharat', "Tarifi başarıyla alışveriş listesine eklediniz!", 'success')

         })

}
catch (error) {
  
  console.log("errorAS_saveShoppingList" + error)
}


}


_openShare= () => {
  this.setState({ openShareModalVisible: true })   
}

_closeShare= () => {
  this.setState({ openShareModalVisible: false })   
}

_deleteShopping= async (ID) => {
  try{

      console.log("_deleteFavori",ID)

      this.state.recipesDataShoppingList.map(async(item, i) => {
        await axios.delete(`https://buharaapi.stechomeyazilim.info:5210/shoppingList/delete/${item.ID}`)
        .then(async(res) => {
  
          await this._getReciepsData()
          await this._getReciepsShopping()
  
        })
      })
      this.showToast('Buhara Baharat', "Tarifi başarıyla alış listesinden çıkardınız!", 'false')
 }
 catch (error) {
   console.log("errordeleteFavori" + error)
 }  
}

_deleteFavori= async (ID) => {
  try{

      console.log("_deleteFavori",ID)
      await axios.delete(`https://buharaapi.stechomeyazilim.info:5210/recipes/delete/${ID}`)
      .then(async(res) => {
        await this._getReciepsData()
        await this._getReciepsShopping()
            })
  
      this.showToast('Buhara Baharat', "Tarifi başarıyla favorilerden kaldırdınız!", 'false')

 }
 catch (error) {
   console.log("errordeleteFavori" + error)
 }  
}

showToast(event, value, type) {
  if(type == "false"){
    toast.error(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }else{
    toast.success(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }

}


   _saveRecipes = async (ID) => {
    try{
      console.log("c1232c13",this.props.recipesData.ID)
      var testDateUtc = Moment.utc(new Date());       
      var localDate = Moment(testDateUtc).local();

      
      const response = await fetch('https://api.ipify.org?format=json');
      const dataIP = await response.json();

  
      const data = {
          Users_Recipes_Fav_UUID: dataIP.ip, // This is the body part
          Recipes_ID: ID,
          Created_DateTime: localDate  
        }

        console.log("data1231",data)
        await axios.post(`https://buharaapi.stechomeyazilim.info:5210/recipesFav/insert`, data).then(async(res) => {

        this.showToast('Buhara Baharat', "Tarifi başarıyla favoriye eklediniz!", 'success')
       
        await this._getReciepsData()
        await this._getReciepsShopping()

      })


}
catch (error) {
  console.log("_saveRecipes" + error)
}
}

render(){
  let favData = this.state.recipesData != null && this.state.recipesData.Users_Recipes_Fav.length > 0 ? this.state.recipesData.Users_Recipes_Fav.filter((x) => x.Users_Recipes_Fav_UUID == this.state.ipAdress) : []
  let shoppingList =  this.state.recipesDataShoppingList.length > 0 ? this.state.recipesDataShoppingList.filter((x) => x.User_UUID == this.state.ipAdress) : []

  return (
 this.state.recipesData !=  null  ?
    <div className="bg-[#fff]  justify-center py-3 px-3 m-10 items-center ">
            
      <div className="lg:w-4/5 xl:w-full  w-full bg-white flex  lg:flex-row  flex-col">
        
        <WhatToShop recipesData = {this.state.recipesData}/>
        <HowToPrepareMatter  recipesData = {this.state.recipesData}/>
        <ShareModal _closeShare={this._closeShare} openShareModalVisible = {this.state.openShareModalVisible}/>

      </div>

      <div className="lg:w-4/5 xl:w-full  w-full justify-center  bg-white flex  lg:flex-row  flex-col ">
                              
                              
                              <button onClick={()=> this._openShare()} className="drop-shadow-md hover:drop-shadow-xl bg-[#1b2542] rounded-md flex flex-row justify-center items-center ml-1 xxs:mt-1 sm:mt-1" > 
                              <div className="flex justify-center items-center xxs:m-2 sm:m-2 md:m-2 lg:m-3">
                              <FontAwesomeIcon  color='white' size="lg" icon={faShare} />
                              </div>
                              <div className="flex justify-center items-center xxs:m-2 sm:m-2 md:m-2 lg:m-3" >
                              <p className="flex justify-center items-center px-2 color text-white hover:text-blue-600 cursor-pointer">Paylaş</p>
                              </div>
                              </button>
                             
                              {favData.length > 0 ? 
                              <button onClick={()=> this._deleteFavori(favData[0].ID)} className="drop-shadow-md hover:drop-shadow-xl bg-[#1b2542] rounded-md flex flex-row ml-1 justify-center items-center xxs:mt-1 sm:mt-1" > 
                              <div className="flex justify-center items-center px-1"  >
                              <div className="flex justify-center items-center xxs:m-2 sm:m-2 md:m-2 lg:m-3">
                               <FontAwesomeIcon color='red' size="lg" icon={faHeart} />
                               </div>
                               <p className="flex justify-center items-center px-1 color text-white hover:text-blue-600 cursor-pointer">Kaydedildi</p>

                            </div>
                             </button>:
                              <button onClick={()=> this._saveRecipes(this.state.recipesData.ID)} className="drop-shadow-md hover:drop-shadow-xl bg-[#1b2542] rounded-md flex flex-row ml-1 justify-center items-center xxs:mt-1 sm:mt-1" > 
                              <div className="flex justify-center items-center xxs:m-2 sm:m-2 md:m-2 lg:m-3">
                              <FontAwesomeIcon color='white'  size="lg" icon={faHeart} />
                              </div>
                              
                               <div className="flex justify-center items-center "  >
                              <p className="flex justify-center items-center px-2 color text-white hover:text-blue-600 cursor-pointer">Kaydet</p></div>
                              </button>}

                              {shoppingList.length == 0 ?
                              <button onClick={()=> this._saveShoppingList(this.state.recipesData.ID)} className="drop-shadow-md hover:drop-shadow-xl bg-[#1b2542] rounded-md flex flex-row ml-1 justify-center items-center xxs:mt-1 sm:mt-1 " > 
                              <div className="flex justify-center items-center m-2">
                               <FontAwesomeIcon color='white'  size="lg" icon={faAdd} />
                              <div className="flex justify-center items-center xxs:m-2 sm:m-2 md:m-2 lg:m-3" >
                              <p  className="flex justify-center items-center px-1 color text-white hover:text-blue-600 cursor-pointer">Alışveriş Listesine Ekle</p></div>
                              </div> </button> : 
                              
                              <button onClick={()=> this._deleteShopping(shoppingList[0])} className="drop-shadow-md hover:drop-shadow-xl bg-[#1b2542] rounded-md flex flex-row ml-1 opacity-75 justify-center items-center xxs:mt-1 sm:mt-1" > 
                              <div className="flex justify-center items-center xxs:m-2 sm:m-2 md:m-2 lg:m-3">
                              <FontAwesomeIcon color='white' size="lg" icon={faAdd} />
                              <p className="flex justify-center items-center px-1 color text-white hover:text-blue-600 cursor-pointer">Alışveriş Listesine Eklendi</p></div>
                              </button>
                          }


                          </div>

                          <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </div> : null
  )
}
};

export default HowToPrepare;
