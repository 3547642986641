import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
//All the svg files
import logo from "./assets/logo.png";
import Home from "./assets/home-solid.png";
import Team from "./assets/social.svg";
import Recipe from "./assets/recipe-book-icon.png";

import Calender from "./assets/sceduled.svg";
import Projects from "./assets/menu.png";
import Documents from "./assets/receipt.png";
import Favorite from "./assets/heart-1.png";
import ContentDarkMode from "./ContentDarkMode";
import ContentKVKK from "./ContetnKVKK";
import ContentCertifica from "./ContentCertifica";
import ContentMedia from "./ContentMedia";
import ContentStand from "./ContentStand";
import ContentImage from "./ContentImage";
import ContentImageMedia from "./ContentImageMedia";

import PowerOff from "./assets/power-off-solid.svg";
import Fajitas from "./assets/fajitas.jpg";
import More from "./assets/more.png";

import styled from "styled-components";
import { NavLink } from "react-router-dom";
import "./index.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCircle, faDotCircle, faMoon } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

const Container = styled.div`
  position: fixed;

  z-index: ${(props) => (props.clicked ? "2" : "2")}

  .active {
    border-left: 5px solid var(--yellow);
    background-color:#ECECEC;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(10%);
    }
   
  }
`;


const MainBar = styled.div`
  background-color: var(--white);
  width: 6.8rem;
  height: 100vh;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;



const SlickBar = styled.ul`
  color: var(--white);
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--yellow);

  position: absolute;
  top: 0;
  left: 0;

  transition: all 0.5s ease;

`;

const Item = styled(NavLink)`
  text-decoration: none;
  color: #FFC04B;
  width: 100%;
  flex-direction:column;
  padding: 1rem 0;
  cursor: pointer;
  

background-color: white;
border-bottom-color:#0a4022;

border-bottom-width:5px;

  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;


  
  &:hover {
    
    border-left: 4px solid var(--yellow);
    border-bottom: 2px solid #0a4022;
    background-color: #FFC04B;
 
    img {
      filter: invert(10%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(100%) contrast(200%);
    }

    #deneme {
   align-self:center;
   
    }

    #deneme p {
      
      color:#0a4022;
      text-align:'center'
  }
    
   
    
    
  
  }
  
  #deneme {
    align-self:center;
    
     }
 

  #deneme p {

    color:#c5aa77;
    text-align:'center'
}
  img {
    width: 2.rem;
    height: auto;
  
  }
  

  .imgNew {
    width: ${(props) => (props.clicked ? "10%" : "0")};
    overflow: hidden;
    margin-left: ${(props) => (props.clicked ? "1.5rem" : "0")};
    transition: all 0.3s ease;
  }
`;

const ItemDontClick = styled.ul `
  text-decoration: none;
  color: #FFC04B;
  width: 100%;
  flex-direction:column;
  padding: 1rem 0;
  cursor: pointer;
  

background-color: white;
border-bottom-color:#0a4022;

border-bottom-width:5px;

  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;


  
  &:hover {
    
    border-left: 4px solid var(--yellow);
    border-bottom: 2px solid #0a4022;
    background-color: #FFC04B;
 
    img {
      filter: invert(10%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(100%) contrast(200%);
    }

    #deneme {
   align-self:center;
   
    }

    #deneme p {
      
      color:#0a4022;
      text-align:'center'
  }
    
   
    
    
  
  }
  
  #deneme {
    align-self:center;
    
     }
 

  #deneme p {

    color:#c5aa77;
    text-align:'center'
}
  img {
    width: 2.rem;
    height: auto;
  
  }
  

  .imgNew {
    width: ${(props) => (props.clicked ? "10%" : "0")};
    overflow: hidden;
    margin-left: ${(props) => (props.clicked ? "1.5rem" : "0")};
    transition: all 0.3s ease;
  }
`;

const TextDesc = styled.span`
  width:100%;
  marginTop:1rem;
  overflow: hidden;
  margin-left: 0px;
  text-align:center;
  transition: all 0.3s ease;
  color: #c5aa77;

`;



const Logout = styled.button`

  width: 2rem;
  height: 2rem;
  background-color: transparent;

  img {
    width: 100%;
    height: auto;
    filter: invert(15%) sepia(70%) saturate(6573%) hue-rotate(2deg)
      brightness(100%) contrast(126%);
    transition: all 0.3s ease;
    &:hover {
      border: none;
      padding: 0;
      opacity: 0.5;
    }
  }
`;

const Sidebar = () => {
  const [ dataVisible , setVisible ] = useState(false);
  const [ dataCertificaVisible , setCertificaVisible ] = useState(false);
  const [ data , setData ] = useState(false);

  const [ dataVisibleMedia , setVisibleMedia ] = useState(false);
  const [ dataVisibleStand , setVisibleStand ] = useState(false);

  const [ imageVisible , setImageVisible ] = useState(false);
  const [ imageLink , setImageLink ] = useState('');
  const [ imageVisibleMedia , setImageVisibleMedia ] = useState(false);
  const [ imageLinkMedia , setImageLinkMedia ] = useState(false);
  const [ imageLinkMediaType , setImageLinkMediaType ] = useState('');

  
  
  const _closedataStandVisible = () => {
    setVisibleStand(false)
    setImageVisible(false)
  }

  const _closeMediaVisible = () => {
    setVisibleMedia(false)
  }
  const _closeKVKKVisible = () => {
    setVisible(false)
  }
  
  const _closeImageVisible = () => {
    setImageVisible(false)
  }
    
  const _closeImageVisibleMedia = () => {
    setImageVisibleMedia(false)
  }
  
  const _openImage = (url) => {
    setImageVisible(true)
    setImageLink(url)
  }

  const _openImageMedia = (url,type) => {
    setImageVisibleMedia(true)
    setImageLinkMedia(url)
    setImageLinkMediaType(type)

  }
  
  

  const _openKVKK = () => {
    setVisible(true)
  }


  const _closeCertifica = () => {
    setCertificaVisible(false)
  }



 const _getProductsCategory = async () => {
    try{
      
   await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getProductCategory/select/`).then((res) => {
    setData(res.data)
        
        })
   }
   catch (error) {
     
     console.log("errorAS" + error)
   }

}




  const [windowDimension, detectHw] = useState(
    {
      winWidth: window.innerWidth,
      winHeight: window.innerHeight
    }
  )
  const detectSize = () => {

    detectHw({
      winWidth: window.innerWidth,
      winHeigh: window.innerHeight
    })
  }
  useEffect(() => {
    const asyncFetchDailyData = async () => {
      window.addEventListener('resize', detectSize)
    }
  
    asyncFetchDailyData();
  }, []);


   useEffect(async() => {
    await _getProductsCategory()

  }, []);


  {/* const Link = ({ children, ...props }) => <a {...props}>{children}</a>; */}



  const [click, setClick] = useState(false);



  const [showRecipes, setshowRecipes] = useState(false);

  const [showProduct, setshowProducts] = useState(false);


  const [showShoppingList, setshowShoppingList] = useState(false);

  const [showMore, setshowMore] = useState(false);

  const [showShowFavorite, setShowFavorite] = useState(false);


  const _closeFunc = (type) => {

    if(type == "favoriterecipes"){

      setshowRecipes(false)
      setshowShoppingList(false)
      setshowProducts(false)
      setshowMore(false)

      if(showRecipes == true || showProduct == true || showRecipes == true || showMore == true || showShowFavorite == true){
        setClick(true)
      }else{
        setClick(false)
      }

    }

    if (type == "all") {
      setshowRecipes(false)
      setshowShoppingList(false)
      setshowProducts(false)
      setshowMore(false)

      console.log("showRecipes123",showRecipes,showProduct,showRecipes,showMore)

      if(showRecipes == true || showProduct == true || showRecipes == true || showMore == true){
        setClick(true)

      }else{
        setClick(false)
            }

    }

    if (type == "blog") {
      setshowRecipes(false)

      setshowShoppingList(false)
      setshowProducts(false)
      setshowMore(false)

      if(showRecipes == true || showProduct == true || showRecipes == true || showMore == true){
        setClick(true)
      }else{
        setClick(!click)
      }

    }

  
    if (type == "recipes") {
      setshowRecipes(!showRecipes)

      setshowShoppingList(false)
      setshowProducts(false)
      setshowMore(false)

      if(showShoppingList == true || showProduct == true || showMore == true ){
        setClick(true)
      }else{
        setClick(!click)
      }

    }
    if (type == "shoppinglist") {
      setshowShoppingList(!showShoppingList)

      setshowRecipes(false)
      setshowProducts(false)
      setshowMore(false)

      if(showRecipes == true || showMore == true || showProduct == true){
        setClick(true)
      }else{
        setClick(!click)
      }

    }
    if (type == "product") {
      setshowProducts(!showProduct)

      setshowShoppingList(false)
      setshowRecipes(false)
      setshowMore(false)
      if(showRecipes == true || showMore == true || showShoppingList == true){
        setClick(true)
      }else{
        setClick(!click)
      }
      

    }


    if (type == "more") {
      setshowMore(!showMore)

      setshowShoppingList(false)
      setshowRecipes(false)
      setshowProducts(false)
     
      if(showRecipes == true || showProduct == true || showShoppingList == true){
        setClick(true)
      }else{
        setClick(!click)
      }

    }



  };





  const [show, toggleShow] = useState(false);


  return (
    <Container  className={click == true ? "h-full w-full z-10" : "h-full z-0"}>
      {/*<Button clicked={click} onClick={() => handleClick()} />*/}

      <MainBar className={click == true ? "h-full w-full z-10": "h-full  z-0" } >

        <SlickBar className={click == true ? "h-full w-full z-10" : "h-full z-0"} clicked={click}>
          
          <Item
            exact
            onClick={() => _closeFunc('all')}
            activeClassName="pasive"
            to="/"
            className="h-1/6 w-1/3 justify-center items-center">
            <img style={{
              alignSelf: "center", width: '100%',
            }} className={'imgNew'} src={logo} alt="logo" />

                      
          </Item>

          <Item className="h-1/6 w-1/3 justify-center items-center" 
            onClick={() => _closeFunc('all')}
            exact
            activeClassName={click == true ? "active" : "pasive"}
            to="/"

          >
            <img className="w-1/3 justify-center items-center" src={Home} alt="Anasayfa" />
            <div id='deneme'>
              <p className="font-[Lato-Bold]">ANASAYFA</p>
            </div>
          </Item>

          <Item className="h-1/6 w-1/3 justify-center items-center"
            onClick={() => _closeFunc("recipes")}
            activeClassName={showRecipes == true ? "active" : "pasive"}
            exact
            
            to="/yemek-tarifleri"
          
          >
            <img className="w-1/3 justify-center items-center"  src={Recipe} alt="Blog" />
            <div id='deneme'>
              <p className="font-[Lato-Bold]">TARİFLER</p>
            </div>
          </Item>


          <Item className="h-1/6 w-1/3 justify-center items-center"
            onClick={() => _closeFunc("favoriterecipes")}
            activeClassName={showShowFavorite == true ? "active" : "pasive"}
            exact
            
            to="/favori-tariflerim"
          
          >
            <img className="w-1/3 justify-center items-center"  src={Favorite} alt="Blog" />
            <div id='deneme'>
              <p className="font-[Lato-Bold]">TARİFLERİM</p>
            </div>
          </Item>


          <Item className="h-1/6 w-1/3 justify-center items-center"
            onClick={() => _closeFunc("shoppinglist")}
            activeClassName={showShoppingList == true ? "active" : "pasive"}
            exact
            
            to="/alisveris-listesi"
          
          >
            <img className="w-1/3 justify-center items-center"  src={Documents} alt="Blog" />
            <div id='deneme'>
            <p className="font-[Lato-Bold] text-center">ALIŞVERİŞ LİSTESİ</p>
            </div>
          </Item>

          <ItemDontClick className="h-1/6 w-1/3 justify-center items-center" 
            onClick={() => _closeFunc('product')}
            activeClassName={showProduct == true ? "active" : "pasive"}
            
           
            activeStyle={showProduct == true ? { backgroundColor: '#FFC04B', borderBottomColor: 'white' } : { backgroundColor: 'white' }}
          >
            {showProduct === true}
            <img className="w-1/3 justify-center items-center"  src={Projects} alt="Projects" />

            <div id='deneme'>
              <p className="font-[Lato-Bold]">ÜRÜNLER</p>
            </div>
          </ItemDontClick>

          <div sty className = {showProduct === true ? "w-[400px] h-full absolute left-[110px] bg-[#e9e9e9] overflow-auto" : "w-0 h-full absolute overflow-auto"} >
            {showProduct &&
              <div className="bg-[#eee] h-full"  >

                <Item  
                onClick={() => _closeFunc('product')}
                exact
                to="/urunlerimiz"

                className=" hover:text-red-400 text-[#FFC04B] font-[Lato-Bold] sm:text-[20px] md:text-[40px] lg:text-[60px] text-center"  >
                  <div className="h-1/6" style={{
                    position: 'relative',
                    padding:3,
                    margin:3,
                    borderBottomWidth:3,
                      borderBottomColor:'#0a4022'
                  }}>
                    <img style={{
                      width: '100%',
                      height:'100%',
                      opacity : '40%'

                    }} className="imgNew" src={Fajitas} alt="Fajitas" />
                    <h1 className="font-[Lato-Bold] sm:text-[20px] md:text-[30px] lg:text-[40px] text-center" style={{
                      color: '#FFC04B',
                      textShadow: '2px 2px 2px #0a4022',
                      position: 'absolute',
                      top: '40%',
                      left: '50%',
                      

                      transform: 'translate(-50%, -50%)'
                    }}>BÜTÜN ÜRÜNLER</h1>
                    
                  </div>

                </Item>



                
                {data.map((item, i) => (
                 <Item  
                 onClick={() => _closeFunc('product')}
                 exact
                
                   to={"/urunlerimiz"} state={{ ID: item.ID, _itemSub  :item }}  >

                  <div className="h-1/6" style={{
                    position: 'relative',
                     padding:3,
                      margin:3,
                      borderBottomWidth:3,
                      borderBottomColor:'#0a4022'
                  }}>
                    <img style={{
                      width: '100%',
                      height:'100%',
                      opacity : '40%'

                    }} src={'https://buharasubdomain.stechomeyazilim.info/' + item.Products_Category_Image} alt="Fajitas" />
                    <h1 className="font-[Lato-Bold]  sm:text-[20px] md:text-[30px] lg:text-[40px] text-center" style={{
                      color: i % 2 == 0 ? 'white' : '#FFC04B',
                      textShadow: '2px 2px 2px #0a4022',
                      position: 'absolute',
                      top: '40%',
                      left: '50%',
    
                      transform: 'translate(-50%, -50%)'
                    }}>{item.Products_Category_Name}</h1>
                  </div>
                  </Item>
                ))}
                  


              
              
              </div>
            }

          </div>

          <Item className="h-1/6 w-1/3 justify-center items-center" 
            onClick={() => _closeFunc('more')}
            activeClassName={showMore == true ? "active" : "pasive"}
            to="#"
            activeStyle={showMore == true ? { backgroundColor: ' #FFC04B', borderBottomColor: 'white' } : { backgroundColor: 'white' }}

          >
            <img className="w-1/3 justify-center items-center"  src={More} alt="More" />
            <TextDesc clicked={click}></TextDesc>
          </Item>

          <div className = {showMore === true ?  "w-[400px] h-full absolute left-[110px] bg-[#e9e9e9]" : "w-0 h-full absolute"}>
            {showMore &&
              <div style={{
                height: windowDimension.winHeight, marginTop:show == true ?  windowDimension.winHeight /7 :  windowDimension.winHeight  /7, 
                display: 'flex', flexDirection: 'column',
              }}   >

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <p
                    style={{backgroundColor: '#FFC04B', color: '#0a4022',  fontSize: 20, display: 'flex', alignItems: 'center', width: '100%', borderWidth: 0.5, borderStyle: 'solid', borderColor: '#D3D3D3', padding: 10 }}
                    className="font-[Lato-Bold]  hover:text-red-400 cursor-pointer"
                    onClick={() => toggleShow(!show)}
                  >
                    Kurumsal

                  </p>


                  <Link
                    style={{ backgroundColor: '#FFC04B', color: 'white',  fontSize: 20, display: 'flex', justifyContent: 'center', width: '15%', borderWidth: 0.5, borderStyle: 'solid', borderColor: '#FFC04B', padding: 10 }}
                    
                    className="link-hover"
                    onClick={() => toggleShow(!show)}
                  >
                <FontAwesomeIcon className="m-1"  color='#0a4022' size="lg" icon={faAdd}/>

                  </Link>
                </div>


                {show &&


                  <div style={{ backgroundColor: 'white' }}>


<Item className="h-1/5 w-1/3 " 
            onClick={() => _closeFunc('more')}
            activeClassName={showMore == true ? "active" : "pasive"}
            to="/hakkimizda"
            style={{backgroundColor: '#0a4022', color: 'white', fontSize: 20, display: 'flex', width: '100%', borderWidth: 0.5, borderStyle: 'solid', borderColor: '#D3D3D3', padding: 10 }}
          >
            Hakkımızda
          </Item>

        {/**   <Item className="h-1/5 w-1/3 font-[Lato-Bold]" 
            onClick={() => _closeFunc('more')}
            style={{backgroundColor: '#0a4022', color: 'white', fontSize: 20, display: 'flex',  width: '100%', borderWidth: 0.5, borderStyle: 'solid', borderColor: '#D3D3D3', padding: 10 }}
            activeClassName={showMore == true ? "active" : "pasive"}
            to="/uretim">Üretim</Item>*/}
                    
            <Item className="h-1/5 w-1/3 font-[Lato-Bold]" 
            onClick={() => _closeFunc('more')}
            style={{backgroundColor: '#0a4022', color: 'white', fontSize: 20, display: 'flex',  width: '100%', borderWidth: 0.5, borderStyle: 'solid', borderColor: '#D3D3D3', padding: 10 }}
            activeClassName={showMore == true ? "active" : "pasive"}
            to="/hakkimizda">Kalite Belgelerimiz</Item>




<Item className="h-1/5 w-1/3 font-[Lato-Bold]" 
            onClick={() => _closeFunc('more')}
            style={{backgroundColor: '#0a4022', color: 'white', fontSize: 20, display: 'flex',  width: '100%', borderWidth: 0.5, borderStyle: 'solid', borderColor: '#D3D3D3', padding: 10 }}
            activeClassName={showMore == true ? "active" : "pasive"}
            to="/medya">Medya</Item>
                

                    <button
                    onClick={()=> _openKVKK()}
                    style={{ backgroundColor: '#0a4022', color: 'white', fontSize: 20, display: 'flex', alignItems: 'center', width: '100%', borderWidth: 0.5, borderStyle: 'solid', borderColor: '#D3D3D3', padding: 10 }}

                      className="font-['Lato-Bold']"
                    >
                      KVKK
                    </button>


                   

                  </div>
                }



                

                <div style={{ display: 'flex', flexDirection: 'row',backgroundColor:'#eee' }}>
                  
                  
                <Item   className="font-[Lato-Bold]"
            onClick={() => _closeFunc('more')}
            activeClassName={showMore == true ? "active" : "pasive"}
            to="/bayilerimiz"
            style={{ "background-color": '#FFC04B', color: '#1d2540', fontSize: 20, display: 'flex', width: '100%', borderWidth: 0.5, borderStyle: 'solid', borderColor: '#D3D3D3', padding: 10 }}>
             Bayilerimiz ve Satış Noktalarımız
          </Item>

                



                </div>

                <div style={{ display: 'flex', justifyContent: 'space-around',backgroundColor:'#eee' }}>
                
                  <Item   className="font-[Lato-Bold]"
            onClick={() => _closeFunc('more')}
            activeClassName={showMore == true ? "active" : "pasive"}
            to="/iletisim"
            style={{ "background-color": '#FFC04B', color: '#1d2540', fontSize: 20, display: 'flex', width: '100%', borderWidth: 0.5, borderStyle: 'solid', borderColor: '#D3D3D3', padding: 10 }}>
            İletişim
          </Item>


                </div>





                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              

                
                  <Item   className="font-[Lato-Bold]"
            onClick={() => _closeFunc('more')}
            activeClassName={showMore == true ? "active" : "pasive"}
            to="/blog"
            style={{ "background-color": '#FFC04B', color: '#1d2540', fontSize: 20, display: 'flex', width: '100%', borderWidth: 0.5, borderStyle: 'solid', borderColor: '#D3D3D3', padding: 10 }}>
             Blog Yazıları
          </Item>



                </div>


              </div>

            }

          </div>


        </SlickBar>
      </MainBar>

      <ContentCertifica openKVKKVisible = {dataCertificaVisible} _closeKVKKVisible = {_closeCertifica}/>
      <ContentKVKK openKVKKVisible = {dataVisible} _closeKVKKVisible = {_closeKVKKVisible}/>
      <ContentMedia _openImageMedia={_openImageMedia} openMediaVisible = {dataVisibleMedia} _closeMediaVisible = {_closeMediaVisible}/>
      <ContentStand _openImage={_openImage} openVisibleStand = {dataVisibleStand} _closedataStandVisible = {_closedataStandVisible}/>

      <ContentImageMedia _imageLinkMediaType = {imageLinkMediaType} openVisibleImage={imageVisibleMedia} _imageLink = {imageLinkMedia} _closeImageVisible = {_closeImageVisibleMedia} _openImage={_openImage}/>
      <ContentImage openVisibleImage={imageVisible} _imageLink = {imageLink} _closeImageVisible = {_closeImageVisible} _openImage={_openImage}/>
    </Container>
  );
};

export default Sidebar;
