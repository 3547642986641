import React , { Fragment, useState,useEffect,useContext } from "react";
import TextField from "@mui/material/TextField";
import "./search.css";
import SearchIcon from '@mui/icons-material/Search';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../themeContext'

function SearchBar() {
  const [inputText, setInputText] = useState("");
  const [myOptions, setMyOptions] = useState([])
  const [filterImage, setFilterImage] = useState([])
  const [showDarkMode, setShowDarkMode] = useState(false);

  const [darkToggle, setDarkToggle] = React.useState(false)

  const { theme, setTheme } = useContext(ThemeContext)



  const toggleShowDark = (e) => {
    console.log("lsndkf",e.target.checked)
   if(e.target.checked == true){
    setTheme('dark')
   }else{
    setTheme('light')

   }
    setDarkToggle(!darkToggle)
  }


  const getDataFromAPI = (e) => {
    setMyOptions([])
    if(e.target.value.length > 0){
      fetch(`https://buharaapi.stechomeyazilim.info:5210/products/search/${e.target.value}`).then((response) => {
        return response.json()
      }).then((res) => {
        
        for (var i = 0; i < res.length; i++) {
          myOptions.push(res[i].Products_Name)
         setFilterImage(res)
        }
       setMyOptions(myOptions)

      })
    }

  }


  return (
    <div className="main">
     
      <div className="items-center m-3 xxs:w-1/2 md:w-1/3 lg:w-1/3 flex">
        
      <Autocomplete
        style={{ background:'white',borderRadius:30,borderWidth:10 }}
        noOptionsText={'Veri Bulunmamaktadır!'}
        fullWidth
        autoComplete
        autoHighlight
        options={myOptions}
        renderOption={option => {
         let ImageFilter = filterImage.filter((x) => x.Products_Name == option)

         console.log("şmsdlşf",ImageFilter)
          return (
         <Link to={"/urun-detay/"  + (ImageFilter[0].Products_Name).replace('?', '').replace(/\s+/g, '-')} state={{_item : ImageFilter[0],ID: ImageFilter[0].ID}}   className=" w-full  grid grid-cols-2 gap-2 justify-center">
              <div >
              <p className="text-light-white fs-16 font-['Lato-Medium']">{ImageFilter[0].Products_SubCategory.Products_SubCategory_Name} - </p>
                <p className="text-light-white fs-16 font-['Lato-Medium']">{ImageFilter[0].Products_SubCategory.Products_Category.Products_Category_Name}</p>

               <p className=" fs-16 font-['Lato-Bold']">{option}</p>
               </div>
               <div className="m-1">
                  <img src={'https://buharasubdomain.stechomeyazilim.info/' + ImageFilter[0].Products_Image} className="w-10" alt="product-img" />
                  </div>
             
              </Link>
          );
      }}
        renderInput={params => <TextField  sx={{
          "& .MuiInputBase-root": {
            color: 'red',
              "& input": {
                  textAlign: "center",
                  color:'#0a4022',
                  fontSize:20
              }
          }
        }}
        placeholder="Ne Aramıştınız?" className="inputRounded"
        
           {...params} variant="outlined" onChange={getDataFromAPI} />}/>
      
      <div className="toggle-theme-wrapper hidden">
      <span>☀️</span>
      <label className="toggle-theme" htmlFor="checkbox">
        <input
          type="checkbox"
          id="checkbox"
          onChange={toggleShowDark}
          
        />
        <div className="slider round"></div>
      </label>
      <span>🌒</span>

      
    </div>
      
    

             
      </div>
     

    


    </div>
  );
}

export default SearchBar;