import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import axios from 'axios';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navmethod: false,
            productsData:[]
        };
    }
    // Navigation
   
    toggleNav = async () => {
        this.props._openNav()
    }
    
    

    render() {
        return (
            <div className="catring parent-megamenu p-4 flex justify-between items-center">
                <p to="#" className={classNames({ "active": this.state.navmethod })}>
                    <span className='text-[#0a4022] font-[Lato-Medium] '>Filtrele:</span>
                </p>

              

                <button className='bg-[#0a4022] justify-center items-center rounded-lg' to="#"  onClick={this.toggleNav}>
                    <span className='text-[white]  text-[15px] m-3 text-center'>Filtrelemeyi Aç</span>
                </button>
              
                
            </div>
        );
    }
}

export default Menu;