import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation } from 'swiper/modules';

import axios from 'axios';

import img1 from '../../../assets/img/restaurants/220x220/nearme-1.jpg';
import img2 from '../../../assets/img/restaurants/220x220/nearme-2.jpg';
import img3 from '../../../assets/img/restaurants/220x220/nearme-3.jpg';
import img4 from '../../../assets/img/restaurants/220x220/nearme-4.jpg';
import img5 from '../../../assets/img/restaurants/220x220/nearme-5.jpg';
import img6 from '../../../assets/img/restaurants/220x220/nearme-6.jpg';
import img7 from '../../../assets/img/restaurants/220x220/nearme-7.jpg';
import img8 from '../../../assets/img/restaurants/220x220/nearme-8.jpg';



// install Swiper components

const foodslider = [
    { img: img1, title: 'Chicken Special Pizza' },
    { img: img2, title: 'Hakka Noodles' },
    { img: img3, title: 'Vegan Burger' },
    { img: img4, title: 'Sticky Date Cake' },
    { img: img5, title: 'Thai Dumpling Soup' },
    { img: img6, title: 'American Chop Suey' },
    { img: img7, title: 'Italian Sausage Pasta' },
    { img: img8, title: 'Spaghetti' },
    { img: img2, title: 'Mexican Street Tacos' },
];

class Nearfood extends Component {
    constructor() {
        super()
        this.state = {
            open: true,
            open2: true,
            subList:[]
        }
    }

    async componentDidMount() {
        await this._getSubCategory()
    }

    _getSubCategory = async () => {
        try{
        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getProductCategory/select/`)
         .then((res) => {
             this.setState({subList: res.data })   
            })
       }
       catch (error) {
         console.log("errorAS" + error)
       }
    }
    render() {
        const settings = {
            slidesPerView: 1,
            spaceBetween: 25,
            loop: false,
            breakpoints: {
                576: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                },
               
            },
            modules: [Navigation], // Modülleri burada belirtin
        };
        return (
            <section className="browse-cat section-padding">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="flex items-center justify-between mt-2">
                                <h3 className="sm:text-[15px] md:text-[20px] lg:text-[30px]  font-[Lato-Bold] text-[#c5aa77]">Buhara Baharat Ürünleri<span className="fs-14"></span></h3>
                                <Link className='font-[Lato-Bold] text-right' to={ "/urunlerimiz/"} state={{  ID: null }} >HEPSİNİ GÖRÜNTÜLE</Link>
                            </div>
                        </div>
                        <div className="col-12 mt-2 ">
                            <Swiper className="food-near-me swiper-container" {...settings} navigation>
                                {this.state.subList.map((item, i) => (
                                    <SwiperSlide key={i}>
                                        <Link   to={ "/urunlerimiz/"} state={{ ID: item.ID, _itemSub : item }}  className="categories">
                                            <div className="icon icon2 text-custom-white ">
                                                <img src={'https://buharasubdomain.stechomeyazilim.info/' + item.Products_Category_Image}  alt={item.Products_Category_Image} />
                                            </div>
                                            <span className="bg-[#c5aa77] text-light-black cat-name  text-[#0a4022] font-[Lato-Bold] ">{item.Products_Category_Name}</span>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Nearfood;