import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Footer from '../layouts/Footer';
import Content from '../sections/homethree/Content';
import styled from "styled-elements";

const Pages = styled.div`
margin-left:110px;`;


class Homethree extends Component {

    
    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>Buhara Baharat | Tarifler</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                {/* <Header/> */}
               {/* <Sidebar /> */}

                <Content/>
                <Footer/>
              
            </Fragment>
        );
    }
}

export default Homethree;