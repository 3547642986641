import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { fetchFlavorIcon } from '../../../helper/helper';


import axios from 'axios';
import Moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';

import img1 from '../../../assets/img/preparing-salad-female-chef-cutting-fresh-vegetables.jpg';
import img2 from '../../../assets/img/woman-chef-cooking-vegetables-pan.jpg';

// install Swiper components

const topcollection = [
    { img: img1, cat: 'Trend Tarifler' },
    { img: img2 , cat: 'En Gözde Tarifler' },
];

class Collectionbox extends Component {
    constructor() {
         super();
        this.state = { recipesData:[] }
}

    async componentDidMount() {
        await this._getRecieps()
    }

    
    _getRecieps = async () => {
        try{

            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();

        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipes/select/`)
         .then(res => {
             this.setState({recipesData: res.data , ipAdress : data.ip})                    
             })
       }
       catch (error) {
         
         console.log("errorAS" + error)
       }
   }

   

   showToast(event, value, type) {
    toast.success(value, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  }


  _deleteFavori= async (ID) => {
    try{

        console.log("_deleteFavori",ID)
        await axios.delete(`https://buharaapi.stechomeyazilim.info:5210/recipes/delete/${ID}`)
        .then(async(res) => {
            await this._getRecieps()
        })
    
   }
   catch (error) {
     console.log("errordeleteFavori" + error)
   }  
}

_saveRecipes = async (ID) => {

    console.log("ID123213",ID)
    var testDateUtc = Moment.utc(new Date());       
    var localDate = Moment(testDateUtc).local();
    
    
    const response = await fetch('https://api.ipify.org?format=json');
    const dataIP = await response.json();


    console.log("ID123213resIp",dataIP)

    const data = {
        Users_Recipes_Fav_UUID: dataIP.ip, // This is the body part
        Recipes_ID: ID,
        Created_DateTime: localDate
};

fetch("https://buharaapi.stechomeyazilim.info:5210/recipesFav/insert", {
method: "post",
body: JSON.stringify(data),
headers: { "Content-Type": "application/json" }
})
.then(async(res) =>{
    await this._getRecieps()
    this.showToast('Buhara Baharat', "Tarifi başarıyla favoriye eklediniz!", 'success')
}  )
.catch(err => console.log(err))

}
   
    render() {
        const settings = {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: false,
        }

        return (
            <section className="ex-collection section-padding bg-light-theme home-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="section-header-left">
                            <h3 className="text-[30px] font-[Lato-Bold] text-[#c5aa77]">Tariflere Göz At</h3>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            {topcollection.map((item, i) => (
                                <Link to={{pathname: "/yemek-tarifleri/" }} key={i} className="col-md-6">
                                    <div className="ex-collection-box mb-xl-20">
                                        <img src={item.img} className="img-fluid full-width" alt="" />
                                        <div className="category-type overlay padding-15">
                                            <Link className="category-btn bg-[#FFC04B]">{item.cat}</Link>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div className="flex items-center justify-between section-header-left">
                            <h3 className="text-[30px] font-[Lato-Bold] text-[#c5aa77]">Tarifler</h3>
                            <Link className="font-[Lato-Bold] text-right" to={"/urunlerimiz/"} state={{ID: null}}>HEPSİNİ GÖRÜNTÜLE</Link>
                        </div>
                        <div className="grid xxl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xxs:grid-cols-2 justify-center">
                            {this.state.recipesData.slice(0,6).map((item, i) => {
                                let favData = item.Users_Recipes_Fav.length > 0 ? item.Users_Recipes_Fav.filter((x) => x.Users_Recipes_Fav_UUID == this.state.ipAdress && x.Recipes_ID == item.ID) : []
                                return (
                                    <Link to={"/yemek-tarifi/" + (item.Recipes_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}} className="ex-collection-box2 w-full h-full">
                                        <div className="product-box">
                                            <div className="product-img">
                                                <img src={'https://buharasubdomain.stechomeyazilim.info/' + item.Recipes_Image} className="img-fluid full-width xxl:h-[420px] lg:h-[320px] md:h-[320px] sm:h-[220px] xxs:h-[220px]" alt={item.Recipes_Title} />
                                                <div className="overlay">
                                                    <div className="product-tags padding-10">
                                                    <span onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();
    favData.length > 0 ? this._deleteFavori(favData[0].ID) : this._saveRecipes(item.ID);
}} className="circle-tag">
    {favData.length == 0 ? <img src="assets/img/svg/heart-1.svg" alt="tag" /> : <img src="assets/img/svg/heartlike.png" alt="tag" />}
</span>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product-caption">
                                                <div className="title-box">
                                                    <h6 className="product-title">
                                                        <Link to={"/yemek-tarifi/" + (item.Recipes_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}} className="text-[#0a4022] font-[Lato-Bold]">
                                                            {item.Recipes_Title}
                                                        </Link>
                                                    </h6>
                                                </div>
                                                <p className="text-light-white absolute top-0">
                                                    <span className="font-[Lato-Bold] text-white" key={i}>{(item.Recipes_Category.Recipes_Category_Text).toUpperCase()}</span>
                                                </p>
                                                <div className="product-details"></div>
                                                {item.Recipes_Icon != null ? <div className="product-footer">
                                                    {item.Recipes_Icon.map((flavor, i) => (
                                                        <span key={i} className="text-custom-white square-tag">
                                                            <img src={fetchFlavorIcon(flavor.Recipes_Icon_Text)} alt={flavor.Recipes_Icon_Text} />
                                                        </span>
                                                    ))}
                                                </div> : null}
                                            </div>
                                        </div>
                                    </Link>
                                )})}
                        </div>
                    </div>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>
            </div>
        </section>
        
        );
    }
}

export default Collectionbox;