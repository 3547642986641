import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Content from '../sections/Production/Content';

class About extends Component {
    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>Buhara Baharat | Hakkımızda</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
               {/* <Header/> */}
               <Content/> 
                <Footer/>
            </Fragment>
        );
    }
}

export default About;