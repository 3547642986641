import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Heart from '../../../assets/img/svg/heart-1.svg';

class Tabs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }
    render() {
        return (
            <div className="restaurent-tabs u-line">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="restaurent-menu scrollnav">
                                <ul className="nav nav-pills">
                                    <li className="nav-item"> 
                                    <AnchorLink className="nav-link text-light-white fw-700" href="#menu">Menü</AnchorLink>
                                    </li>
                               
                                  {/**  <li className="nav-item"> 
                                    <AnchorLink onClick={()=> this.props._openReview()} className="nav-link text-light-white fw-700" href="#review">Değerlendirme</AnchorLink>
                                    </li>*/} 
                                   
                                </ul>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Tabs;