import React, { Fragment } from 'react';
import Menu from './Menu';
import FilterBy from './FilterBy';
import HeaderComponent from '../../../helper/Navigationhelper';


class Header extends HeaderComponent {
    constructor(props) {
        super(props)
        this.state = {
            visibleFilter : false
        }
    }

    _openNav = async () => {
        this.setState({visibleFilter: !this.state.visibleFilter})
        console.log("kbsdkjfbjkdsf")
    }

    render() {
        return (
            <Fragment>
                <div className='bg-[#0a4022]' >
                <Menu _itemSub={this.props._itemSub} _subCategoryID={this.props._subCategoryID} _data = {this.props._data} _filterRecipe={this.props._filterRecipe}/>
               

                {this.state.visibleFilter == true ?  
                <div className='bg-[white]'>
                <FilterBy  />
                </div> : null}

                </div>
                
             
            </Fragment>
        );
    }
}

export default Header;