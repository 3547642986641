import React,{ StrictMode } from 'react';
import { createRoot } from "react-dom/client";

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './themeContext';
import Background from './Background';
import { HashRouter } from "react-router-dom";


// Css
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/swiper/swiper-bundle.min.css';
import '../node_modules/magnific-popup/dist/magnific-popup.css';
import '../node_modules/react-rangeslider/lib/index.css';
import './assets/css/font-awesome.css';
import './assets/css/font/flaticon.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/color4.css'; 
import './index.css';
import { isMobile } from "react-device-detect";

import Sidebar from "./Sidebar";
import Header from './components/layouts/Header';
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";



const rootElement = document.getElementById("quickmunch");
const root = createRoot(rootElement);


root.render(
  <BrowserRouter>
  <ThemeProvider>
            <Background>
            {isMobile == false ?  
            <Sidebar /> : 
            <Header />}

<WhatsAppWidget
			phoneNo="+905051187380"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt="Merhaba?"
			iconSize= {isMobile == false ? "80" : "50" }
			iconColor="white"
			iconBgColor="green"
			headerIcon="https://buhara.com/buharalogo.png"
			headerIconColor="#c5aa77"
			headerTxtColor="white"
			headerBgColor="#c5aa77"
			headerTitle="Buhara Baharat"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatPersonName="Destek"
			chatMessage={<>Merhaba 👋 <br /><br /> Size Nasıl Yardımcı Olabilirim?</>}
			footerBgColor="#999"
			placeholder="Mesaj Türü.."
			btnBgColor="#0a4022"
			btnTxt="Konuşmaya Başla"
			btnTxtColor="white"
		/>

    <App />
    </Background>
    </ThemeProvider>
  </BrowserRouter>
);