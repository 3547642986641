import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faHeart, faShare } from '@fortawesome/free-solid-svg-icons'
import Moment from 'moment'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #000',
  boxShadow: 24,
};


class ContentKVKK extends Component {

  constructor(props) {
      super(props)
      this.state = {
          open: false,
          recipesData:null,
          recipesDataShoppingList:[],
          kvkk:[]
      }
  }

  async componentDidMount() {
    await this._getContact()
}


_getContact = async () => {
 try{
 await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getContact/select/`)
  .then(res => {
      this.setState({kvkk: res.data })})
}
catch (error) {
  
  console.log("errorAS" + error)
}
}

  
render(){
  return (

    <Modal
  open={this.props.openKVKKVisible}
  onClose={this.props._closeKVKKVisible}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80vh',  // Ekranın yüksekliğinin yarısını kaplayacak şekilde genişlik
      height: '80vh', // Ekranın yüksekliğinin yarısını kaplayacak şekilde yükseklik
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: 1,
      p: 4,
    }}
  >

<div className="flex justify-between items-center bg-[#fff] p-3">
      <p className="text-center sm:text-[10px] md:text-[20px] lg:text-[30px] font-['Alfa'] text-[#1b2542]">KVKK</p>
      <IconButton onClick={this.props._closeKVKKVisible}>
        <CloseIcon />
      </IconButton>
    </div>
  
    <div className="bg-[#ebe5df] w-full p-2">
      <div className="grid w-full p-1">
        {this.state.kvkk.map((item, i) => (
          <div key={i}>
            <p className="text-center">{item.Contact_KVKK}</p>
          </div>
        ))}
      </div>
    </div>
  </Box>
</Modal>


  )
}
};

export default ContentKVKK;
