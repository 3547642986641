import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Content from '../sections/exdeals/Content';
import { useLocation, Link } from "react-router-dom";

    const Exdeals = () => {
        const location = useLocation();
        const state = location.state;

        return (
            <Fragment>
                <MetaTags>
                    <title>Buhara Baharat | Ürünlerimiz</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                <Content _itemSub={state != null ? state._itemSub : null} _ID={state != null ? state.ID : null} _item={state != null ? state._item : null}/>
                <Footer/>
            </Fragment>
        )
}

export default Exdeals;