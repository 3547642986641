import React, { Component, Fragment } from 'react';
import { BsPrinter } from "react-icons/bs";
import Checkbox from "@mui/material/Checkbox";
import { yellow } from "@mui/material/colors";
import Shoppingmater from "./Shoppingmater";
import axios from 'axios';
import uniqid from 'uniqid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { processRatingStars, fetchFlavorIcon } from '../../../helper/helper';
import ReactToPrint from "react-to-print";
import LoadingSpinner from "../../../LoadingSpinner";


class ShoppingListDetail extends Component {

constructor() {
  super();
 this.state = {
   shoppingData:[],
   arrayList:[],
   isLoading:true ,
   resReview:[]
  }
  this.componentRef = React.createRef();

}



async componentDidMount() {
 await this._getShoppingList()
}


_getShoppingList = async () => {
  try{

    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();

await axios.get(`https://buharaapi.stechomeyazilim.info:5210/shoppingList/select/${data.ip}`)
.then((res) => {


  this.setState({ shoppingData: res.data , isLoading:false    })

   res.data.map((item, i) => (
    this.setState(prevState => ({
      arrayList: [...prevState.arrayList, item.Recipes]
    }),async()=> {   
     /* await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipes/review/${item.Recipes_Products_ID}`)
      .then((resReview) => {
        this.setState(prevState => ({
          resReview: [...prevState.resReview, {ID : item.ID,reviewValue : resReview.data.value}]
      }))
         }) */
    })
   ))

  
  
  })

}
catch (error) {
  this.setState({ isLoading:false    })

console.log("errorA1S" + error)
}
}

_deleteFavori= async (ID) => {
  try{

    let NewData = this.state.shoppingData.filter((x)=> x.Recipes_ID == ID)

    NewData.map(async(item, i) => (
      await axios.delete(`https://buharaapi.stechomeyazilim.info:5210/shoppingList/delete/${item.ID}`)
      .then(async(res) => {
          await this._getShoppingList()
         })
     ))
    
  
 }
 catch (error) {
   console.log("errordeleteFavori" + error)
 }  
}

render() {
  let arrayNew = this.state.arrayList.filter( (ele, ind) => ind === this.state.arrayList.findIndex( elem => elem.ID === ele.ID && elem.ID === ele.ID))

  return (
    <div ref={el => (this.componentRef = el)}  className="flex justify-center items-center  bg-[#CFD1CC] py-8 ">
             <LoadingSpinner show = {this.state.isLoading}/> 

  {this.state.shoppingData.length == 0 ?
  <div className=" py-12 lg:px-28 px-2 md:w-4/5 flex justify-center items-center lg:w-2/3 text-[#0C022F] flex-col xl:w-1/2 ">

<h2 className="sm:text-[20px] md:text-[25px] lg:text-[35px]  font-[Lato-Bold] text-[#0a4022] m-2 text-center">ALIŞVERİŞ LİSTESİ</h2>

            <div className="w-full  items-center bg-white justify-center divShopping">

  <p className='text-center text-[18px] font-medium m-2'>Kaydedilmiş tarifiniz yok. Lezzetli bir yemek pişirmek için tariflerimizden ilham alın. Denemek istediklerinizi alışveriş listesine eklemeniz yeterli.</p>
</div>
  </div>:
    <div className=" py-12 lg:px-28 px-2 md:w-4/5 flex justify-center items-center lg:w-2/3 text-[#0C022F] flex-col xl:w-1/2">
        <div className="w-full flex justify-center items-center bg-white">
          <h2 className="sm:text-[25px] md:text-[30px] lg:text-[45px]  font-[Lato-Bold] text-[#c5aa77] m-2 text-center ">Alışveriş Listesi</h2>
          
        </div>
        <div className="bg-white grid grid-cols-4 gap-2  justify-center items-center p-1">

        {arrayNew.map((item, i) => {
            let reviewFilter = this.state.resReview.filter((x) => x.ID == item.ID)

            return(
                     
                <div className='h-full w-full  rounded-lg bg-white border-2 p-2 relative'>
                   
                    <button onClick={()=> this._deleteFavori(item.ID)} className="absolute bg-white border rounded-full -right-3 -top-3 z-10"> 
                             <FontAwesomeIcon className="m-2"  color='black' size="lg" icon={faTrash}/>
                             </button>

             
                        <Link to={"/yemek-tarifi/" + (item.Recipes_Title).replace('?', '').replace(/\s+/g, '-')} state={{ ID: item.ID, _item:item }} className="z-0">
                          <div className="justify-center relative">
                                    <div to={"/yemek-tarifi/" + (item.Recipes_Title).replace('?', '').replace(/\s+/g, '-')} state={{ ID: item.ID, _item:item }} >
                                        <img  src={'https://buharasubdomain.stechomeyazilim.info/' +  item.Recipes_Image}  className="w-full" alt="#" />
                                    </div>
   
                                   <div className='w-full justify-center items-center flex'><text className='text-[18px] font-[Lato-Bold] text-center'>{item.Recipes_Title}</text></div> 

                              


                            </div>
                            

                            {item.Recipes_Products.map((item, index) => {

return(

  item.Products2 != null && item.Products2.Products_Owner == true ?  
 
 <div className= {index % 2 ===0 ? "bg-zinc-100 w-full flex py-2 " : "bg-white w-full flex py-2 "} >


<p>{item.Products_Unit_Input} {item.Products2.Products2_UnitText2}</p>
<Link to={ "/urun-detay/" + (item.Products2.Products_Name).replace(/\s+/g, '-')} state={{_item : item.Products2, ID: item.Products2.ID }} className="font-medium ml-2 justify-center underline cursor-pointer">
<p className="justify-center">{item.Products2.Products_Name}</p>
</Link>

<p>({item.Recipe_Products_GR}</p>
<p>{item.Products2.Products2_UnitText})</p>
</div>

: item.Products2 != null && item.Products2.Products_Owner == false ?               

<div className= {index % 2 ===0 ? "bg-zinc-100 w-full flex py-2 items-center" : "bg-white w-full flex py-2 items-center"} >
<p>{parseInt(item.Products_Unit_Input)} {item.Products2.Products2_UnitText2}</p>
<Link to={ "/urun-detay/" + (item.Products2.Products_Name).replace(/\s+/g, '-')} state={{_item : item.Products2, ID: item.Products2.ID }} className="font-medium ml-2 justify-center  cursor-pointer" >
{item.Products2.Products_Name}
</Link>



<p>({item.Recipe_Products_GR}</p>
<p>{item.Products2.Products2_UnitText})</p>

</div>:
      item.Products != null && item.Products.Products_Owner == true ?  
       <div>
      <div className= {index%2 ===0 ? "bg-zinc-100 w-full flex py-2 " : "bg-white w-full flex py-2 "} >

 <p>{item.Products_Unit_Input} {item.Products_Units_Text}</p>
 <Link to={ "/urun-detay/" + (item.Products.Products_Name).replace(/\s+/g, '-')} state={{_item : item.Products, ID: item.Products.ID }} className="font-medium ml-2 justify-center underline cursor-pointer">
<p className="justify-center">{item.Products.Products_Name}</p>
</Link>
 </div>
  
</div> :  item.Products != null && 
item.Products.Products_Owner == false ?               
<div>
 <div className= {index%2 ===0 ? "bg-zinc-100 w-full flex py-2 items-center" : "bg-white w-full flex py-2 items-center"} >
 <p>{item.Products_Unit_Input} {item.Products_Units_Text}</p>
 <Link to={ "/urun-detay/" + (item.Products.Products_Name).replace(/\s+/g, '-')} state={{_item : item.Products, ID: item.Products.ID }} className="font-medium ml-2 justify-center  cursor-pointer" >
{item.Products.Products_Name}
</Link>
 </div>



</div> : null)})}

                            {reviewFilter.length > 0 ?  <div className="justify-center items-center">
                                 <div className='sm:text-[20px] md:text-[25px] lg:text-[30px]  font-[Lato-Bold] text-[#0a4022]  text-center'>{item.Recipes_Title}</div>
                                        <div className="product-list-rating text-center">
                                            <div className="ratings">
                                                {processRatingStars(reviewFilter[0].value)} Değerlendirme

                                            </div>
                                                                                                            
                                </div>
                               
                            </div> :   
                                                                   
                                          null}
                        </Link>
                        </div>
               
                 )})}
 </div>
        <div className="divShopping flex w-full flex-col m-10 bg-white">
        <ReactToPrint
          trigger={() => <button className="bg-[#090223] rounded-full content-center hover:bg-[#05145F]  justify-center items-center w-10 h-10 m-1">
          <BsPrinter className="fill-white w-5 h-5  items-center justify-center m-2" />
        </button>}
          content={() => this.componentRef}
        />
      
        {this.state.shoppingData.map((item, i) => 
        
        {
          console.log("al1111",item.Recipes.Recipes_Products)
          
          return(
        <div className="m-1" >
       {item.Recipes.Recipes_Products.map((item1, i) => 
        
       {

           <Shoppingmater
             _item={item1.Products2}
             OrderName={item1.Products2.Products_Name}
             OrderSize={item1.Products_Unit_Input}
             Products_Owner={item1.Products2.Products_Owner}
             OrderDimension={item1.Products_Units_Text}
           />
       })}
       
          </div>
        )})}
       
         
        </div>
      </div>}
    </div>
  );
}
}

export default ShoppingListDetail;
