import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReactPlayer from 'react-player'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import logo from './assets/img/logobuhara.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import zIndex from '@mui/material/styles/zIndex';
import { Link } from 'react-router-dom';


const style = {

  position: "fixed",
  overflow: "hidden",
  zIndex: 1,
  background:'white',
  bottom: '1%',
  left: '155',

  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // box-shadow özelliği
};

class ContentImage extends Component {

  constructor(props) {
      super(props)
      this.state = {
          open: false,
          recipesData:null,
          certifica:[],
          imageVisible:false
      }
  }

  

   _closeKvkk = () => {
    this.props._closeKVKKVisible()
  }

 


render(){

  return (
<div style={style}>

<div className='flex p-1'>
  <div className='flex w-14'>
  <img src={logo} alt="" className='w-full p-2'/>
  </div>

<div className='grid p-1'>
<text className='font-[Lato-Bold]'>Hizmet kalitesi için çerezleri kullanabiliriz.</text>
<text className='font-[Lato-Medium]'>BUHARA BAHARAT’A girerek kullanım izni vermiş sayılırsınız.</text>

<button onClick={()=> this.props._closeKVKKVisible()} className=' hover:from-pink-500 hover:to-yellow-500 absolute right-0 top-0 rounded-full'>
<FontAwesomeIcon className="m-1"  color='gray' size="lg" icon={faClose}/>
</button>
</div>
</div>

<div  className='flex w-full'>
<button onClick={()=> this._closeKvkk()} className='bg-[gray] hover:from-pink-500 hover:to-yellow-500 w-full m-1 rounded-md'><text  className='font-[Lato-Bold] text-white p-1'>Anladım</text></button>
<Link to={"/kvkk"} className='bg-[#e7e7e7] w-full m-1 rounded-md justify-center items-center text-center'><text  className='font-[Lato-Bold]  text-center'>Veri Politikamız</text></Link>
</div>

</div>
  )
}
};

export default ContentImage;
