import React, { Component } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';  
import Modal from '@mui/material/Modal';
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faHeart, faMagnifyingGlassPlus, faShare } from '@fortawesome/free-solid-svg-icons'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #000',
  boxShadow: 24,
};

class ContentKVKK extends Component {

  constructor(props) {
      super(props)
      this.state = {
          open: false,
          recipesData:null,
          allMedia:[]
      }
  }

  async componentDidMount() {
    await this._getCertifica()
}


_getCertifica = async () => {
  try{
    
 await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getMedia/select/`).then((res) => {
       this.setState({allMedia: res.data })   
          
      })
 }
 catch (error) {
   
   console.log("_getCertifica" + error)
 }

}

_openImage= async (url,type) => {
  console.log("url123",url)
  this.props._openImageMedia(url,type)
}

render(){
  return (
<Modal
  open={this.props.openMediaVisible}
  onClose={this.props._closeMediaVisible}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box className='w-1/2' sx={style}>
    <div className="bg-[#fff] p-3" >
      <p className="text-center sm:text-[10px] md:text-[20px] lg:text-[30px] font-['Alfa'] text-[#1b2542]">Medya</p>
    </div>
    <div className="bg-[#ebe5df] w-full p-5 " >

    <div  className="grid lg:grid-cols-4  md:grid-cols-2 sm:grid-cols-2 grid-cols w-full p-1">

    {this.state.allMedia.map((item, i) => (
      item.Media_Type == "video" ?
      <button onClick={()=> this._openImage(item.Media_Url,item.Media_Type)} className='w-full aspect-square p-1 relative'>
           <button onClick={()=> this._openImage(item.Media_Url,item.Media_Type)} className="absolute bg-white border rounded-full -top-3 -right-0"> 
                                             <FontAwesomeIcon className="m-2"  color='black' size="lg" icon={faMagnifyingGlassPlus}/>
                                             </button>
    <ReactPlayer controls={true}  width='100%' height='100%'  url={'https://buharasubdomain.stechomeyazilim.info/' +item.Media_Url} />
                             </button> :
                                   <button onClick={()=> this._openImage(item.Media_Url,item.Media_Type)} className='relative w-full aspect-square p-1'>
                                             <button onClick={()=> this._openImage(item.Media_Url,item.Media_Type)} className="absolute bg-white border rounded-full -top-3 -right-3"> 
                                             <FontAwesomeIcon className="m-2"  color='black' size="lg" icon={faMagnifyingGlassPlus}/>
                                             </button>
                                 <img key={i} src={'https://buharasubdomain.stechomeyazilim.info//' + item.Media_Url} className="w-full h-full" alt="product-img" />
                                                            </button> 
                              ))}
                               </div>
   
    </div> 
  </Box>
</Modal>
  )
}
};

export default ContentKVKK;
