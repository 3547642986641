import React, { Component } from 'react';


import icon1 from '../../../assets/img/001-search.png';
import icon2 from '../../../assets/img/004-shopping-bag.png';
import icon3 from '../../../assets/img/002-stopwatch.png';
import icon4 from '../../../assets/img/003-placeholder.png';

const processblock = [
    {
        id: 1,
        icon: icon1,
        title: 'Arama',
        extclass: 'arrow-1',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.',
    },
    {
        id: 2,
        icon: icon2,
        title: 'Seçme',
        extclass: 'arrow-2',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.',
    },
    {
        id: 3,
        icon: icon3,
        title: 'Satış',
        extclass: 'arrow-1',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.',
    },
    {
        id: 4,
        icon: icon4,
        title: 'Tadım',
        extclass: '',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.',
    },
]

class Process extends Component {
    render() {
        return (
            <section className="section-padding how-it-works bg-light-theme  m-4">
                <div className="container">
                    <div className="section-header-style-2 ">
                        <h6 className="text-light-green sub-title font-[Lato-Bold] m-1 text-[#c5aa77]">Üretim</h6>
                    </div>
                    <div className="row">
                    

<h1 className="lg:text-[35px] text-[30px]  leading-tight text-center ">
              Detay
            </h1>

                    </div>
                </div>
            </section>
        );
    }
}

export default Process;