// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAlveirTzfavQhNInsoR13XedgdwZw6goY",
  authDomain: "buhara-9d233.firebaseapp.com",
  projectId: "buhara-9d233",
  storageBucket: "buhara-9d233.appspot.com",
  messagingSenderId: "1086570258626",
  appId: "1:1086570258626:web:17b6499341a2ab8f1a1b5b",
  measurementId: "G-LX5EH1MPKR"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BP0wipM5RKOxNUTD0BnQffdFSln-Gv8vJWWLUZ6nej5pzyOJTeqMjqZEv1xiFCslhandf4hpitnVz8nDoti4S7Q'}).then((currentToken) => {
    if (currentToken) {
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});