import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link } from 'react-router-dom';

const WhatToShop = (props) => {
  const [porsion, setPorsion] = React.useState(2);
  const [recipesData, setRecipesData] = React.useState([]);

  const handleChange = (event) => {
    setPorsion(event.target.value);
  };



  React.useEffect(() => {
    const recipes =  props.recipesData.Recipes_Products.reduce((item, { Products_SubCategory_ID}) => {
      (item[Products_SubCategory_ID] = item[Products_SubCategory_ID] || []).push(props.recipesData);
      return item;
    }, {});
    setRecipesData(recipes)
    console.log("cats123",recipes); 

  }, []);

  console.log("cat12s123",props.recipesData); 
  return (
    <div className="w-full px-2 lg:px-12 md:px-6 py-2 flex flex-col ">
      <div className="w-full flex justify-between text-start py-2 items-center ">
        <h1 className="xxs:text-[12px] sm:text-[12px] md:text-[16px] lg:text-[24px]  leading-none font-[Lato-Medium] text-[#c5aa77]">
        Tarif İçeriği :
        </h1>
        <div className="flex ">
          <h2 className="flex justify-center items-center px-3">
            Porsiyon Sayısı
          </h2>
          <FormControl >
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select"
              value={porsion}
              onChange={handleChange}
              className="ml-1"
              defaultValue={2}
            >
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={8}>8</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

 
   
      {props.recipesData.Recipes_Products.map((item, index) => {

        return(
        
          item.Products2 != null && item.Products2.Products_Owner == true ?  
         
         <div className= {index % 2 ===0 ? "bg-zinc-100 w-full flex py-2 px-5 " : "bg-white w-full flex py-2 px-5 "} >


    <p>{parseInt(item.Products_Unit_Input) * porsion} {item.Products2.Products2_UnitText2}</p>
    <Link to={ "/urun-detay/" + (item.Products2.Products_Name).replace(/\s+/g, '-')} state={{_item : item.Products2, ID: item.Products2.ID }} className="font-medium ml-2 justify-center underline cursor-pointer">
   <p className="justify-center">{item.Products2.Products_Name}</p>
   </Link>

   <p>({parseInt(item.Recipe_Products_GR) * porsion}</p>
<p>{item.Products2.Products2_UnitText})</p>
    </div>
     
 : item.Products2 != null && item.Products2.Products_Owner == false ?               

    <div className= {index % 2 ===0 ? "bg-zinc-100 w-full flex py-2 px-5 items-center" : "bg-white w-full flex py-2 px-5 items-center"} >
    <p>{parseInt(item.Products_Unit_Input) * porsion} {item.Products2.Products2_UnitText2}</p>
    <Link to={ "/urun-detay/" + (item.Products2.Products_Name).replace(/\s+/g, '-')} state={{_item : item.Products2, ID: item.Products2.ID }} className="font-medium ml-2 justify-center  cursor-pointer" >
{item.Products2.Products_Name}
</Link>


  
<p>({parseInt(item.Recipe_Products_GR) * porsion}</p>
<p>{item.Products2.Products2_UnitText})</p>

</div>:
              item.Products != null && item.Products.Products_Owner == true ?  
               <div>
              <div className= {index%2 ===0 ? "bg-zinc-100 w-full flex py-2 px-5 " : "bg-white w-full flex py-2 px-5 "} >

         <p>{parseInt(item.Products_Unit_Input) * porsion} {item.Products_Units_Text}</p>
         <Link to={ "/urun-detay/" + (item.Products.Products_Name).replace(/\s+/g, '-')} state={{_item : item.Products, ID: item.Products.ID }} className="font-medium ml-2 justify-center underline cursor-pointer">
        <p className="justify-center">{item.Products.Products_Name}</p>
        </Link>
         </div>
          
      </div> :  item.Products != null && 
      item.Products.Products_Owner == false ?               
 <div>
         <div className= {index%2 ===0 ? "bg-zinc-100 w-full flex py-2 px-5 items-center" : "bg-white w-full flex py-2 px-5 items-center"} >
         <p>{parseInt(item.Products_Unit_Input) * porsion} {item.Products_Units_Text}</p>
         <Link to={ "/urun-detay/" + (item.Products.Products_Name).replace(/\s+/g, '-')} state={{_item : item.Products, ID: item.Products.ID }} className="font-medium ml-2 justify-center  cursor-pointer" >
  {item.Products.Products_Name}
 </Link>
         </div>
  
       

</div> : null)})}





    </div>
  );
};

export default WhatToShop;
