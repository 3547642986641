import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Content from '../sections/Kvkk/Content';

class Contact extends Component {
    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>Buhara Baharat | İletişim</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
              {/* <Header/> */}
                <Content/>
               
            </Fragment>
        );
    }
}

export default Contact;