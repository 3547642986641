import React, { Component } from 'react';
import { fetchFlavorIconRecipes } from '../../../helper/helper';

import 'react-toastify/dist/ReactToastify.css';
import Moment from 'moment'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import imgKnife from '../../../assets/img/knife.png';
import Pot from '../../../assets/img/pot.png';
import PRoductCount from '../../../assets/img/note.png';

class About extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            recipesDataShoppingList:[],
            recipesData:null
        }
    }

    async componentDidMount() {
        await this._getReciepsData()
        await this._getReciepsShopping()
    }

    _getReciepsData = async () => {
        try{

        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipesData/search/${this.props.recipesData.ID}`)
         .then((res) => {
            console.log("lknsdlkfn",res.data[0])
             this.setState({ recipesData : res.data[0] })   
                
            })
       }
       catch (error) {
         console.log("errorAS" + error)
       }
    }

    _getReciepsShopping = async () => {
        try{
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();

        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/shoppingListCheck/select/${this.props.recipesData.ID}`)
         .then((res) => {
             this.setState({recipesDataShoppingList: res.data, ipAdress : data.ip })   
                
            })
       }
       catch (error) {
         console.log("errorAS" + error)
       }
    }

    
   showToast(event, value, type) {
    toast.success(value, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
}



_saveShoppingList= async (ID) => {

    var testDateUtc = Moment.utc(new Date());       
    var localDate = Moment(testDateUtc).local();

    try{
        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipesProducts/select/${ID}`)
         .then(async(res) => {

            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();


            res.data.map((item, i) => 
            {                 

                return(
            fetch("https://buharaapi.stechomeyazilim.info:5210/recipesShoppingList/insert", {
            method: "post",
            body: JSON.stringify({
                User_UUID: data.ip, // This is the body part
                Recipes_Products_ID: item.ID,
               Created_DateTime: localDate,
               Recipes_ID:this.props.recipesData.ID
            }),
            headers: { "Content-Type": "application/json" }
            })
            .then((res) => {
                this.setState({recipesDataShoppingList: [{
                    User_UUID: data.ip, // This is the body part
                    Recipes_Products_ID: item.ID,
                   Created_DateTime: localDate,
                   Recipes_ID:this.props.recipesData.ID
                }]
             })   
               })
            .catch(err => console.log(err))
             )})

             this.showToast('Buhara Baharat', "Tarifi başarıyla alışveriş listesine eklediniz!", 'success')

         })

}
catch (error) {
  
  console.log("errorAS" + error)
}


}


_deleteFavori= async (ID) => {
    try{

        console.log("_deleteFavori",ID)
        await axios.delete(`https://buharaapi.stechomeyazilim.info:5210/recipes/delete/${ID}`)
        .then(async(res) => {
            await this._getReciepsData()
        })
    
   }
   catch (error) {
     console.log("errordeleteFavori" + error)
   }  
}


   _saveRecipes = async (ID) => {

    var testDateUtc = Moment.utc(new Date());       
    var localDate = Moment(testDateUtc).local();

    const response = await fetch('https://api.ipify.org?format=json');
    const dataIP = await response.json();

    const data = {
        Users_Recipes_Fav_UUID: dataIP.ip, // This is the body part
        Recipes_ID: ID,
        Created_DateTime: localDate
};

fetch("https://buharaapi.stechomeyazilim.info:5210/recipesFav/insert", {
method: "post",
body: JSON.stringify(data),
headers: { "Content-Type": "application/json" }
})
.then(res =>  this.showToast('Buhara Baharat', "Tarifi başarıyla favoriye eklediniz!", 'success'))
.catch(err => console.log(err))
 await this.props._getRecieps()

}
    render() {
    
        return (
            <section className="bg-[white] section-padding restaurent-about smoothscroll m-10" id="about">
             {this.state.recipesData != null ?   
             <div className="container">
                    <div className="row ">
                        <div className="col-md-5 m-2  bottom-10 right-10">
                        <img src={'https://buharasubdomain.stechomeyazilim.info/' + this.state.recipesData.Recipes_Image} className=" h-29"/>                  
                        </div>
                        <div className="col-md-6 justify-center mt-10">
                            <h3 className="text-light-black fw-700 title">{this.state.recipesData.Recipes_Title} Yemek Tarifi</h3>

                          <div className='flex'>
                            <div  className="m-2 rounded-lg text-custom-white square-tag w-10">
                            <img className="w-1/2 md:w-1/2" src={imgKnife} />
                            <p className="text-light-white m-2 text-center">{this.state.recipesData.Recipes_Preparation_Time} DK.</p>
                            </div>

                            <div  className="m-2 rounded-lg text-custom-white square-tag w-10">
                            <img className="w-1/2 md:w-1/2" src={Pot} />
                            <p className="text-light-white m-2 text-center">{this.state.recipesData.Recipes_Cooking_Time} DK.</p>
                            </div>

                            <div  className="m-2 rounded-lg text-custom-white square-tag w-10">
                            <img className="w-1/2 md:w-1/2" src={PRoductCount} />
                            <p className="text-light-white m-2 text-center">{this.state.recipesData.Recipes_Products.length} Ürün</p>
                            </div>
                            </div>

                            <ul className="social-media pt-2">
                            {this.state.recipesData.Recipes_Icon.map((flavor, i) => (
                                                                <span key={i} className="m-2 rounded-lg border-2 text-custom-white square-tag">
                                                                    <img src={fetchFlavorIconRecipes(flavor.Recipes_Icon_Text)} alt={flavor.Recipes_Icon_Text} />
                                                                </span>
                                                            ))}
                            </ul>
                            <p className="text-light-white m-2">{this.state.recipesData.Recipes_ShortText}</p>
                            <p className="text-light-white m-2">{this.state.recipesData.Recipes_Desc}</p>

                        </div>

                       
                    </div>

                  
                </div>  : null}

                <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
            </section>
        );
    }
}

export default About;