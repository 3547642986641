import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import imgBanner from '../../../assets/img/header.png';
import { Button } from 'react-bootstrap';
import Header from './Header';
import LoadingSpinner from "../../../LoadingSpinner";


class Localdeals extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productsData:[],
            pageNumber:0,
            categoryID:0,
            productsDataFilter:[],
            filterData:false,
            isLoading:true
        }
    }
    async componentDidMount() {
        await this._getProducts()
    }

    _loadMore = () => {
       if(this.state.countData> this.state.pageNumber) {

        this.setState({pageNumber: parseInt(this.state.pageNumber) + 8 },
        ()=> {
            this._getProductsLoadMore( this.state.pageNumber,this.state.categoryID)
        })
    }
    }

    _filterCategory= async (ID,type) => {
        try{
        if(type == "all"){

            
            this.setState({categoryID: 0,pageNumber:0 },async()=> {
                await axios.get(`https://buharaapi.stechomeyazilim.info:5210/productGet/select/${this.state.pageNumber}/${this.state.categoryID}`).then((res) => {
                    console.log("d12321113",res.data.value)    
                   
                   this.setState({productsData: res.data.value , countData : res.data["@odata.count"],filterData:false})   
            })   

         
               })
          }else{

            let filterData = this.state.productsData.filter((x) => x.Products_SubCategory.Products_Category.ID == ID)
            this.setState({ productsDataFilter: filterData,filterData:true }) 
          }
      
         
       }
       catch (error) {
         
         console.log("errorAS" + error)
       }
    
    }

    _filterSubCategory= async (ID,type) => {
        try{
          console.log("jsankla", type)
      
          let filterData = this.state.productsData.filter((x) => x.Products_SubCategory_ID == ID)
          this.setState({ productsDataFilter: filterData,filterData:true }) 
    
         
       }
       catch (error) {
         
         console.log("errorAS" + error)
       }
    
    }
    
    _getProducts = async () => {
        try{
   if(this.props._ID != null )  {
    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/productGet/select/${this.state.pageNumber}/${this.props._ID}`).then((res) => {
        this.setState({productsData: res.data.value, isLoading:false, countData : res.data["@odata.count"] })   
       })
   } else{
    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/productGet/select/${this.state.pageNumber}/${this.state.categoryID}`).then((res) => {
        this.setState({productsData: res.data.value, isLoading:false, countData : res.data["@odata.count"] })   
       })
   }
      
       }
       catch (error) {
         
         console.log("errorAS" + error)
       }
    
    }

    _getProductsLoadMore = async (pageNumber,categoryID) => {
        try{
       await axios.get(`https://buharaapi.stechomeyazilim.info:5210/productGet/select/${pageNumber}/${categoryID}`).then((res) => {
                
       console.log("pageNumber12312312",pageNumber,categoryID,res.data.value)
       this.setState(prevState => ({
        productsData: [...prevState.productsData, ...res.data.value]
      }),()=>{
        console.log("pageNumber12312c312",this.state.productsData)

      })

            })
       }
       catch (error) {
         
         console.log("_getProductsLoadMore" + error)
       }
    
    }
    render() {
        return (
            <section className="local-deals section-padding">
             <LoadingSpinner show = {this.state.isLoading}/> 

                <div className="relative h-1/2">  
                
                <div  className='absolute xxs:-bottom-100 sm:-bottom-80 md:-bottom-40 w-5/6 left-1/2 transform -translate-x-1/2'>
                 <Header _itemSub={this.props._itemSub} filterCategory={this._filterCategory} _subCategoryID= {this.props._ID} _filterSubCategory={this._filterSubCategory} _productsData={this.state.productsData}/>
                 </div>
                <img className='w-full h-64'  src={imgBanner}/>
                
                 
                 <div className='absolute xxs:hidden top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                <p className='font-[IndieFlower] sm:text-[20px] md:text-[50px] lg:text-[80px] text-center text-[white]'>BÜTÜN ÜRÜNLER</p>
                </div>
                
                </div>

              
                <div className="container mt-32">
                    <div className="row">
                    
                    {this.state.filterData == true ? 
                     this.state.productsDataFilter.map((item, i) => (
                        <div key={i} className="col-lg-3 col-md-6 ">
                            <div className="product-box mb-xl-10 bg-[#0a4022] hover:bg-[#FFC04B]">
                                <div className="product-box-2 bg-[white] ">
                                    <div className="product-img">
                                        <Link  to={"/urun-detay/" + (item.Products_Name).replace(/\s+/g, '-').replace('?', '').replace(/Ğ/gim, "g")
		.replace(/Ü/gim, "u")
		.replace(/Ş/gim, "s")
		.replace(/I/gim, "i")
		.replace(/İ/gim, "i")
		.replace(/Ö/gim, "o")
		.replace(/Ç/gim, "c")
		.replace(/ğ/gim, "g")
		.replace(/ü/gim, "u")
		.replace(/ş/gim, "s")
		.replace(/ı/gim, "i")
		.replace(/ö/gim, "o")
		.replace(/ç/gim, "c")} state={{_item : item,ID: item.ID}}>
                                            <img src={'https://buharasubdomain.stechomeyazilim.info//' + item.Products_Image} className="img-fluid" alt="product-img" />
                                        </Link>
                                    </div>
                                  
                                </div>
                                <div className="product-caption">
                                        <div className="title-box ">
                                            <h6 className="product-title "><Link to={"/urun-detay/" + (item.Products_Name).replace(/Ğ/gim, "g")
		.replace(/Ü/gim, "u")
		.replace(/Ş/gim, "s")
		.replace(/I/gim, "i")
		.replace(/İ/gim, "i")
		.replace(/Ö/gim, "o")
		.replace(/Ç/gim, "c")
		.replace(/ğ/gim, "g")
		.replace(/ü/gim, "u")
		.replace(/ş/gim, "s")
		.replace(/ı/gim, "i")
		.replace(/ö/gim, "o")
		.replace(/ç/gim, "c").replace(/\s+/g, '-').replace('?', '')} state={{_item : item,ID: item.ID}} className="text-white">{item.Products_Name}</Link></h6>
                                        </div>
                                      
                                    </div>
                            </div>
                        </div>
                    ))
                    :
                    this.state.productsData.map((item, i) => (
                        <div key={i} className="col-lg-3 col-md-6 ">
                            <div className="product-box mb-xl-10 bg-[#0a4022] hover:bg-[#FFC04B]">
                                <div className="product-box-2 bg-[white]">
                                    <div className="product-img m-3">
                                        <Link to={"/urun-detay/" + (item.Products_Name).replace(/Ğ/gim, "g")
		.replace(/Ü/gim, "u")
		.replace(/Ş/gim, "s")
		.replace(/I/gim, "i")
		.replace(/İ/gim, "i")
		.replace(/Ö/gim, "o")
		.replace(/Ç/gim, "c")
		.replace(/ğ/gim, "g")
		.replace(/ü/gim, "u")
		.replace(/ş/gim, "s")
		.replace(/ı/gim, "i")
		.replace(/ö/gim, "o")
		.replace(/ç/gim, "c").replace(/\s+/g, '-').replace('?', '')} state={{_item : item,ID: item.ID}}>
                                            <img src={'https://buharasubdomain.stechomeyazilim.info//' + item.Products_Image} className="img-fluid" alt="product-img" />
                                        </Link>
                                    </div>
                                  
                                </div>
                                <div className="product-caption">
                                        <div className="title-box ">
                                            <h6 className="product-title "><Link to={"/urun-detay/" + (item.Products_Name).replace(/Ğ/gim, "g")
		.replace(/Ü/gim, "u")
		.replace(/Ş/gim, "s")
		.replace(/I/gim, "i")
		.replace(/İ/gim, "i")
		.replace(/Ö/gim, "o")
		.replace(/Ç/gim, "c")
		.replace(/ğ/gim, "g")
		.replace(/ü/gim, "u")
		.replace(/ş/gim, "s")
		.replace(/ı/gim, "i")
		.replace(/ö/gim, "o")
		.replace(/ç/gim, "c").replace(/\s+/g, '-').replace('?', '')} state={{_item : item,ID: item.ID}} className="text-white">{item.Products_Name}</Link></h6>
                                        </div>
                                      
                                    </div>
                            </div>
                        </div>
                    ))
                    }
                       
                    </div>
                    <div className="row justify-content-md-center">
                   

                        <div className="col-md-4 justify-center items-center"> <Button onClick={()=> this._loadMore()} className=" bg-[#0a4022] text-center m-2 font-[Lato-Medium] font-[20px] text-[white]">Daha Fazla
                       {/* <FontAwesomeIcon  color='white' size="lg" icon={faCircleArrowDown}/>*/}</Button> 
                      
                        </div>
                    </div>
                </div>


            </section>
        );
    }
}

export default Localdeals;