import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import Moment from 'moment';

class Blogpost extends Component {
    constructor() {
        super();
       this.state = { blogData:[] }
}

   async componentDidMount() {
       await this._getBlog()
   }


   _getBlog = async () => {
    try{
    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getBlog/select/`)
     .then(res => {
         this.setState({blogData: res.data })})
   }
   catch (error) {
     
     console.log("errorAS" + error)
   }
}

    render() {
        return (
            <section className="section-padding our-blog restaurent-ad m-10">
                <div className="container">
                    <div className="section-header-style-2">
                        <h6 className=" sub-title text-[30px]  font-[Lato-Bold] text-[#c5aa77]">Blog Yazılarımız</h6>
                        <h3 className=" text-[#0a4022] header-title font-[Lato-Medium]">Son Blog Yazıları</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blog-box mb-xl-20">
                                {/* article 1 */}
                                {this.state.blogData.slice(0, 1).map((item, i) => ( <article className="post">
                                    <div className="blog-img p-relative">
                                        <Link  to={"/blog-detay/"  + (item.Blog_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}} >
                                            <img src={'https://buharasubdomain.stechomeyazilim.info/' + item.Blog_Image} alt="blog-img" />
                                        </Link>
                                        <div className="overlay padding-20">
                                            <div className="blog-meta">
                                                <h4 className="text-custom-white mb-2 fw-600">{item.Blog_Title}</h4>
                                                <Link to={{pathname:  "/blog/"}} className="text-custom-white fw-700 fs-18">Daha Fazlasını Oku</Link>
                                            </div>
                                        </div>
                                    </div>
                                </article> 
                                ))}
                                {/* article 1 */}
                                <div className="side-blog">
                                    <div className="blog-list padding-tb-10">
                                        {/* article 1 */}
                                        {this.state.blogData.slice(0, 8).map((item, i) => (
                                            <article key={i} className="side-post padding-20 u-line">
                                                <div className="content-wrap">
                                                    <div className="entry-meta-content">
                                                        <div className="entry-tye">
                                                            <span className="text-[#0a4022] font-[Lato-Medium]">{Moment(item.Created_DateTime).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                    </div>
                                                    <h6 className="entry-title no-margin">
                                                        <Link to={"/blog-detay/"  + (item.Blog_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}} className="font-[Title]">{item.Blog_Title}</Link>
                                                    </h6>
                                                </div>
                                                <div className="thumb-img">
                                                    <Link to={"/blog-detay/"  + (item.Blog_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}}>
                                                        <img src={'https://buharasubdomain.stechomeyazilim.info/' + item.Blog_Image} alt="" />
                                                    </Link>
                                                </div>
                                            </article>
                                        ))}
                                        {/* article 1 */}
                                    </div>
                                </div>
                            </div>
                       
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Blogpost;