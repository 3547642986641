import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Footer from '../layouts/Footer';
import Content from '../sections/blogtwo/Content';
import axios from 'axios';

class Blogtwo extends Component {
    constructor() {
        super();
       this.state = { 
        blogData:[]
     }
}

   async componentDidMount() {
       await this._getBlog()
       window.scrollTo(0, 0);

   }


   _getBlog = async () => {
    try{
    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getBlog/select/`)
     .then(res => {
         this.setState({blogData: res.data })})
   }
   catch (error) {
     
     console.log("errorAS" + error)
   }
}

    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>Buhara Baharat | Blog</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>               
                {this.state.blogData.length > 0 ? <Content _blogData = {this.state.blogData}/> : null}
                <Footer/>
            </Fragment>
        );
    }
}

export default Blogtwo;