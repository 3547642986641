import React,{useState} from 'react';
import { Routes, Route } from "react-router-dom";

import 'moment/locale/tr';

// Pages
import Homethree from "./components/pages/Homethree";
import Production from "./components/pages/Production";
import About from "./components/pages/About";
import Media from "./components/pages/Media";
import Dealer from "./components/pages/Dealer";
import Certifica from "./components/pages/Certifica";
import ProductDetail from "./components/pages/ProductDetailNew";
import Shoppinglist from "./components/pages/Shoppinglist";

import Blogtwo from "./components/pages/Blogtwo";
import Blogdetails from "./components/pages/Blogdetails";
import Restaurant from "./components/pages/Restaurant";

import Listview from "./components/pages/Listview";

import ListviewFavorite from "./components/pages/ListviewFavorite";
import Urunlerimiz from "./components/pages/Urunlerimiz";
import Orderdetails from "./components/pages/Orderdetails";
import Contact from "./components/pages/Contact";
import KVKKPage from "./components/pages/KVKK";

import Error from "./components/pages/Error";
import { isMobile } from "react-device-detect";
import { fetchToken, onMessageListener } from './firebase';
import KVKK from './KVKKModal';


function App() {

  const [showKVKK, setShowKVKK] = React.useState(true);


  React.useEffect(() => {
    async function _getCheck() {
        try{    
          const KVKKCheck =  JSON.parse(localStorage.getItem('KVKKCheck'))
          if(KVKKCheck==null){
            setShowKVKK(true)
          }else{
            setShowKVKK(false)
          }
       }
       catch (error) {
         
         console.log("errorAS" + error)
       }
    }

    _getCheck()
  }, [])
      

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);

  onMessageListener().then(payload => {
    setNotification({title: payload.notification.title, body: payload.notification.body})
    setShow(true);
    console.log(payload);
  }).catch(err => console.log('failed: ', err));

  const onShowNotificationClicked = () => {
    setNotification({title: "Notification", body: "This is a test notification"})
    setShow(true);
  }

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({title: payload.notification.title, body: payload.notification.body})
    console.log(payload);
  }).catch(err => console.log('failed: ', err));



const _closeKVKKVisible = () => {
	setShowKVKK(false)
  localStorage.setItem('KVKKCheck', true);
  }


  return (
    <div  outerContainerId={'outer-container'} className='z-12' style={{ marginLeft: isMobile == false ?110 : 0}}>
     
    <Routes >
          <Route exact path="/" element={<Homethree />} />
 

          <Route  path={"/hakkimizda"} element={<About />} />
          <Route  path={"/medya"} element={<Media />} />

          <Route  path={"/bayilerimiz"} element={<Dealer />} />
          <Route  path={"/uretim"} element={<Production />} />
          <Route  path={"/kalite-belgelerimiz"} element={<Certifica />} />

          <Route path={"/urun-detay/:id"} element={<ProductDetail />} />
          <Route path={"/alisveris-listesi"} element={<Shoppinglist />} />

          <Route  path="/blog" element={<Blogtwo />} />
          <Route  path="/blog-detay/:id" element={<Blogdetails/>} />
          <Route  path={"/yemek-tarifi/:id"} element={<Restaurant />} />
          
          <Route   path={"/yemek-tarifleri"}  element={<Listview />} />
          <Route   path={"/favori-tariflerim"}  element={<ListviewFavorite />} />

          <Route  path={"/urunlerimiz"} element={<Urunlerimiz />} />


          <Route  path="/iletisim" element={<Contact />} />
          <Route  path="/error-page" element={<Error />} />
        
          <Route  path="/kvkk" element={<KVKKPage />} />

        
 
    </Routes>


    {showKVKK == true ? <KVKK openKVKK={showKVKK} _closeKVKKVisible={_closeKVKKVisible}/> : null}

    </div>
  );
}

export default App;
