import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { processRatingStars, fetchFlavorIcon } from '../../../helper/helper';
import axios from 'axios';
import Header from './Header';
import imgBanner from '../../../assets/img/header.png';
import { fetchFlavorIconRecipes } from '../../../helper/helper';
import LoadingSpinner from "../../../LoadingSpinner";



class Content extends Component {
    constructor(props) {
        super(props)
        this.state = {
            recipesData:[],
            recipesDataCategory:[],
            recipesDataFilter:[],
            resReview:[],
            isLoading:true
        }

        this._sortData = this._sortData.bind(this);

    }
    async componentDidMount() {
        await this._getProducts()
        window.scrollTo(0, 0);

    }

    _sortData= async (event) => {
        try{
            this.setState({isLoading:true })   
    
       if(event.target.value == "Son Eklenenler"){
        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipesOrderBy/select/ID`)
        .then(async(res) => {
            this.setState({recipesData: res.data,isLoading:false },()=> {
            /* res.data.map(async(item, i) => (
                 await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipes/review/${item.ID}`)
                 .then((resReview) => {
                     this.setState(prevState => ({
                         resReview: [...prevState.resReview, {ID : item.ID,reviewValue : resReview.data.value}]
                     }))
                    })
             ))*/
            })    
           })
              } 
      if(event.target.value == "Varsayılan"){
        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipes/select/`)
        .then(async(res) => {
            this.setState({recipesData: res.data,isLoading:false },()=> {
          /*   res.data.map(async(item, i) => (
                 await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipes/review/${item.ID}`)
                 .then((resReview) => {
                     this.setState(prevState => ({
                         resReview: [...prevState.resReview, {ID : item.ID,reviewValue : resReview.data.value}]
                     }))
                    })
             ))  */
            })  
           })
      } if(event.target.value == "Trend Tarifler"){
        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipesOrderBy/select/Recipes_Trend`)
               .then(async(res) => {
                   this.setState({recipesData: res.data,isLoading:false },()=> {
                /*    res.data.map(async(item, i) => (
                        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipes/review/${item.ID}`)
                        .then((resReview) => {
                            this.setState(prevState => ({
                                resReview: [...prevState.resReview, {ID : item.ID,reviewValue : resReview.data.value}]
                            }))
                           })
                    )) */
                   })   
                  })
      }
      if(event.target.value == "Değerlendirme"){
        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipesOrderBy/select/ID`)
               .then(async(res) => {
                   this.setState({recipesData: res.data,isLoading:false },()=> {
                  /*  res.data.map(async(item, i) => (
                        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipes/review/${item.ID}`)
                        .then((resReview) => {
                            this.setState(prevState => ({
                                resReview: [...prevState.resReview, {ID : item.ID,reviewValue : resReview.data.value}]
                            }))
                           })
                    ))*/
                   })    
                  })
      }
      if(event.target.value == "Varsayılan"){
        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipes/select/ID`)
               .then(async(res) => {
                   this.setState({recipesData: res.data,isLoading:false },()=> {
                 /*   res.data.map(async(item, i) => (
                        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipes/review/${item.ID}`)
                        .then((resReview) => {
                            this.setState(prevState => ({
                                resReview: [...prevState.resReview, {ID : item.ID,reviewValue : resReview.data.value}]
                            }))
                           })
                    ))*/
                   })  
                  }) 
      }
       
        }
           catch (error) {
            console.log("_sortData" + error)
          }
    }

    _getProducts = async () => {
        try{

            console.log("sdfsdf11",this.props)
            if(this.props._ID != null )  {
            await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipesCategory/select/`)
            .then((res) => {
                this.setState({recipesDataCategory: res.data,isLoading:false })   
                   
               })
            
               await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipesCategory/search/${this.props._ID}`)
               .then(async(res) => {
                   this.setState({recipesData: res.data },()=> {
               /*     res.data.map(async(item, i) => (
                        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipes/review/${item.ID}`)
                        .then((resReview) => {
                            this.setState(prevState => ({
                                resReview: [...prevState.resReview, {ID : item.ID,reviewValue : resReview.data.value}]
                            }))
                           })
                    ))*/
                   })   
                  })
                }else{
                    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipesCategory/select/`)
            .then((res) => {
                this.setState({recipesDataCategory: res.data,isLoading:false })   
                   
               })
            
               await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipes/select/`)
               .then(async(res) => {
                   this.setState({recipesData: res.data },()=> {
             /*       res.data.map(async(item, i) => (
                        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipes/review/${item.ID}`)
                        .then((resReview) => {
                            this.setState(prevState => ({
                                resReview: [...prevState.resReview, {ID : item.ID,reviewValue : resReview.data.value}]
                            }))
                           })
                    ))*/
                   })   
                  })
                }
         
       }
       catch (error) {
         
         console.log("_getProducts" + error)
       }
    
    
    }

    _filterRecipe = async (ID,type) => {
        try{

     if(type=="all"){
                await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipesCategory/search/0`)
                .then((res) => {
                    this.setState({recipesData: res.data })   
                       
                   })
            }else{
                await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipesCategory/search/${ID}`)
                .then((res) => {
                    this.setState({recipesData: res.data })   
                       
                   })
            }
          

            {/**   await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipesCategory/search/0`)
                   .then((res) => {
                       this.setState({recipesData: res.data })   
                          
                      })*/}
                 
       }
       catch (error) {
         
         console.log("_filterRecipe" + error)
       }
    
    
    }


    render() {
        return (
            <div className="most-popular section-padding">
             <LoadingSpinner show = {this.state.isLoading}/> 

<div className="relative">  
                
                <div  className='absolute -bottom-10 w-5/6 left-1/2 transform -translate-x-1/2'>
                 <Header _itemSub={this.props._itemSub} filterCategory={this._filterCategory} _subCategoryID= {this.props._ID}  _data={this.state.recipesDataCategory} _filterRecipe={this._filterRecipe}/>
                 </div>

                <img className='w-full'  src={imgBanner}/>
            
                 
                 <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  xxs:hidden'>
                <p className='font-[IndieFlower] text-[15px] sm:text-[40px] md:text-[60px] lg:text-[80px] text-center text-[white]'>YEMEK TARİFLERİ</p>
                </div>
                
                </div>
                
                <div className="container-fluid mt-10">
                    <div className="row">
                        
                        <div className="col-lg-12 browse-cat border-0">
                            <div className="row">
                             
                              {/*<div className="col-12"><Sidebar/> </div> */}
                                <div className="col-12">
                                 
                                    <div className="sort-tag-filter padding-15">
                                        <div className="restaurent-tags">
                                        <h3 className="text-light-black header-title title-2 text-[14px]  sm:text-[18px] md:text-[25px] lg:text-[30px] font-[Lato-Bold] m-1 text-[#c5aa77]">Popüler Yemek Tarifleri <small><a  className="text-dark-white fw-600 font-[Lato-Medium-Medium]">{this.state.recipesData.length} Adet Tarif</a></small></h3>
                                        </div>
                                        <div className="sorting"> <span className="text-dark-white fs-16 fw-600">Sıralama: </span>
                                            <select onChange={this._sortData}>
                                                <option>Varsayılan</option>
                                                <option>Değerlendirme</option>
                                                <option>Son Eklenenler</option>
                                                <option>Trend Tarifler</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="grid xxl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xxs:grid-cols-2 justify-center">

                                    {this.state.recipesData.map((item, i) =>
                                            {
                                               let reviewFilter = this.state.resReview.filter((x) => x.ID == item.ID)
 
                                                return(
                                                    <Link to={"/yemek-tarifi/"  + (item.Recipes_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}}  key={i} className= {i % 2 == 1 ? "bg-[#eaeaea] product-list-view  border-[1px] border-[#0a4022] m-2 rounded-md" : "product-list-view border-[1px] border-[#0a4022] m-2 rounded-md"}>
                                            <div className="product-list-info">
                                                <div className="product-list-img">
                                                    <Link  to={ "/yemek-tarifi/" + (item.Recipes_Title).replace('?', '').replace(/\s+/g, '-')} state={{ ID: item.ID, _item:item, _recipesReview : reviewFilter.length > 0 ? reviewFilter : 0 }}>
                                                        <img  src={'https://buharasubdomain.stechomeyazilim.info/' +  item.Recipes_Image}  className="img-fluid rounded-md" alt="#" />
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="product-right-col">
                                                <div className="product-list-details">
                                                    <div className="product-list-title">
                                                        <div className="product-info">
                                                            <h6><Link to={ "/yemek-tarifi/" + (item.Recipes_Title).replace('?', '').replace(/\s+/g, '-')} state={{ ID: item.ID, _item:item , _recipesReview : reviewFilter.length > 0 ? reviewFilter : 0 }} className="text-light-black fw-600 font-[Lato-Medium]">{item.Recipes_Title}</Link></h6>
                                                            <p className="text-light-white fs-12">
                                                                <span key={i}>{item.Recipes_Category.Recipes_Category_Text}</span>
                                                            </p>
                                                            

                                                            {item.Recipes_Icon.length > 0 ? <ul className="grid lg:grid-cols-5 lg:gap-4 md:gap-3 sm:gap-1 md:grid-cols-4 sm:grid-cols-3 xxs:grid-cols-2 justify-center">{item.Recipes_Icon.map((flavor, i) => (
                                                                <span key={i} className="m-1 rounded-lg border-2 text-custom-white square-tag ">
                                                                    <img src={fetchFlavorIconRecipes(flavor.Recipes_Icon_Text)} alt={flavor.Recipes_Icon_Text} />
                                                                </span>
                                                            ))} </ul> : null}
                                              

                                                            </div>
                                                    </div>
                                                    {reviewFilter.length > 0 ? <div className="product-detail-right-box">
                                                        <div className="grid text-center">
                                                            <div className="ratings">
                                                                {processRatingStars(reviewFilter[0].reviewValue)}
                                                            </div>
                                                            
                                                            <div className="rating-text">
                                                                <p className="text-light-white fs-12">{item.Recipes_Rewies.length} Değerlendirme</p>
                                                                
                                                            </div>
                                                           
                                                        </div>
                                                       
                                                    </div> : null}

                                                                                                            
                                                    { item.Recipes_Trend == true ?  <div className="product-list-tags">
                                                          
                                                          <span className="rectangle-tag bg-[#FFC04B] text-custom-white text-center p-1">Trend Tarif</span>
                                                         
                                                        </div> : null}
                                                </div>
                                                   
                                                  
                                            </div>
                                        </Link>
                                    )})}
                                      </div>
                                </div>
                               
                              
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;