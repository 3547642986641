import React from 'react'

const HowToPrepareMatter = (props) => {
  return (
    <div className="w-full pr-12  lg:pl-12 pl-3 flex flex-col ">
          <div className="w-full flex flex-col text-start">
            <h1 className="font-medium text-[24px] leading-none lg:pt-0 pt-4 font-[Lato-Medium] text-[#c5aa77]">
             Nasıl Hazırlanır?
            </h1>

            <div className="flex w-full items-center justify-center">
              <div className="w-full flex  justify-center">
                <div className="flex text-left  font-[Lato-Medium]">
                  <p>{props.recipesData.Recipes_Desc}</p>
                  
                </div>
              </div>
            
              
            </div>
          </div>
        </div>
  )
}

export default HowToPrepareMatter