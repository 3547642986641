import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import axios from 'axios';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipesData: [],
            recipesStar: []
        };
    }

    async componentDidMount() {
        await this._getProducts();
    }

    _getProducts = async () => {
        try {
            const res = await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipes/select/`);
            const recipesData = res.data;
            this.setState({ recipesData });

            recipesData.forEach(async (item) => {
                const starRes = await axios.get(`https://buharaapi.stechomeyazilim.info:5210/GetStar(Recipes_ID=${item.ID})`);
                this.setState((prevState) => ({
                    recipesStar: {
                        ...prevState.recipesStar,
                        [item.ID]: starRes.data
                    }
                }));
            });
        } catch (error) {
            console.log("_getProducts" + error);
        }
    };

    render() {
        const settings = {
            slidesPerView: 3,
            spaceBetween: 0,
            loop: false,
            navigation: true,
            modules: [Navigation],
        };

        return (
            <Swiper className="advertisement-slider swiper-container mb-xl-20" {...settings}>
                {this.state.recipesData.map((item, i) => (
                    <SwiperSlide key={i}>
                        <div className="testimonial-wrapper">
                            <div className="testimonial-box">
                                <div className="testimonial-caption padding-15">
                                    <div className="testimonial-user-box">
                                        <img src={'https://buharasubdomain.stechomeyazilim.info/' + item.Recipes_Image} className="h-full" alt="" />
                                    </div>
                                    <h5 className="font-[Lato-Bold] text-center text-[19px]">
                                        <Link 
                                            to={"/yemek-tarifi/" + item.Recipes_Title.replace('?', '').replace(/\s+/g, '-')} 
                                            state={{ ID: item.ID, _item: item }} 
                                            className="text-[#0a4022]"
                                        >
                                            {item.Recipes_Title}
                                        </Link>
                                    </h5>
                                    <div className="bg-[#0a4022] justify-center items-center rounded-lg w-full flex m-1">
                                        <Link 
                                            to={"/yemek-tarifi/" + item.Recipes_Title.replace('?', '').replace(/\s+/g, '-')} 
                                            state={{ ID: item.ID, _item: item }} 
                                            className="text-[white] text-center p-2"
                                        >
                                            Tarifi İncele
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        );
    }
}

export default Testimonial;
