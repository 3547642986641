import React, { useEffect, useState,Fragment } from "react";
import { Link } from 'react-router-dom';
import { faMailBulk, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import ContentKVKK from "../../Sidebar/ContetnKVKK";
import ContentCertifica from "../../Sidebar/ContentCertifica";
import ContentMedia from "../../Sidebar/ContentMedia";
import ContentStand from "../../Sidebar/ContentStand";
import ContentImage from "../../Sidebar/ContentImage";
import ContentImageMedia from "../../Sidebar/ContentImageMedia";
import LoadingSpinner from "../../LoadingSpinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import logo from '../../assets/img/logobuhara.png';
import { FaGooglePlay, FaApple } from 'react-icons/fa'; // React Icons kütüphanesinden Google Play ve App Store ikonlarını içe aktarıyoruz

const Footer = () => {

  const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.buharabaharatmobil';
  const appStoreLink = 'https://apps.apple.com/tr/app/buhara/id6463096365?l=tr';

    const [ dataVisible , setVisible ] = useState(false);
    const [ dataCertificaVisible , setCertificaVisible ] = useState(false);
    const [ data , setData ] = useState(false);
  
    const [ dataVisibleMedia , setVisibleMedia ] = useState(false);
    const [ dataVisibleStand , setVisibleStand ] = useState(false);
  
    const [ imageVisible , setImageVisible ] = useState(false);
    const [ imageLink , setImageLink ] = useState('');
    const [ imageVisibleMedia , setImageVisibleMedia ] = useState(false);
    const [ imageLinkMedia , setImageLinkMedia ] = useState(false);
    const [ imageLinkMediaType , setImageLinkMediaType ] = useState('');


    const _closedataStandVisible = () => {
        setVisibleStand(false)
        setImageVisible(false)
      }
    
      const _closeMediaVisible = () => {
        setVisibleMedia(false)
      }
      const _closeKVKKVisible = () => {
        setVisible(false)
      }
      
      const _closeImageVisible = () => {
        setImageVisible(false)
      }
        
      const _closeImageVisibleMedia = () => {
        setImageVisibleMedia(false)
      }
      
      const _openImage = (url) => {
        setImageVisible(true)
        setImageLink(url)
      }
    
      const _openImageMedia = (url,type) => {
        setImageVisibleMedia(true)
        setImageLinkMedia(url)
        setImageLinkMediaType(type)
    
      }



  const _openKVKK = () => {
    setVisible(true)
  }

  const _openMedia = () => {
    setVisibleMedia(true)
  }
  
  const _openStand = () => {
    setVisibleStand(true)
  }
  

  const _closeCertifica = () => {
    setCertificaVisible(false)
  }

  const _openCertica = () => {
    setCertificaVisible(true)
  }

  const [ recipes , setRecipes ] = useState({
    recipesData:[],
    isLoading:true
  });


  
  const [ mailChange , setMailChange ] = useState(null)
  const [ Contact , setConcact ] = useState({
    Contact_Email:null,
                        Contact_Facebook:null,
                        Contact_Instagram:null,
                        Contact_Twitter:null,
                        Contact_Linkedin:null
  });

  const _sendSubsUser= async () => {
    try{
    var testDateUtc = Moment.utc(new Date());       
    var localDate = Moment(testDateUtc).local();

   let data = {
    Subscription_User_MailAdress: mailChange,
    Subscription_User_CreatedDateTime: localDate,
}
    axios.post('https://buharaapi.stechomeyazilim.info:5210/postSubs/insert', data).then(async(rsp) => {
        showToast('Buhara Baharat', "Başarıyla Sisteme Abone Oldunuz!", 'success')
        setMailChange(null)
    })
}
           catch (error) {
  
            console.log("_sendSubsUser" + error)
          }
           
  }

  const showToast= async (event, value, type) => {
    if(type == "false"){
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }else{
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  
  }
  const [ categoryData , setCategoryData ] = useState([]);

  useEffect(() => {
    async function _getRecieps() {
        try{    
        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipesCategory/select/`)
         .then(async(res) => {
             setRecipes({
                ...recipes,
                recipesData: res.data,
                isLoading:false
            })  

            await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getContact/select/`)
            .then(async(res) => {
            
                if(res.data.length> 0){
                    setConcact({
                        ...Contact,
                        Contact_Email:res.data[0].Contact_Email,
                        Contact_Facebook:res.data[0].Contact_Facebook,
                        Contact_Instagram:res.data[0].Contact_Instagram,
                        Contact_Twitter:res.data[0].Contact_Twitter,
                        Contact_Linkedin:res.data[0].Contact_Linkedin
                    })  
                  
                }
                
            })
            

             await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getProductCategory/select/`)
             .then((resCategory) => {
                 setCategoryData(resCategory.data)                    
                 })
    
             })
       }
       catch (error) {
         
         console.log("errorAS" + error)
       }
    }

    _getRecieps()
  }, [])
      
  const _onChangeMail= async (event) => {
    setMailChange(event.target.value)                  
  }
  
        return (
            <Fragment>
               
                <footer className="section-padding bg-light-theme  bg-[#0a4022] z-0">
                  {/* <Instagram /> */}
                    <div className="flex justify-center">
  

                            <div className="xxs:w-1/4 sm:w-1/4 md:w-1/5 lg:w-1/5">
                                <div className="footer-links text-center">
                                    <h6 className=" text-[20px] font-['Lato-Medium'] text-center text-[#fff]">KURUMSAL</h6>
                                    <ul >
                                    <li><Link to="/hakkimizda"  className="text-[#c5aa77]   font-[Lato-Medium] text-center">Kalite Belgelerimiz</Link>
                                        </li>

                                        <li><Link to="/hakkimizda"  className="text-[#c5aa77]   font-[Lato-Medium] text-center">Hakkımızda</Link>
                                        </li>
                                        
                                        <li><button  onClick={()=> _openKVKK()}><Link  className="text-[#c5aa77]   font-['Lato-Medium']">KVKK</Link>
                                        </button></li>

                                        <li><Link to="/uretim" className="text-[#c5aa77]   font-['Lato-Medium']">Üretim</Link>
                                        </li>
                                       
                                        <li><button onClick={()=> _openMedia()}><a  className="text-[#c5aa77]   font-['Lato-Medium']">Medya</a>
                                        </button></li>

                                      
                                    </ul>
                                </div>
                            </div>
                       
                            <div className="xxs:w-1/4 sm:w-1/4 md:w-1/5 lg:w-1/5">
                                <div className="footer-links text-center">
                                    <h6 className="text-center text-[20px] font-large text-[#fff]  font-['Lato-Medium']">Ürünler</h6>
                                    <ul>
                                    {categoryData.map((item, i) => (
                                                                 <li><Link  to={"/urunlerimiz"} state={{ ID: item.ID, _itemSub  :item }} className="text-[#c5aa77]   font-['Lato-Medium']">{item.Products_Category_Name}</Link>
                                                                 </li>
                                                            ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="xxs:w-1/4 sm:w-1/4 md:w-1/5 lg:w-1/5">
                                <div className="footer-links text-center">
                                    <h6 className="text-center text-[20px] font-large text-[#fff]  font-['Lato-Medium']">Tarifler</h6>
                                    <ul>
                                  
                                    
                                    {recipes.recipesData.map((item, i) => (
                                                                 <li><Link to="/yemek-tarifleri" state={{ ID: item.ID, _itemSub  :item }} className="text-[#c5aa77]    font-['Lato-Medium']">{item.Recipes_Category_Text}</Link>
                                                                 </li>
                                                            ))}

                                       

                                    </ul>
                                </div>
                            </div>

                           
                            <div className="xxs:w-1/4 sm:w-1/4 md:w-1/5 lg:w-1/5">
                                <div className="footer-links text-center">
                                    <h6 className="text-center text-[20px] font-large text-[#fff]  font-['Lato-Medium']">Kurumsal Kimlik</h6>
                                    <ul>
                                  
                                
                                        <li><Link to="/iletisim" className="text-start text-[#c5aa77]    font-['Lato-Medium']">İletişim</Link>
                                        </li>
                                        <li><Link to="/blog" className="text-start text-[#c5aa77]    font-['Lato-Medium']">Blog Yazıları</Link>
                                        </li>
                                        <li><Link to="/bayilerimiz" className="text-start text-[#c5aa77]    font-['Lato-Medium']">Bayilerimiz ve Satış Noktalarımız</Link>



                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                  


                    <div className="col-md-12 ">
                                <div className="grid lg:grid-cols-5 gap-4  md:grid-cols-4 grid-cols w-full">
                               <div className='col-span-3 '>
                                <h6 className="text-start text-[20px] font-large text-[#fff]  font-['Lato-Medium'] pl-2">Lezzetli haberler?</h6>
                                <h6 className="text-start  font-medium text-[#fff]  font-['Lato-Medium'] pl-2">Bültenimize abone olun ve her ay size gönderilen lezzetli tarif haberlerini alın. Kişisel bilgilerinizi KVKK’ya uygun olarak saklıyoruz. <a href="https://buhara.com/BuharaKVKK.pdf" target={"_blank"}><text className="font-[Lato-Medium-Medium]">KVKK politikasına buradan ulaşabilirsiniz</text> </a></h6>
                                </div>
                                    <form  className='col-span-1'>
                                            <input value={mailChange} onChange={(e)=> _onChangeMail(e)} type="text" className="form-control form-control-submit" name="email" placeholder="Mail Adresinizi Girin" />
                                          
                                    </form>

                                    <button onClick={()=> _sendSubsUser()} className="col-span-1 border-2 border-white justify-center rounded-lg flex items-center bg-[#FFC04B]">
                                            <FontAwesomeIcon  color='white' size="lg" icon={faPaperPlane} />
                                                <p className="m-1 text-center text-[20px] font-medium text-[#fff] cursor-pointer font-['Lato-Medium']">Gönder</p>
                                            </button>

                                </div>
                            </div>

                            
                            <div className="col-md-12">
                                <div className="ft-social-media">
                                    <h6 className="text-[20px] font-large text-[#fff] text-center m-1">Bizi Takip Edin</h6>
                                    <ul>
                                 
                        <li> <a  href={"mailto:" + Contact.Contact_Email}>
                        <FontAwesomeIcon className="m-2"  size="lg" icon={faMailBulk}/></a>
                                        </li>

                                        <li> <a target={"blank"} rel="noopener noreferrer" href={Contact.Contact_Facebook}><i className="fab fa-facebook-f" /></a>
                                        </li>
                                        <li> <a target={"blank"} rel="noopener noreferrer" href={Contact.Contact_Twitter}><i className="fab fa-twitter" /></a>
                                        </li>
                                        <li> <a target={"blank"} rel="noopener noreferrer" href={Contact.Contact_Instagram}><i className="fab fa-instagram" /></a>
                                        </li>
                                        <li> <a target={"blank"} rel="noopener noreferrer" href={Contact.Contact_Linkedin}><i className="fab fa-linkedin-in" /></a>
                                        </li>
                                     
                                      
                                    </ul>

                                
                                </div>

                             
                                
                            </div>

                         
                         
                </footer>
                <div className="copyright bg-[#0a4022]">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4">
                               
                            </div>
                            <div className="col-lg-4 text-center  align-self-center">
                                <Link to="http://www.stechome.com.tr" className="text-custom-white">Made with Stechome Software</Link>
                            </div>

                            <div className="col-lg-4 text-center flex space-x-4 align-self-center">
            <a href={googlePlayLink} target="_blank" rel="noopener noreferrer" className="bg-white rounded-full p-2 shadow-md">
                <FaGooglePlay size={32} className="text-green-600" />
            </a>
            <a href={appStoreLink} target="_blank" rel="noopener noreferrer" className="bg-white rounded-full p-2 shadow-md">
                <FaApple size={32} className="text-green-600" />
            </a>
        </div>

                        {/*    <div className="col-lg-4 flex text-center medewithlove align-self-center">
                                    <div className="appimg">
                                        <Link to="#">
                                            <img src={playstore} className="w-50" alt="" />
                                        </Link>
                                    </div>
                                    <div  className='divide-x-[3px]'>
                                        <Link to="#">
                                            <img src={appstore} className="w-50" alt="" />
                                        </Link>
                                    </div> 
                               
                            </div>*/}

                            <div className="col-lg-4">
                                <div className="copyright-text"> <span className="text-light-white">© <Link to="http://www.stechome.com.tr" className="text-light-white">Buhara Buharat</Link> - 2024 | Tüm Hakları Saklıdır</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LoadingSpinner show = {recipes.isLoading}/> 

      <ContentCertifica openKVKKVisible = {dataCertificaVisible} _closeKVKKVisible = {_closeCertifica}/>
      <ContentKVKK openKVKKVisible = {dataVisible} _closeKVKKVisible = {_closeKVKKVisible}/>
      <ContentMedia _openImageMedia={_openImageMedia} openMediaVisible = {dataVisibleMedia} _closeMediaVisible = {_closeMediaVisible}/>
      <ContentStand _openImage={_openImage} openVisibleStand = {dataVisibleStand} _closedataStandVisible = {_closedataStandVisible}/>

      <ContentImageMedia _imageLinkMediaType = {imageLinkMediaType} openVisibleImage={imageVisibleMedia} _imageLink = {imageLinkMedia} _closeImageVisible = {_closeImageVisibleMedia} _openImage={_openImage}/>
      <ContentImage openVisibleImage={imageVisible} _imageLink = {imageLink} _closeImageVisible = {_closeImageVisible} _openImage={_openImage}/>

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>

            </Fragment>
        )
}

export default Footer;