import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import StickyBox from "react-sticky-box";
import Testimonial from '../../layouts/Testimonial';
import { restaurantRatingStars } from '../../../helper/helper';
import Moment from 'moment';
import axios from 'axios';
import reviewfooter from '../../../assets/img/review-footer.png';


class Content extends Component {
    constructor() {
        super()
        this.state = {
          blogReview:[]
        }
    }
    async componentDidMount() {
        await this._getBlogReview()
    }

    _getBlogReview = async () => {
        try{
        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/blogReview/select/${this.props._item.ID}`)
         .then((res) => {
             this.setState({blogReview: res.data })   
            })
       }
       catch (error) {
         console.log("errorAS" + error)
       }
    }

    render() {
        return (
            <section className="our-articles bg-light-theme section-padding pt-0">
                <div className="blog-page-banner" />
                <div className="container-fluid">
                    <div className="row">
                        <aside className="col-lg-3">
                            <StickyBox className="sidebar2" offsetTop={0} offsetBottom={20}>
                                <div className="side-bar section-padding pb-0 mb-md-40">
                                  
                                </div>
                            </StickyBox>
                        </aside>
                        <div className="col-lg-6 blog-inner clearfix">
                            <div className="main-box padding-20 full-width">
                                <div className="breadcrumb-wrpr">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/" className="text-light-black">Anasayfa</Link>
                                        </li>
                                        <li className="breadcrumb-item"><Link to="/blog" className="text-light-black">Blog Yazıları</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Blog Detay</li>
                                    </ul>
                                </div>
                                <div className="post-wrapper mb-xl-20">
                                    <img src={'https://buharasubdomain.stechomeyazilim.info/' + this.props._item.Blog_Image} className="img-fluid full-width" alt="" />
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                    <h6 className="sm:text-[10px] md:text-[15px] lg:text-[16px] font-[Lato-Medium]">{Moment(this.props._item.Created_DateTime).fromNow()} Oluşturuldu</h6>

                                        <div className="blog-meta mb-xl-20">
                                            <h2 className="blog-title text-light-black font-[Lato-Bold] sm:text-[20px] md:text-[25px] lg:text-[35px] text-center">{this.props._item.Blog_Title}</h2>
                                            <p className="text-light-white font-['Lato-Medium'] text-center sm:text-[16px] md:text-[19px] lg:text-[23px]">{this.props._item.Blog_Desc}</p>
                                        </div>
                                      
                                      
                                       
                                 {/**  <div className="comment-box">
                                            <div className="section-header-left">
                                                <h3 className="text-light-black font-[Lato-Medium] sm:text-[20px] md:text-[25px] lg:text-[30px]">Yorumlar</h3>
                                            </div>
                                            {this.state.blogReview.length == 0 ?
                         <div className="col-12">
                            <div className="review-img">
                                <div className="review-text">
                                    <h2 className="text-light-white mb-2 fw-600">İlk yorum yapanlardan biri olun</h2>
                                    <p className="text-light-white">Buhara Baharat</p>
                                </div>
                            </div>
                        </div> : this.state.blogReview.map((item, i) => (
                                                <Fragment key={i}>
                                                    <div className="review-box">
                                                        <div className="review-user">
                                                            <div className="review-user-img">
                                                                <img src={item.userimg} className="rounded-circle" alt="#" />
                                                                <div className="reviewer-name">
                                                                    <p className="text-light-black fw-600">{item.username} <small className="text-light-white fw-500">{item.location}</small>
                                                                    </p> <i className="fas fa-trophy text-black" /><span className="text-light-black">{item.userpost}</span>
                                                                </div>
                                                            </div>
                                                            <div className="review-date"> <span className="text-light-white">{item.commentdate}</span>
                                                            </div>
                                                        </div>
                                                        <div className="ratings">
                                                            {restaurantRatingStars(item.rating)}
                                                        </div>
                                                        <p className="text-light-black">{item.comment}</p>
                                                    </div>
                                                    {item.children.map((review, i) => (
                                                        <div key={i} className="review-box comment-reply">
                                                            <div className="review-user">
                                                                <div className="review-user-img">
                                                                    <img src={review.userimg} className="rounded-circle" alt="#" />
                                                                    <div className="reviewer-name">
                                                                        <p className="text-light-black fw-600">{review.username} <small className="text-light-white fw-500">{review.location}</small>
                                                                        </p> <i className="fas fa-trophy text-black" /><span className="text-light-black">{review.userpost}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="review-date"> <span className="text-light-white">{review.commentdate}</span>
                                                                </div>
                                                            </div>
                                                            <div className="ratings">
                                                                {restaurantRatingStars(item.rating)}
                                                            </div>
                                                            <p className="text-light-black">{review.comment}</p>
                                                        </div>
                                                    ))}
                                                </Fragment>
                                            ))}
                                        </div>
                                        <div className="comment-form">
                                            <div className="section-header-left">
                                                <h3 className="text-light-black font-[Lato-Medium] sm:text-[20px] md:text-[25px] lg:text-[30px]">Yorum Yap</h3>
                                            </div>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="text-light-black fw-600">Adınız</label>
                                                            <input type="text" name="#" className="form-control form-control-submit" placeholder="Adınız" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="text-light-black fw-600">Mail Adresiniz</label>
                                                            <input type="email" name="#" className="form-control form-control-submit" placeholder="Mail Adresiniz" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="text-light-black fw-600">Yorumunuz</label>
                                                            <textarea className="form-control form-control-submit" name="#" rows={6} placeholder="Yorumunuz" defaultValue={""} />
                                                        </div>
                                                        <button type="submit" className="btn-second btn-submit full-width">Gönder</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>*/}      
                                    </div>
                                </div>
                            </div>
                        </div>
                        <aside className="col-lg-3">
                            <StickyBox className="sidebar3" offsetTop={0} offsetBottom={20}>
                                <div className="side-bar section-padding pb-0">
                                    <Testimonial />
                                   
                                  
                                </div>
                            </StickyBox>
                        </aside>
                    </div>
                </div>
            </section>
        );
    }
}

export default Content;