import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import StickyBox from "react-sticky-box";
import Blogsidebar from '../../layouts/Blogsidebar';
import Testimonial from '../../layouts/Testimonial';
import Moment from 'moment';
import 'moment/locale/tr';

import largeimg1 from '../../../assets/img/blog/ad-1.jpg';
import google from '../../../assets/img/logo2.png';

class Content extends Component {


    async componentDidMount() {
        Moment().locale('tr')
    }
    render() {
        return (
            <section className="our-articles bg-light-theme section-padding pt-0">
                <div className="blog-page-banner" />
                <div className="container-fluid">
                    <div className="row">
                        <aside className="col-lg-3">
                           
                        </aside>
                        <div className="col-lg-6 blog-inner clearfix">
                            <div className="main-box padding-20 full-width">
                                <div className="breadcrumb-wrpr">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/" className="text-light-black">Anasayfa</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Blog Yazıları</li>
                                    </ul>
                                </div>
                                <div className="row">
                                    {this.props._blogData.map((item, i) => (
                                        <div key={i} className="col-xl-6 col-lg-12 col-sm-6">
                                            <article className="blog-services-wrapper main-box mb-xl-20">
                                                <div className="post-img">
                                                    <Link  to={"/blog-detay/"  + (item.Blog_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}} >
                                                        <img src={'https://buharasubdomain.stechomeyazilim.info/' + item.Blog_Image} className="img-fluid full-width" alt="" />
                                                    </Link>
                                                </div>
                                                <div className="post-meta">
                                                    <div className="author-img">
                                                        <img src={google} className="h-12 w-12" alt="" />
                                                    </div>
                                                    <div className="author-meta">
                                                        <h6 className="no-margin"><Link to={"/blog-detay/"  + (item.Blog_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}} className="text-light-black font-[Lato-Medium]">Buhara Baharat</Link></h6>
                                                        <p className="no-margin text-light-white"><Link tto={"/blog-detay/"  + (item.Blog_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}} className="text-light-white">{Moment(item.Created_DateTime).format('DD/MM/YYYY')}</Link></p>
                                                    </div>
                                                </div>
                                                <div className="post-content padding-20">
                                                    <h5><Link  to={"/blog-detay/"  + (item.Blog_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}} className="text-light-black font-[Lato-Medium] text-center">{item.Blog_Title}</Link></h5>
                                                    <div className="blog-link-wrap"> <Link to={"/blog-detay/"  + (item.Blog_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}} className="btn-first white-btn font-[Lato-Medium]">Oku</Link>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <aside className="col-lg-3">
                           
                        </aside>
                    </div>
                </div>
            </section>
        );
    }
}

export default Content;