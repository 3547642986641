import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import axios from 'axios';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navmethod: false,
            productsData:[],
            selectCategory : ''
        };
    }
    // Navigation
   
    async componentDidMount() {

        console.log("msldkf",this.props._itemSub)
        this.setState({
            selectCategory:this.props._itemSub != null ? this.props._itemSub.ID : null
        })

        this.props._filterRecipe(this.props._itemSub != null ? this.props._itemSub.ID : null,'category')

    }

    _openCategory= async (ID,type) => {

        this.setState({
            selectCategory:ID
        })
        this.props._filterRecipe(ID,type)
    }


    render() {
        return (
            <div className="lg:grid grid-rows-1 md:p-1 lg:p-3 xl:p-4 grid-flow-col items-center">
                <Link   onClick={this.toggleNav}>
                    <span className='text-[white] font-[Lato-Medium] text-[10px] sm:text-[13px] md:text-[15px] lg:text-[22px]'>Kategoriler:</span>
                </Link>

                <button  className={this.state.selectCategory == '' ? 'bg-[#FFC04B] justify-center items-center p-3 rounded-lg' : 'justify-center items-center  p-3'}  onClick={()=> this._openCategory('', 'all')}>
                    <span className='text-[white] font-[Lato-Medium] text-[10px] sm:text-[13px] md:text-[15px] lg:text-[18px] p-2 text-left'>Tüm Tarifler</span>
                </button>

                {this.props._data.map((item, i) => (

                <button className={this.state.selectCategory == item.ID ? 'bg-[#FFC04B] justify-center items-center p-3 rounded-lg' : 'justify-center items-center  p-3'} onClick={()=> this._openCategory(item.ID , 'category')}>
                    <span className='text-[white] font-[Lato-Medium] text-[10px] sm:text-[13px] md:text-[15px] lg:text-[18px] p-2'>{item.Recipes_Category_Text}</span>
                </button>
                ))} 
                
            </div>
        );
    }
}

export default Menu;