import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faHeart, faShare } from '@fortawesome/free-solid-svg-icons'
import Moment from 'moment'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReactPlayer from 'react-player'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #000',
  boxShadow: 24,
};

class ContentImage extends Component {

  constructor(props) {
      super(props)
      this.state = {
          open: false,
          recipesData:null,
          certifica:[],
          imageVisible:false
      }
  }

 

render(){
  console.log("sdnlf",this.props._imageLink)
  return (
<Modal
  open={this.props.openVisibleImage}
  onClose={this.props._closeImageVisible}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box className='w-1/2' sx={style}>
<Carousel>

{this.props._imageLink.length> 0 ? this.props._imageLink.map((item, i) => (
  <div className='#0a4022'>
                    <img src={'https://buharasubdomain.stechomeyazilim.info/' + item.Certifica_Url} />
                    <p className="legend font-[Lato-Bold] text-[#0a4022] sm:text-[10px] md:text-[20px] lg:text-[30px]">Medyada</p>
                </div>
)) : null}
               
        
            </Carousel>
  </Box>
</Modal>
  )
}
};

export default ContentImage;
