function calculateRating(rating) {
    if (rating < 3) {
        return 'bg-red';
    } else if (rating > 3 && rating < 4) {
        return 'bg-yellow';
    } else {
        return 'bg-green';
    }
}


function processRatingStars(rating) {
    let stars = [];
    for (let i = 0; i < 5; i++) {
        stars.push(<i className="fas fa-star" key={i}></i>);
    }
    if (rating && rating > 0) {
        for (let i = 0; i <= rating - 1; i++) {
            stars[i] = <i className="fas fa-star text-yellow" key={i}></i>;
        }
    }
    return stars;
}

function recipesRatingStars(rating) {
    let stars = [];
    for (let i = 0; i < 5; i++) {
        stars.push(<span className="fs-16 text-[#0a4022] " key={i}><i className="fas fa-star"></i></span>);
    }
    if (rating && rating > 0) {
        for (let i = 0; i <= rating - 1; i++) {
            stars[i] = <span className=" text-[#FFC04B]  fs-16" key={i}><i className="fas fa-star"></i></span>;
        }
    }
    return stars;
}

function restaurantRatingStars(rating) {
    let stars = [];
    for (let i = 0; i < 5; i++) {
        stars.push(<span className="fs-16  text-[white]" key={i}><i className="fas fa-star"></i></span>);
    }
    if (rating && rating > 0) {
        for (let i = 0; i <= rating - 1; i++) {
            stars[i] = <span className=" text-[#0a4022] fs-16" key={i}><i className="fas fa-star"></i></span>;
        }
    }
    return stars;
}
function fetchFlavorIcon(flavor) {
    return 'assets/img/svg/' + flavor + '.svg';
}
function fetchFlavorIconRecipes(flavor) {
    return '../../assets/img/svg/' + flavor + '.svg';
}
export { recipesRatingStars,fetchFlavorIconRecipes,calculateRating, processRatingStars, fetchFlavorIcon, restaurantRatingStars };
