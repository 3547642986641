import React, { Fragment } from 'react';
import Menu from './Menu';
import Filter from './Filter';

import HeaderComponent from '../../../helper/Navigationhelper';
import FilterBy from './FilterBy';
import axios from 'axios';


class Header extends HeaderComponent {
    constructor(props) {
        super(props)
        this.state = {
            visibleFilter : true,
            productsData:[],
            productsSubData:[]
        }
    }



    
    async componentDidMount() {
        await this._getProductsCategory()
        await this._getProductsSubCategory()
    }

    _getProductsCategory = async () => {
        try{
          
       await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getProductCategory/select/`).then((res) => {
             this.setState({productsData: res.data },()=> {
                console.log("nsdjclf123",res.data)
             })   
                
            })
       }
       catch (error) {
         
         console.log("errorAS" + error)
       }
    
    }

    _getProductsSubCategory = async () => {
        try{
          
       await axios.get(`https://buharaapi.stechomeyazilim.info:5210/productSubCategory/select/`).then((res) => {
             this.setState({productsSubData: res.data },()=> {
                console.log("nsdjclf123",res.data)
             })   
                
            })
       }
       catch (error) {
         
         console.log("errorAS" + error)
       }
    
    }

    _openCategory= (ID,type) => {
        console.log("kb1fbjkdsf",this.props._productsData)
        this.props.filterCategory(ID,type)
    }

    _openNav = () => {
        this.setState({visibleFilter: !this.state.visibleFilter})
    }

    render() {
        return (
            <Fragment>
                <div className='bg-[#0a4022]' >
                <Menu _itemSub={this.props._itemSub} _subCategoryID={this.props._subCategoryID} _openCategory = {this._openCategory} productsData={this.state.productsData} />
                <div   className='bg-[white]'>
                <Filter productsData={this.state.productsData} _openNav ={this._openNav} />
                </div>

                {this.state.visibleFilter == true ?  
                <div className='bg-[white]'>
                <FilterBy _subCategoryID={this.props._subCategoryID} _filterSubCategory={this.props._filterSubCategory} productsSubData={this.state.productsSubData} productsData={this.state.productsData} _openNav ={this._openNav} />
                </div> : null}

                </div>
                
                <div className="main-sec" />
              
            </Fragment>
        );
    }
}

export default Header;