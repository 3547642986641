import React, { useEffect, useState,Fragment } from "react";
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import Logo from "../../assets/img/logobuhara.png"
import LogoWhite from "../../assets/img/logobuhara.png"
import IconImg1 from "../../assets/img/icon/contact/icon1.png"
import IconImg2 from "../../assets/img/icon/contact/icon2.png"
import IconImg3 from "../../assets/img/icon/contact/icon3.png"
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faAddressBook, faBusinessTime, faCircle, faClipboardList, faDotCircle, faHome, faListCheck, faMoon, faReceipt, faRss, faShop, faShoppingBag, faStar } from "@fortawesome/free-solid-svg-icons";

import ContentKVKK from "../../Sidebar/ContetnKVKK";
import ContentCertifica from "../../Sidebar/ContentCertifica";
import ContentMedia from "../../Sidebar/ContentMedia";
import ContentStand from "../../Sidebar/ContentStand";
import ContentImage from "../../Sidebar/ContentImage";
import ContentImageMedia from "../../Sidebar/ContentImageMedia";

const servicesUrl = "https://lastikbulans.stechomeyazilim.info/apiwebyol/RestController.php?view=getService";
const contactUrl = "https://lastikbulans.stechomeyazilim.info/apiwebyol/RestController.php?view=getContact";

const Header4 = () => {

	const [ dataVisible , setVisible ] = useState(false);
    const [ dataCertificaVisible , setCertificaVisible ] = useState(false);

	const [ data , setData ] = useState({
		ID:null,
       Adress_Text :  null,
							Email_Adress:null,
							Phone_Numer:null,
							Slogan_Home:null,
							Header_Text:null,
							Header_Icon: null,
							Main_Logo:null,
							Twitter_Adress: null,
							Instagram_Adress: null,
							Facebook_Adress: null,
	});

	

    const [ contactArray , setContact ] = useState(false);
	const [ openMenu , setOpenMenu ] = useState(false);
	const [ servicesArray , setService ] = useState(false);

    const [ dataVisibleMedia , setVisibleMedia ] = useState(false);
    const [ dataVisibleStand , setVisibleStand ] = useState(false);
  
    const [ imageVisible , setImageVisible ] = useState(false);
    const [ imageLink , setImageLink ] = useState('');
    const [ imageVisibleMedia , setImageVisibleMedia ] = useState(false);
    const [ imageLinkMedia , setImageLinkMedia ] = useState(false);
    const [ imageLinkMediaType , setImageLinkMediaType ] = useState('');

	
	const _close = () => {

		var btn = document.querySelector('.menuicon');
        var nav = document.querySelector('.menu-links');
		btn.classList.toggle("open");
		nav.classList.toggle("show");
      }

    const _closedataStandVisible = () => {

        setVisibleStand(false)
        setImageVisible(false)
      }
    
      const _closeMediaVisible = () => {

        setVisibleMedia(false)
      }
      const _closeKVKKVisible = () => {

        setVisible(false)
      }
      
      const _closeImageVisible = () => {

        setImageVisible(false)
      }
        
      const _closeImageVisibleMedia = () => {

        setImageVisibleMedia(false)
      }
      
      const _openImage = (url) => {

        setImageVisible(true)
        setImageLink(url)
      }
    
      const _openImageMedia = (url,type) => {

        setImageVisibleMedia(true)
        setImageLinkMedia(url)
        setImageLinkMediaType(type)
    
      }

	  const [ Contact , setConcact ] = useState({
		Contact_Email:null,
							Contact_Facebook:null,
							Contact_Instagram:null,
							Contact_Twitter:null,
							Contact_Linkedin:null
	  });

  const _closeCertifica = () => {
    setCertificaVisible(false)
  }

  const _openCertica = () => {
	_close()
    setCertificaVisible(true)
  }


	  useEffect(() => {

		async function getContact () {
				try {      
		
			await axios.get(contactUrl)
			  .then(res => {
					res.data.cikti.map((item) => {
						setData({
							...data,
							ID:item.ID,
							Adress_Text :  item.Adress_Text,
							Email_Adress:item.Email_Adress,
							Phone_Numer:item.Phone_Numer,
							Slogan_Home:item.Slogan_Home,
							Header_Text:item.Header_Text,
							Header_Icon: item.Header_Icon,
							Main_Logo: item.Main_Logo,
							Twitter_Adress: item.Twitter_Adress,
							Instagram_Adress: item.Instagram_Adress,
							Facebook_Adress: item.Facebook_Adress,
						})
	
					  })
			  })

			  await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getContact/select/`)
			  .then(async(res) => {
			  
				  if(res.data.length> 0){
					  setConcact({
						  ...Contact,
						  Contact_Email:res.data[0].Contact_Email,
						  Contact_Facebook:res.data[0].Contact_Facebook,
						  Contact_Instagram:res.data[0].Contact_Instagram,
						  Contact_Twitter:res.data[0].Contact_Twitter,
						  Contact_Linkedin:res.data[0].Contact_Linkedin
					  })  
					
				  }
				  
			  })
			  

			  
				} catch (error) {
				  console.log("error" + error)
				}
			  }

			  async function getSidebar () {
				try {
				  axios.get(servicesUrl).then((res) => {
					setService(res.data.cikti)
				  });
				} catch (error) {
				  console.log("error" + error);
				}
			  };

		getContact();
		getSidebar();
		
	
		// Contact Sidebar
        var asideBtn = document.getElementById("aside-btnshow")
        var contactSidebar = document.querySelector(".contact-sidebar")
        var sidebtnShow = document.getElementById("contact-sidebtnshow")

        asideBtn.addEventListener('click',function(){
            contactSidebar.style.display = "block"
            contactSidebar.classList.add("open")
        })

        sidebtnShow.addEventListener('click',function(){
            contactSidebar.style.display = "none"
            contactSidebar.classList.remove("open")
        })

        // sidebar open/close

        var btn = document.querySelector('.menuicon');
        var nav = document.querySelector('.menu-links');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
            console.log("lkjlkjl")

        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');
           
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }

	}, [])

		return(
			<>
				
				{/* <!-- Header Top ==== --> */}
				<header className="rs-nav style-3 box header">
					
					
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container clearfix">
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo d-lg-none d-table">
									<Link to="/"><img src={LogoWhite} className="w-full" alt=""/></Link>
								</div>
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> */}
								<div className="secondary-menu">
									<div className="secondary-inner">
										<ul>
											
											<li className="search-box">
												<form action="#">
												</form>
											</li>
											<li className="aside-box d-none d-lg-block">
												<Link id="aside-btnshow" className="aside-btn contact-sidebtn" to="#">
													<span></span>
													<span></span>
													<span></span>
												</Link>
											</li>
										</ul>
									</div>
								</div>
								{/* <!-- Navigation Menu ==== --> */}
                               	<div className="menu-links navbar-collapse  justify-content-start" id="menuDropdown">
									<div className="menu-logo">
										<Link to="/"><img src={Logo} alt=""/></Link>
									</div>
									<ul className="nav navbar-nav">	
										<li className="active"><button onClick={()=> _close()}><Link to="/"><FontAwesomeIcon  color='#0a4022' size="lg" icon={faHome}/><a className='font-[Lato-Bold] ml-2'>Anasayfa</a></Link></button>
											{/* <ul className="sub-menu">
												<li><Link to="/"><span>Home - Default</span> </Link></li>
												<li><Link to="index-2"><span>Home - Energy & Power</span></Link></li>
												<li><Link to="index-3"><span>Home - Construction</span></Link></li>
												<li><Link to="index-4"><span>Home - Agricultural</span></Link></li>
											</ul> */}
										</li>
                                        
										<li className="has-mega-menu "><button onClick={()=> _close()}><Link to="/yemek-tarifleri"><FontAwesomeIcon  color='#0a4022' size="lg" icon={faReceipt}/><a className='font-[Lato-Bold] ml-2'>Yemek Tarifleri</a></Link></button></li>

										<li className="has-mega-menu "><button onClick={()=> _close()}><Link to="/favori-tariflerim"><FontAwesomeIcon  color='#0a4022' size="lg" icon={faStar}/><a className='font-[Lato-Bold] ml-2'>Tariflerim</a></Link></button></li>

                                        <li className="has-mega-menu "><button onClick={()=> _close()}><Link  to="/alisveris-listesi"><FontAwesomeIcon  color='#0a4022' size="lg" icon={faShoppingBag}/><a className='font-[Lato-Bold] ml-2'>Alışveriş Listesi</a></Link></button></li>

                                        <li className="has-mega-menu "><button onClick={()=> _close()}><Link to="/urunlerimiz"><FontAwesomeIcon  color='#0a4022' size="lg" icon={faClipboardList}/><a className='font-[Lato-Bold] ml-2'>Ürünler</a></Link></button></li>

										<li className="has-mega-menu "><button onClick={()=> _close()}><Link to="/bayilerimiz"><FontAwesomeIcon  color='#0a4022' size="lg" icon={faShop}/><a className='font-[Lato-Bold] ml-2 text-left'>Bayilerimiz</a></Link></button></li>
											
										<li className="has-mega-menu "><button onClick={()=> _close()}><Link to="/iletisim"><FontAwesomeIcon  color='#0a4022' size="lg" icon={faAddressBook}/><a className='font-[Lato-Bold] ml-2'>İletişim</a></Link></button></li>
	
											<li className="has-mega-menu "><button onClick={()=> _close()}><Link to="/blog"><FontAwesomeIcon  color='#0a4022' size="lg" icon={faRss}/><a className='font-[Lato-Bold] ml-2'>Blog </a></Link></button>
												
											</li>

										<li><Link to="#"><FontAwesomeIcon  color='#0a4022' size="lg" icon={faBusinessTime}/><a className='font-[Lato-Bold] ml-2'>Kurumsal</a><i className="fa fa-chevron-down"></i></Link>
													<ul>
														
													<button onClick={()=> _close()} ><Link to="/hakkimizda" className='font-[PoppinsReg] ml-2 text-sm'>Hakkımızda</Link></button>
													<button onClick={()=> _close()} ><Link to="/hakkimizda" className='font-[PoppinsReg] ml-2 text-sm'>Kalite Belgelerimiz</Link></button>
													<button onClick={()=> _close()} ><Link to="/hakkimizda" className='font-[PoppinsReg] ml-2 text-sm'>KVKK</Link></button>
													<button onClick={()=> _close()} ><Link to="/hakkimizda" className='font-[PoppinsReg] ml-2 text-sm'>Üretim</Link></button>
													<button onClick={()=> _close()} ><Link to="/hakkimizda" className='font-[PoppinsReg] ml-2 text-sm'>Medya</Link></button>

													</ul>
												
										
										</li>
								
										
										
									</ul>
									<div className="nav-social-link flex">
									 <a target={"blank"} rel="noopener noreferrer" href={Contact.Contact_Facebook}><i className="fab fa-facebook-f" /></a>
                                        
                                      <a target={"blank"} rel="noopener noreferrer" href={Contact.Contact_Twitter}><i className="fab fa-twitter" /></a>
                                      
                                        <a target={"blank"} rel="noopener noreferrer" href={Contact.Contact_Instagram}><i className="fab fa-instagram" /></a>
                                      
                                        <a target={"blank"} rel="noopener noreferrer" href={Contact.Contact_Linkedin}><i className="fab fa-linkedin-in" /></a>
                                       
									</div>
								</div> 
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
				</header>
				{/* <!-- Header Top END ==== --> */}
				<div className="contact-sidebar content-scroll">
					<div className="inner">
						<div className="logo">
							<img src={Logo} alt="Lastikcigelsin"/>
						</div>
						<h4 className="title">Yol Yardım Desteği İçin Bize Ulaşın</h4>
						<ul className="contact-infolist">
							<li>
								<img src={IconImg1} alt="numara"/>
								<h6 className="contact-title">Numaralarımız</h6>
								<a href={"tel:05518378638"}><p>05518378638</p></a>
							</li>
							<li>
								<img src={IconImg2} alt="email"/>
								<h6 className="contact-title">Email Adresimiz</h6>
								<a href={"mailto:"+data.Email_Adress}>{data.Email_Adress}</a><br/>
							</li>
							<li>
								<img src={IconImg3} alt="addres"/>
								<h6 className="contact-title">Adresimiz</h6>
								<p>{data.Adress_Text}</p>
							</li>
						</ul>
						<h4 className="title">Bize Ulaşın</h4>
						<form className="contact-bx ajax-form" action="script/contact.php">
							<div className="ajax-message"></div>
							<div className="row placeani">
								<div className="col-lg-12">
									<div className="form-group">
										<div className="input-group">
											<label>İsminiz</label>
											<input name="name" type="text" required className="form-control"/>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group">
										<div className="input-group"> 
											<label>E-mail Adresiniz</label>
											<input name="email" type="email" className="form-control" required />
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group">
										<div className="input-group">
											<label>Mesajınız</label>
											<textarea name="message" rows="4" className="form-control" required ></textarea>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<button name="submit" type="submit" value="Submit" className="btn button-md">Gönder</button>
								</div>
							</div>
						</form>
					</div>
				</div>

				<ContentCertifica openKVKKVisible = {dataCertificaVisible} _closeKVKKVisible = {_closeCertifica}/>
      <ContentKVKK openKVKKVisible = {dataVisible} _closeKVKKVisible = {_closeKVKKVisible}/>
      <ContentMedia _openImageMedia={_openImageMedia} openMediaVisible = {dataVisibleMedia} _closeMediaVisible = {_closeMediaVisible}/>
      <ContentStand _openImage={_openImage} openVisibleStand = {dataVisibleStand} _closedataStandVisible = {_closedataStandVisible}/>

      <ContentImageMedia _imageLinkMediaType = {imageLinkMediaType} openVisibleImage={imageVisibleMedia} _imageLink = {imageLinkMedia} _closeImageVisible = {_closeImageVisibleMedia} _openImage={_openImage}/>
      <ContentImage openVisibleImage={imageVisible} _imageLink = {imageLink} _closeImageVisible = {_closeImageVisible} _openImage={_openImage}/>


				<div id="contact-sidebtnshow" className="contact-sidebtn close">
					<i className="ti-close"></i>
				</div>
			</>
		)
}

export default Header4;