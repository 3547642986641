import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #000',
  boxShadow: 24,
};


class HowToPrepare extends Component {
  constructor(props) {
      super(props)
      this.state = {
          open: false,
          recipesData:null,
          recipesDataShoppingList:[]
      }
  }


render(){
  return (
<Modal
  open={this.props.openShareModalVisible}
  onClose={this.props._closeShare}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box className='w-1/2' sx={style}>
    <div className="bg-[#fff] p-3" >
      <p className="text-center text-[30px]  font-['Lato-Bold']  text-[#1b2542]">PAYLAŞ</p>
    </div>
    <div className="bg-[#ebe5df] w-full p-5" >
    <ul className='grid lg:grid-cols-5 lg:gap-4 md:gap-3 sm:gap-1 xxs:gap-1  md:grid-cols-4 sm:grid-cols-3 xxs:grid-cols-2 justify-center'>
                                        <li className=' bg-[white] rounded-md justify-center flex'> <Link className=' items-center justify-center'><i className="fab fa-facebook-f" /></Link>
                                        </li>
                                        <li className='bg-[white] rounded-md justify-center flex'> <Link className='' ><i className="fab fa-twitter" /></Link>
                                        </li>
                                        <li className='bg-[white] rounded-md justify-center flex'> <Link className=' ' ><i className="fab fa-instagram" /></Link>
                                        </li>
                                        <li className='bg-[white] rounded-md w-full justify-center flex'> <Link className='' ><i className="fab fa-linkedin-in" /></Link>
                                        </li>
                                     
                                      
                                    </ul>
    </div>
  </Box>
</Modal>
  )
}
};

export default HowToPrepare;
