import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Content from '../sections/listviewfavorite/Content';
import Sidebar from "../../Sidebar";
import styled from "styled-components";



class ListviewFavorite extends Component {
    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>Buhara Baharat | Favori Tariflerim</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
              
             

                <Content/>
              
                <Footer/>

             

            </Fragment>
        );
    }
}

export default ListviewFavorite;