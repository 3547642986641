
import React from "react";
import { Collapse } from "@kunukn/react-collapse";
import Down from "./down";
import "./styles.scss";
import base64 from 'react-native-base64';
import ReactHtml from 'raw-html-react';


function Block({ isOpen, title, onToggle, children }) {
  console.log("isOpen4123",isOpen)
  return (
    <div className="block">
      <button className={"btn toggle bg-[#FFC04B] flex items-center"} onClick={onToggle}>
        <span className={"font-[Lato-Medium] hover:text-[white] text-[18px] text-center"}>{title}</span>
        <Down isOpen={isOpen} />
      </button>
      <Collapse layoutEffect className = "hover:text-[#FFC04B]" isOpen={isOpen}>
        {children}
      </Collapse>
    </div>
  );
}
const Product = (props) => {
  const initialState = [true,false,false,false,false];

  const [state, dispatch] = React.useReducer(reducer, initialState);

  function reducer(state, { type, index }) {
    switch (type) {
  
      case "toggle":
        state[index] = !state[index];
        return [...state];
  
      default:
        throw new Error();
    }
  }


  return (
    <div className="flex justify-center items-center py-10 bg-[#CFD1CC] z-10">
      <div className=" lg:w-5/6 h-full w-full py-1  flex lg:flex-row flex-col  bg-[white] justify-center items-center">

      <div className="col-md-5 m-2   rounded-md  ">
      <img src={'https://buharasubdomain.stechomeyazilim.info//' + props._item.Products_Image } alt={props._item.Products_Image} className="object-fill border-2 border-[red] rounded-lg" />
                        </div>


        <div className="rounded-md bg-[#0a4022] lg:px-16 px-3 py-10 flex flex-col lg:w-[600px] w-full flex-wrap z-10">
          <div>
            <h1 className="lg:text-[55px] md:text-[50px] sm:text-[45px] text-center text-[white] ">
              {props._item.Products_Name}
            </h1>
          </div>
          <div className="pt-2 pb-2">
           
          {props._item.Products_Desc != null ?     <Block
        title="Ürün açıklaması"
        isOpen={state[0]}
        onToggle={() => dispatch({ type: "toggle", index: 0 })}
      >
        <div className="bg-[white]">
          <p className="font-[Lato-Medium]  text-[#0a4022] p-2"> {props._item.Products_Desc}</p>
    
        </div>
      </Block> : null }

      {props._item.Products_Nutritional_Values != null ? <Block
        title="Ürün Besin Değerleri"
        isOpen={state[1]}
        onToggle={() => dispatch({ type: "toggle", index: 1 })}>
        <div className="bg-[white] w-full  p-2">
            <p className="head-px2"><ReactHtml html={base64.decode(base64.decode(props._item.Products_Nutritional_Values))}/></p>
            
            </div>

      </Block> : null}

      {props._item.Products_Prepared != null ? 
      <Block
        title="Nasıl Hazırlanır?"
        isOpen={state[2]}
        onToggle={() => dispatch({ type: "toggle", index: 2 })}>
        <div className="bg-[white] w-full  p-2">
            <p className="head-px2"><ReactHtml html={base64.decode(base64.decode(props._item.Products_Prepared))}/></p>
            
            </div>

      </Block>: null}

      {props._item.Products_Contents != null ? 

      <Block
        title="İçindekiler:"
        isOpen={state[3]}
        onToggle={() => dispatch({ type: "toggle", index: 3 })}>
        <div className="bg-[white] w-full  p-2">
            <p className="head-px2"><ReactHtml html={base64.decode(base64.decode(props._item.Products_Contents))}/></p>
        </div>

      </Block> : null}
          </div>
         

         {/**  <Block
        title="Buradan Satın Alabilirsiniz:"
        isOpen={state[4]}
        onToggle={() => dispatch({ type: "toggle", index: 4 })}>
         <div className="grid lg:grid-cols-3  md:grid-cols-2 grid-cols w-full z-10">
          {props._item.Products_Link.map((item, i) => (
           
           <button href={item.Products_Link_Address} className="bg-[white]  py-3 mt-[10px] justify-center  grid hover:bg-[#FFC04B] ml-1 rounded-lg ">
            <a target="_blank" href={item.Products_Link_Address} className=" text-[#0a4022] uppercase text-center text-[20px]  hover:text-[white] font-[Title]">
              {item.Products_Link_Header}
            </a>

             <div className="w-full items-center justify-center flex"> 
             <img src={'https://buharasubdomain.stechomeyazilim.info/' + item.Products_Link_Logo } alt="" className="w-full h-12 items-center justify-center" />
             </div>
             </button>
        
          ))}

</div>         
      </Block>*/}

   
      
         
      
         
        
        </div>
      </div>
    </div>
  );
};

export default Product;
