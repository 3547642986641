import React, { Component, Fragment } from 'react';
import Chefspecial from './Chefspecial';
import Collectionbox from './Collectionbox';
import Banner from './Banner2';
import Delivery from './Delivery';
import Nearfood from './Nearfood';
import Specialized from './Specialized';
import SearchBar from './SearchBar';

class Content extends Component {
    render() {
        return (
            <Fragment> 
                <Banner/>
                <SearchBar/>

                <Collectionbox/>
                <Nearfood/>

                <Delivery/>

               {/*  <Specialized/>*/}
               {/*  <Chefspecial/> */}
            </Fragment>
        );
    }
}

export default Content;