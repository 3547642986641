import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { processRatingStars } from '../../../helper/helper';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from "../../../LoadingSpinner";
import { fetchFlavorIconRecipes } from '../../../helper/helper';

// install Swiper components


class Content extends Component {
    constructor(props) {
        super(props)
        this.state = {
            recipesData:[],
            isLoading:true,
            resReview:[]
        }
    }
    async componentDidMount() {
        await this._getRecipes()
        window.scrollTo(0, 0);

    }

    _deleteFavori= async (ID) => {
        try{

            await axios.delete(`https://buharaapi.stechomeyazilim.info:5210/recipes/delete/${ID}`)
            .then(async(res) => {
                await this._getRecipes()
               })
        
       }
       catch (error) {
         console.log("errordeleteFavori" + error)
       }  
    }


    _getRecipes = async () => {
        try{

            const response = await fetch('https://api.ipify.org?format=json');
            const dataIP = await response.json();

            
            await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipes/favorite/${dataIP.ip}`)            
            .then(async(res) => {
                this.setState({recipesData: res.data,isLoading:false })  
                res.data.map(async(item, i) => (
                 {/**  await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipes/review/${item.Recipes_ID}`)
                    .then((resReview) => {
                        this.setState(prevState => ({
                            resReview: [...prevState.resReview, {ID : item.ID,reviewValue : resReview.data.value}]
                          }),()=>{
                            console.log("ljkn121jkl3", this.state.resReview)  

                          })
                         console.log("ljkn12jkl3", resReview.data)  
                       })*/}  
                ))
               })
       }
       catch (error) {
        this.setState({isLoading:false })  

         console.log("errorAS" + error)
       }
    
    
    }



    render() {
        return (
            <div className="most-popular section-padding z-10">                               
                         
<div className="justify-center items-center py-10">
          <p className="text-center text-[30px]  font-[Lato-Bold]  text-[#1b2542]">FAVORİ TARİFLERİM</p>
          </div>

          <div className="justify-center items-center py-1">
          <p className="text-center text-[14px] font-[Lato-Medium] text-[#1b2542]">Favori olarak işaretlediğiniz tarifleri burada tarif sayfasındaki kalbe tıklayarak bulabilirsiniz. Tarifleri kendinize veya bir arkadaşınıza kolayca e-posta ile gönderebilirsiniz.</p>
          </div>
              
          { this.state.recipesData.length == 0 ? 
           <div className=" justify-center items-center py-5 ">
           <p className='text-center text-[18px] font-medium'>Favori Tarifiniz Bulunmamaktadır!</p>
            </div> :
           <div className="grid grid-cols-4 gap-4  justify-center items-center py-1 ">

             {this.state.recipesData.map((item, i) =>
                                            {
                                               let reviewFilter = this.state.resReview.filter((x) => x.ID == item.Recipes_ID)
 
                                                return(
                                                    <div key={i}  className="  h-full w-full  rounded-lg bg-white m-3 relative">
                                                    <div className="product-list-info">
                                                <div className="product-list-img">
                                                    <Link  to={ "/yemek-tarifi/" + (item.Recipes.Recipes_Title).replace('?', '').replace(/\s+/g, '-')} state={{ ID: item.Recipes.ID, _item:item.Recipes, _recipesReview : reviewFilter.length > 0 ? reviewFilter : 0 }}>
                                                        <img  src={'https://buharasubdomain.stechomeyazilim.info/' +  item.Recipes.Recipes_Image}  className="img-fluid" alt="#" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <button onClick={()=> this._deleteFavori(item.ID)} className="relative md:absolute bg-white border rounded-full -top-3 -right-3"> 
                                             <FontAwesomeIcon className="m-2"  color='black' size="lg" icon={faTrash}/>
                                             </button>
                                           
                                            <div className="product-right-col">
                                                <div className="product-list-details">
                                                    <div className="product-list-title">
                                                        <div className="product-info">
                                                            <h6><Link to={ "/yemek-tarifi/" + (item.Recipes.Recipes_Title).replace('?', '').replace(/\s+/g, '-')} state={{ ID: item.Recipes.ID, _item:item.Recipes , _recipesReview : reviewFilter.length > 0 ? reviewFilter : 0 }} className="text-light-black fw-600 font-[Lato-Medium]">{item.Recipes.Recipes_Title}</Link></h6>
                                                            <p className="text-light-white fs-12">
                                                                <span key={i}>{item.Recipes.Recipes_Category.Recipes_Category_Text}</span>
                                                            </p>

                                                            <ul className="grid lg:grid-cols-5 lg:gap-4 md:3 sm:1 md:grid-cols-4 sm:grid-cols-3 xxs:grid-cols-3 justify-center">{item.Recipes.Recipes_Icon.map((flavor, i) => (
                                                                <span key={i} className="m-1 rounded-lg border-2 text-custom-white square-tag ">
                                                                    <img src={fetchFlavorIconRecipes(flavor.Recipes_Icon_Text)} alt={flavor.Recipes_Icon_Text} />
                                                                </span>
                                                            ))}
                            </ul>
                                                        </div>
                                                    </div>
                                                    {reviewFilter.length > 0 ? <div className="product-detail-right-box">
                                                        <div className="grid text-center">
                                                            <div className="ratings">
                                                                {processRatingStars(reviewFilter[0].reviewValue)}
                                                            </div>
                                                            
                                                            <div className="rating-text">
                                                                <p className="text-light-white fs-12">{item.Recipes.Recipes_Rewies.length} Değerlendirme</p>
                                                                
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                       { i % 2 == 0 ?  <div className="product-list-tags">
                                                          
                                                          <span className="rectangle-tag bg-[#FFC04B] text-custom-white">Trend Tarif</span>
                                                         
                                                        </div> : null}
                                                       
                                                    </div> : null}
                                                </div>
                                                   
                                                  
                                            </div>
                                        </div>
                                    )})}
                                </div>}
                               
                                <LoadingSpinner show = {this.state.isLoading}/> 
  
                </div>
        );
    }
}

export default Content;