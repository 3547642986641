import React, { Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Footer from '../layouts/Footer';
import Content from '../sections/listview/Content';

import { useLocation } from "react-router-dom";


const Listview = () => {
    const location = useLocation();
    const state = location.state;

   
    console.log("lmadfl",state)
        return (
            <Fragment>
                <MetaTags>
                    <title>Buhara Baharat | Tarifler</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>

                <Content _itemSub={state != null ? state._itemSub : null} _ID={state != null ? state.ID : null} _item={state != null ? state._item : null}/>
              
                <Footer/>

            </Fragment>
        );
    }


export default Listview;