
import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Content from '../sections/productdetail/Content';
import { useLocation, Link } from "react-router-dom";


const ProductDetailNew = () => {
        const location = useLocation();
        const state = location.state;

        return (
            <Fragment>
                <MetaTags>
                    <title>Buhara Baharat | Ürün Detay</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
               {/* <Header/> */}
                <Content _item={state._item}/>
                <Footer/>
            </Fragment>
        );
   
}

export default ProductDetailNew;
