import React, { Fragment } from 'react';
import Contacthelper from '../../../helper/Contacthelper';
import ReCAPTCHA from "react-google-recaptcha";
import { Alert } from 'react-bootstrap';
import axios from 'axios';

class Content extends Contacthelper {
    constructor() {
        super();
       this.state = { 
        contactDetail:{}
    }
}

recaptchaRef = React.createRef(null);


   async componentDidMount() {
       await this._getContact()
   }

   _getContact = async () => {
    try{

    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getContact/select/`)
     .then(res => {
         this.setState({contactDetail: res.data[0]})                    
         })
   }
   catch (error) {
     
     console.log("_getContact" + error)
   }
}

    
_handleSubmit = async (e) => {
    e.preventDefault();

    const captchaToken = await this.recaptchaRef.current.executeAsync();
    this.recaptchaRef.current.reset();
    
    console.log("captchaToken123",captchaToken)
    const res = await axios.post(
        `https://www.google.com/recaptcha/api/siteverify?secret=${"6LcyxIYkAAAAABj3DAD7U33_m6Ui1QfLvNF7B-o2"}&response=${captchaToken}`
      );
      
      console.log("captchaT12oken123",res.data)

      // Extract result from the API response
      if(res.data.success){
        console.log('Human');
      }
       
      else{
        console.log('BOT!!!');

      }

  }

    render() {
        return (
            <Fragment>
                <div className="contact_map">
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3147.5287839317607!2d32.5545263153334!3d37.91807547973371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d08fde0028003f%3A0x9a34903fadff627e!2sBuhara%20Baharat!5e0!3m2!1str!2str!4v1670849603678!5m2!1str!2str" className="border-0 w-100" style={{ height: '100%', minHeight: '500px' }} allowFullScreen />
                </div>
                <section className="section-padding">
                    <div className="container">
                        <div className="section-header-style-2 ">
                            <h6 className="text-light-green text-[30px]  font-[PoppinsSemiBold] text-[#c5aa77]">İletişim Formu</h6>
                            <h3 className="text-light-black header-title">Buhara Baharat</h3>
                        </div>
                        <div className="row">
                        <div className="col-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="grid bg-[#0a4022]  rounded-lg">
                                                <div className='bg-[#c5aa77] w-full  text-center rounded-tl-md rounded-tr-md'> 
                                                <label className="font-[PoppinsBold] sm:text-[8px] md:text-[20px] lg:text-[20px] text-white p-2 text-center">MAİL ADRESİ</label>
                                                </div>
                                                <label className="font-[PoppinsReg] sm:text-[8px] md:text-[15px] lg:text-[18px] text-white p-2 text-center">{this.state.contactDetail.Contact_Email}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                        <div className="grid bg-[#0a4022] rounded-lg">
                                        <div className='bg-[#c5aa77] w-full  text-center rounded-tl-md rounded-tr-md'> 
                                                <label className="font-[PoppinsBold] sm:text-[8px] md:text-[20px] lg:text-[20px] text-white p-2 text-center">İLETİŞİM NUMARASI</label>
                                                </div>
                                                <label className="font-[PoppinsReg] sm:text-[8px] md:text-[15px] lg:text-[18px] text-white p-2 text-center">{this.state.contactDetail.Contact_Telephone_Number}</label>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-4">
                                        <div className="grid bg-[#0a4022] items-center justify-center rounded-lg">
                                        <div className='bg-[#c5aa77] w-full  text-center rounded-tl-md rounded-tr-md'> 
                                                <label className="font-[PoppinsBold] sm:text-[8px] md:text-[20px] lg:text-[20px] text-white p-2 text-center">ADRESİMİZ</label>
                                                </div>
                                                <label className="font-[PoppinsReg] sm:text-[8px] md:text-[15px] lg:text-[18px] text-white p-2 text-center">{this.state.contactDetail.Contact_Adress}</label>
                                            </div>
                                        </div>
                                      
                                    </div>
                            </div>

                            <div className="col-12 mt-1">
                                <form onSubmit={this.handleSubmit} >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="text-light-black fw-600">Adınız</label>
                                                <input type="text" name="name" value={this.state.name} onChange={this.onNameChange} className="form-control form-control-submit" placeholder="Adınız" required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="text-light-black fw-600">Mail Adresiniz</label>
                                                <input type="email" name="email" value={this.state.email} onChange={this.onEmailChange} className="form-control form-control-submit" placeholder="Mail Adresiniz" required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="text-light-black fw-600">Telefon Numarası</label>
                                                <input type="text" name="phone" value={this.state.phone} onChange={this.onPhoneChange} className="form-control form-control-submit" placeholder="Telefon Numaranız" required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="text-light-black fw-600">Konu</label>
                                                <input type="text" name="subject" value={this.state.subject} onChange={this.onSubjectChange} className="form-control form-control-submit" placeholder="Konu" required />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="text-light-black fw-600">Mesaj</label>
                                                <textarea className="form-control form-control-submit" name="message" value={this.state.message} onChange={this.onMessageChange} rows={6} placeholder="Mesajınızı Yazınız" required />
                                            </div>
{  /*                                          <ReCAPTCHA
          ref={this.recaptchaRef}
          sitekey={"6LcyxIYkAAAAAIAspnIGJU8Xh3hB4dQkGKb_2ytH"}
          size="invisible"
        />  */}
                                        
                                            <button type="submit" className="btn-second btn-submit full-width bg-[#0a4022] text-[white] m-1 hover:text-[red]">Gönder</button>
                                            {/* Form Messages */}
                                            <Alert variant="success" className="d-none mt-3 mb-0" id="server_response_success">
                                                <strong>Başarılı!</strong> İletişim formu başarıyla gönderildi.
                                            </Alert>
                                            <Alert variant="danger" className="d-none mt-3 mb-0" id="server_response_danger">
                                                <strong>Hata!</strong> Kötü bir şey oldu. Lütfen daha sonra tekrar deneyiniz.
                                            </Alert>
                                            {/* Form Messages */}
                                        </div>
                                    </div>
                                </form>
                            </div>

                          
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

export default Content;