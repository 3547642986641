import React, { Component, Fragment } from 'react';
import Exclusivedeal from './Exclusivedeal';
import Freshdeals from './Freshdeals';
import Localdeals from './Localdeals';
import Partners from './Partners';
import Trending from './Trending';

class Content extends Component {
    render() {
        return (
            <Fragment>
              
            <Localdeals _itemSub={this.props._itemSub} _ID={this.props._ID} _item = {this.props._item}/>
            </Fragment>
        );
    }
}

export default Content;