import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import axios from 'axios';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navmethod: false,
            catID : null
        };
    }
    // Navigation
   
    toggleNavCategory = async (ID) => {
        this.props._filterSubCategory(ID)
        this.setState({
            catID: ID
           })
    }

    toggleNav = async () => {
        this.props._openNav()
    }
    
    async componentDidMount() {
       this.setState({
        catID:  this.props._subCategoryID
       })
    }

    render() {
        return (
            <div className="catring parent-megamenu pl-4  justify-between items-center">
               

              
                <div className='grid xxs:grid-cols-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 border-1 border-[gray] '>
                {this.props.productsSubData.map((item, i) => (


<button className={this.state.catID == item.ID ? 'bg-[#FFC04B] p-1 ' : ''  }  onClick={()=> this.toggleNavCategory(item.ID)}>
    <span className={this.state.catID == item.ID ? 'text-[white] font-[Lato-Medium]' : 'text-[gray] font-[Lato-Bold]'}>{item.Products_SubCategory_Name}</span>
</button>
))} 
              </div>
                
            </div>
        );
    }
}

export default Menu;