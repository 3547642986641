import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { faAdd, faHeart, faMagnifyingGlassPlus, faShare } from '@fortawesome/free-solid-svg-icons'
import Moment from 'moment'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #000',
  boxShadow: 24,
};

class ContentStand extends Component {

  constructor(props) {
      super(props)
      this.state = {
          open: false,
          recipesData:null,
          certifica:[],
          imageVisible:false
      }
  }

  async componentDidMount() {
    await this._getCertifica()
}

_closedataImageVisible= async () => {
  this.setState({imageVisible: false }) 
}

_openImage= async (url) => {
  this.props._openImage(url)
}

_getCertifica = async () => {
  try{
    
 await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getCertifica/select/`).then((res) => {
       this.setState({certifica: res.data })   
          
      })
 }
 catch (error) {
   
   console.log("_getCertifica" + error)
 }

}

render(){
  return (
<Modal
  open={this.props.openVisibleStand}
  onClose={this.props._closedataStandVisible}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box className='w-1/2' sx={style}>
    <div className="bg-[#fff] p-3" >
      <p className="text-center sm:text-[10px] md:text-[20px] lg:text-[30px]  font-[Lato-Bold] text-[#1b2542]">Stand Görselleri</p>
    </div>
    <div className="bg-[#ebe5df] w-full p-5 " >

    <div  className="grid lg:grid-cols-6  md:grid-cols-2 sm:grid-cols-3 grid-cols w-full p-1">

    {this.state.certifica.map((item, i) => (
   <div className="relative m-2">
       <button onClick={()=> this._openImage(this.state.certifica)} className="absolute bg-white border rounded-full -top-3 -right-3"> 
                                             <FontAwesomeIcon className="m-2"  color='black' size="lg" icon={faMagnifyingGlassPlus}/>
                                             </button>
      <button onClick={()=> this._openImage(this.state.certifica)}>
                                 <img key={i} src={'https://buharasubdomain.stechomeyazilim.info//' + item.Certifica_Url} className="img-fluid" alt="product-img" />
                             </button>
                             </div>
                              ))}
                               </div>
   
    </div> 
  </Box>


  
</Modal>
  )
}
};

export default ContentStand;
