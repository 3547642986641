import React, { Component } from 'react';
import { recipesRatingStars } from '../../../helper/helper';

import reviewfooter from '../../../assets/img/review-footer.png';
import axios from 'axios';
import Moment from 'moment';
import { processRatingStars, fetchFlavorIcon } from '../../../helper/helper';
import { toast } from 'react-toastify';
import {Collapse} from 'react-collapse';
import ReactStars from "react-rating-stars-component";

class Review extends Component {
    constructor() {
        super()
        this.state = {
            open: true,
            open2: true,
            recipesReview:[],
            recipesReviewTotal:{}
        }
    }

    async componentDidMount() {
        await this._getRecieps()
    }

    
    _changeTextName = async (text) => {
        this.setState({ reviewUserNickName : text.target.value})   
    }
    _changeTextComment= async (text) => {
        this.setState({ reviewText : text.target.value})   
    }

    _changeTextMail= async (text) => {
        this.setState({ reviewUserMail : text.target.value})   
    }

    _getRecieps = async () => {
        try{
        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipesReview/select/${this.props.recipesData.ID}`)
         .then((res) => {
             this.setState({recipesReview: res.data })   
            })

            console.log("lsndlkf",this.props.recipesData.ID)
            await axios.get(`https://buharaapi.stechomeyazilim.info:5210/recipes/review/${this.props.recipesData.ID}`)
            .then((res) => {
                console.log("sdnfl1",res.data.value)
                this.setState({recipesReviewTotal: res.data })   
               })
       }
       
       catch (error) {
         console.log("errorAS" + error)
       }
    }
    
    showToast(event, value, type) {
        toast.success(value, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
      }

    _postComment = async (event) => {
        try{
            event.preventDefault();
            console.log("klsd123nflks")
        var testDateUtc = Moment.utc(new Date());       
    var localDate = Moment(testDateUtc).local();
    const resIp = await axios.get('https://geolocation-db.com/json/')


    
    const data = {
        Recipes_Rewies_UUID: resIp.data.IPv4, // This is the body part
        Recipes_ID: this.props.recipesData.ID,
        Recipes_Rewies_CreatedDate: localDate,
        Recipes_Rewies_Text:this.state.reviewText,
        Recipes_Rewies_Star:this.state.newRating,
        Recipes_Rewies_NickName:this.state.reviewUserNickName,
        Recipes_Rewies_MailAdress: this.state.reviewUserMail
}

fetch("https://buharaapi.stechomeyazilim.info:5210/recipesReview/insert", {
method: "post",
body: JSON.stringify(data),
headers: { "Content-Type": "application/json" }
})
.then(async(res) =>{
  //await this.props._getRecieps()
  this.setState({ reviewUserNickName:null,reviewText:null,reviewUserMail:null,newRating:0})   

  this.showToast('Buhara Baharat', "Tarife başarıyla yorumunuzu eklediniz!", 'success')

  await this._getRecieps()

})
.catch(err => console.log("err1231231",err))
}
       
       catch (error) {
         console.log("errorAS" + error)
       }
    }

    _ratingChanged = (newRating) => {
        this.setState({ newRating:newRating })   
      }

    render() {
        return (
            <section className="section-padding restaurent-review smoothscroll m-10 border-1 bg-[white]" id="review">

<Collapse isOpened={this.props.openReview}>

                <div className="container ">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-header-left">
                                <h3 className="text-light-black text-[30px]  font-[Lato-Bold] text-[#c5aa77]">{this.state.recipesReviewTotal.value} Ortalama {this.props.recipesData.Recipes_Title}</h3>
                            </div>
                            <div className="restaurent-rating mb-xl-20">
                                <div className="star"> 
                                {processRatingStars(this.state.recipesReviewTotal.value)}
                                
                                </div> <span className="fs-12 text-light-black">{this.state.recipesReview.length} Değerlendirme</span>
                            </div>
                            
                            <div className="u-line" />
                        </div>
                        <div className="col-md-12">
                            {this.state.recipesReview.map((item, i) => (
                                <div key={i} className= {i % 2 === 0 ? "review-box border-bott-2 bg-[#e6e6e6]" : "review-box border-bott-2"}>
                                    <div className="review-user">
                                        <div className="review-user-img">
                                            <img src={'https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o='} className="rounded-circle" alt="#" />
                                            <div className="reviewer-name">
                                                <p className="text-light-black fw-600">{item.Recipes_Rewies_NickName} <small className="text-light-white fw-500">{item.userlocation}</small>
                                                </p> <i className="fas fa-trophy text-black" /><span className="text-light-black">{item.Recipes_Rewies_Text}</span>
                                            </div>
                                        </div>
                                        <div className="review-date">
                                            <span className="text-light-white">{Moment(item.Recipes_Rewies_CreatedDate).format('DD/MM/YYYY')}</span>
                                        </div>
                                    </div>
                                    <div className="ratings">
                                        {recipesRatingStars(item.Recipes_Rewies_Star)}
                                    </div>
                                 
                                </div>
                            ))}
                        </div>
                        {this.state.recipesReview.length == 0 ?
                         <div className="col-12">
                            <div className="review-img">
                                <img src={reviewfooter} className="img-fluid" alt="#" />
                                <div className="review-text">
                                    <h2 className="text-light-white mb-2 fw-600">İlk yorum yapanlardan biri olun</h2>
                                    <p className="text-light-white">Buhara Baharat</p>
                                </div>
                            </div>
                        </div> : null}
                    </div>

                    <div className="comment-form">

                                            <div className="section-header-left">
                                                <h3 className="text-light-black header-title">Yorum Yap</h3>
                                            </div>
                                            <form  onSubmit={this._postComment}>
                                                
                                
                                                <div className="row ">
                                                    <div className="col-md-4 justify-center">
                                                        <div className="form-group">
                                                            <label className="text-light-black fw-600">Adınız</label>
                                                            <input onChange={this._changeTextName} type="text" name="#" className="form-control form-control-submit" placeholder="Adınız" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 justify-center">
                                                        <div className="form-group">
                                                            <label className="text-light-black fw-600">Mail Adresiniz</label>
                                                            <input onChange={this._changeTextMail} type="email" name="#" className="form-control form-control-submit" placeholder="Mail Adresiniz" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 justify-center">
                                                        <div className="form-group">
                                                            <label className="text-light-black fw-600">Puanınız</label>
                                                            <ReactStars count={5} onChange={this._ratingChanged} size={24} activeColor="#ffd700"/>                                                        </div>
                                                    </div>
                                            

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="text-light-black fw-600">Yorumunuz</label>
                                                            <textarea  onChange={this._changeTextComment}  className="form-control form-control-submit" name="#" rows={6} placeholder="Yorumunuz" defaultValue={""} />
                                                        </div>
                                                        <button  className="btn-second btn-submit full-width text-[white]">Gönder</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                </div>

                </Collapse>
            </section>
        );
    }
}

export default Review;