import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { isMobile } from "react-device-detect";

// Doğru CSS dosyalarını içe aktarın
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';

class Banner extends Component {
    constructor() {
        super()
        this.state = {
            open: true,
            open2: true,
            sliderData:[]
        }
    }
    async componentDidMount() {
        await this._getSlider()
    }

    _getSlider = async () => {
        try{
        await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getSlider/select`)
         .then((res) => {
             this.setState({sliderData: res.data })   
            })
       }
       catch (error) {
         console.log("errorAS" + error)
       }
    }
    
    render() {
        const settings = {
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            speed: 1000,
            grabCursor: true,
            watchSlidesProgress: true,
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            navigation: true, // Navigation özelliğini etkinleştir
            modules: [Navigation, Autoplay], // Modülleri burada belirtin
        };


        return (
            isMobile == true ?  <Swiper tag="section" className="h-72 swiper-container p-relative" navigation {...settings}>
                {this.state.sliderData.map((item, i) => (
                    <SwiperSlide key={i} className="swiper-slide slide-item">
                        <img src={'https://buharasubdomain.stechomeyazilim.info/' + item.SliderImage} className="w-full h-full" alt="" />
                        <div className="transform-center">
                            <div className="container">
                                <div className={"row justify-content-center "}>
                                    <div className="justify-center items-center">
                                            <h1 className="text-[white] font-[Lato-Bold] sm:text-[20px] md:text-[40px] lg:text-[70px] text-center">{item.Slider_Text}</h1>
                                            <div className='content-center flex justify-center items-center'>
                                            <Link to="/yemek-tarifleri" className=" btn-second btn-submit  text-[white] p-2 font-[Lato-Bold] sm:text-[8px] md:text-[20px] lg:text-[25px] text-center">TARİFLERİ İNCELE</Link>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overlay overlay-bg" />
                    </SwiperSlide>
                ))}
            </Swiper> : <Swiper tag="section" className="about-us-slider swiper-container p-relative" navigation {...settings}>
                {this.state.sliderData.map((item, i) => (
                    <SwiperSlide key={i} className="swiper-slide slide-item">
                        <img src={'https://buharasubdomain.stechomeyazilim.info/' + item.SliderImage} className="img-fluid full-width" alt="" />
                        <div className="transform-center">
                            <div className="container">
                                <div className={"row justify-content-center "}>
                                    <div className="justify-center items-center">
                                            <h1 className="text-[white] font-[Lato-Bold] sm:text-[20px] md:text-[40px] lg:text-[70px] text-center">{item.Slider_Text}</h1>
                                           {item.Slider_Text != '' ? 
                                            <div className='content-center flex justify-center items-center'>
                                            <Link to="/yemek-tarifleri" className=" btn-second btn-submit  text-[white] p-2 font-[Lato-Bold] sm:text-[8px] md:text-[20px] lg:text-[25px] text-center">TARİFLERİ İNCELE</Link>
                                            </div> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overlay overlay-bg" />
                    </SwiperSlide>
                ))}
            </Swiper>
        );
    }
}

export default Banner;