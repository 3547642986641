import React, { useState, useEffect,Component } from "react";
import { Link } from 'react-router-dom';

import aboutimg1 from '../../../assets/img/about1.png';
import aboutimg2 from '../../../assets/img/about2.png';
import aboutimg3 from '../../../assets/img/about3.png';
import axios from 'axios';

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
  } from "react-google-maps";
  import mapStyles from './mapStyles'
  import base64 from 'react-native-base64';
  import ReactHtml from 'raw-html-react';
  import utf8 from "utf8";
  import ReactPlayer from 'react-player'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassPlus, faMoon } from "@fortawesome/free-solid-svg-icons";


import ContentImageMedia from "../../../Sidebar/ContentImageMedia";
  

class History extends Component {    
    constructor(props) {
    super(props);
   this.state = {
     dealerData:[],
     selectedLat:37.8604039,
     selectedLng:32.5372508,

    defaultProps : {
        center: {
          lat: 37.8604039,
          lng: 32.5372508
        },
        zoom: 11
      },
      showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    aboutHtml:null,
    allMedia:[],
    certifica:[],
    productionHtml:null,
    imageVisible:false
     }
}


onMarkerClick = (props, marker, e) =>{
console.log("nsldlfk",props, marker, e)
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    })
}

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

      
  _closeMediaVisible = () => {
    this.setState({
      imageVisible: false
    })
  }
  _openImageMedia = (url,type) => {
    this.setState({
      imageVisible: true,
      imageLink: url,
      imageLinkMediaType : type
    });

  }

async componentDidMount() {
  window.scrollTo(0, 0);

   await this._getBlog()
   await this._getAbout()
   await this._getCertifica()
   await this._getCerCertifica()
   await this._getProduction()

}

_getProduction = async () => {
  try{
    
 await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getProduction/select/`).then((res) => {
       this.setState({productionHtml: res.data[0].Production_Html })   
       console.log("12lk3m1lk9",res.data[0].Production_Html)
          
      })
 }
 catch (error) {
   
   console.log("_getCertifica" + error)
 }

}

_getCerCertifica = async () => {
  try{
    
 await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getCertifica/select/`).then((res) => {
       this.setState({certifica: res.data })   
          
      })
 }
 catch (error) {
   
   console.log("_getCertifica" + error)
 }

}

_getCertifica = async () => {
  try{
    
 await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getMedia/select/`).then((res) => {
       this.setState({allMedia: res.data })   
          
      })
 }
 catch (error) {
   
   console.log("_getCertifica" + error)
 }

}

 _getAbout = async () => {
  try{
    
 await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getAbout/select/`).then((res) => {
  this.setState({aboutHtml : res.data[0].About_Html})

 console.log("lsjdnflk",res.data[0].About_Html)
      })
 }
 catch (error) {
   
   console.log("_getCertifica" + error)
 }

}

_changeMaps= async (lat,long) => {
    this.setState({defaultProps: {
        center: {
          lat: lat,
          lng: long
        },
        zoom: 11
      } ,
      selectedLat:lat,
      selectedLng:long,

    
    },()=> {
        console.log("sjndfjkls",this.state.defaultProps)
    })
    }
    _setMap = async (item) => {

    this.setState({
        openDetail:true,
        mapDetail:item
    })
}
    

_getBlog = async () => {
try{
await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getDealer/select/`)
 .then(res => {
     this.setState({dealerData: res.data })})
}
catch (error) {
 
 console.log("errorAS" + error)
}
}

    render() {
       
          const AnyReactComponent = ({ text }) => <div>{text}</div>;

        return (
            <section className="aboutus section-padding">
                        
   
              

                <div className="container">


                                                 


               
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="history-title mb-md-40 m-1">

             
                                <h2 className="text-light-black sm:text-[25px] md:text-[30px] lg:text-[35px] text-light-green text-center font-[Lato-Bold] text-[#0a4022]">Buhara <span className="text-light-green  text-[#FFC04B]">Baharat</span>     <span className="sm:text-[25px] md:text-[30px] lg:text-[35px] text-light-green  font-[Lato-Bold] m-1 text-[#c5aa77]">Hakkımızda</span></h2>
                                {this.state.aboutHtml != null ?     
                                <div className="bg-[white] w-full  p-2">
            <p className="head-px2"><ReactHtml html={utf8.decode(base64.decode(base64.decode(this.state.aboutHtml)))}/></p>
            </div> : null}
                                {/*<Link className="btn-second btn-submit text-[white] justify-center items-center" >Bize Ulaşın</Link>*/}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <div className="histry-img mb-xs-20">
                                        <img src={aboutimg3} className="img-fluid full-width" alt="Histry" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <div className="histry-img mb-xl-20">
                                        <img src={aboutimg1} className="img-fluid full-width" alt="Histry" />
                                    </div>
                                    <div className="histry-img">
                                        <img src={aboutimg2} className="img-fluid full-width" alt="Histry" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   {/* this.state.productionHtml != null ? <div className="section-padding how-it-works bg-light-theme">
                <div className="container">
                    <div className="section-header-style-2 ">
                    <p className="text-center sm:text-[10px] md:text-[20px] lg:text-[30px] font-[Lato-Bold] text-[#1b2542]">Üretim</p>
                    </div>
                    <div className="row">
                    

             
             <ReactHtml html={utf8.decode(base64.decode(base64.decode(this.state.productionHtml)))}/>
         

                    </div>
                </div>
            </div> : null */}

          {/**   <div className="section-padding how-it-works bg-light-theme mt-1">
            <div className="bg-[#fff] p-3" >
      <p className="text-center sm:text-[10px] md:text-[20px] lg:text-[30px] font-[Lato-Bold] text-[#1b2542]">Medya</p>
    </div>

    <div  className="grid lg:grid-cols-3   md:grid-cols-2 sm:grid-cols-2 grid-cols w-full p-1">

    {this.state.allMedia.map((item, i) => (
      item.Media_Type == "video" ?
      <button onClick={()=> this._openImageMedia(item.Media_Url,item.Media_Type)} className='w-full  p-1 relative  m-2 border-2 '>
           <button onClick={()=> this._openImageMedia(item.Media_Url,item.Media_Type)} className="absolute bg-white border rounded-full -top-3 -right-0"> 
                                             <FontAwesomeIcon className="m-2"  color='#FFC04B' size="lg" icon={faMagnifyingGlassPlus}/>
                                             </button>
    <ReactPlayer controls={true}  width='100%' height='100%'  url={'https://buharasubdomain.stechomeyazilim.info/' +item.Media_Url} />
                             </button> :
                                   <button onClick={()=> this._openImageMedia(item.Media_Url,item.Media_Type)} className='relative w-full  p-1 border-2'>
                                             <button onClick={()=> this._openImageMedia(item.Media_Url,item.Media_Type)} className="absolute bg-white border rounded-full -top-3 -right-3"> 
                                             <FontAwesomeIcon className="m-2"  color='#FFC04B' size="lg" icon={faMagnifyingGlassPlus}/>
                                             </button>
                                 <img key={i} src={'https://buharasubdomain.stechomeyazilim.info//' + item.Media_Url} className="object-contain w-full h-full" alt="product-img" />
                                                            </button> 
                              ))}
                               </div>
   
   
    </div>*/}
    
 <div>
    <div className="bg-[#fff] p-3" >
      <p className="text-center sm:text-[10px] md:text-[20px] lg:text-[30px] font-['Alfa'] text-[#1b2542]">Kalite Belgelerimiz</p>
    </div>
    <div className="bg-[#ebe5df] w-full p-4" >

    <div  className="grid lg:grid-cols-3  md:grid-cols-3 sm:grid-cols-3 grid-cols w-full p-1">
    {this.state.certifica.map((item, i) => (
      <div>
                                 <img key={i} src={'https://buharasubdomain.stechomeyazilim.info//' + item.Certifica_Url} className="img-fluid" alt="product-img" />
                             </div>
                              ))}
                               </div>
   
    </div>
    </div>
                </div>

                <ContentImageMedia _imageLinkMediaType = {this.state.imageLinkMediaType} openVisibleImage={this.state.imageVisible} _imageLink = {this.state.imageLink} _closeImageVisible = {this._closeMediaVisible} _openImage={this._openImageMedia}/>

            </section>
        );
    }
}

export default History