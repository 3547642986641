import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation } from 'swiper/modules';

import axios from 'axios';


// install Swiper components

class Delivery extends Component {
    constructor() {
        super();
       this.state = { recipesData:[] }
}

async componentDidMount() {
    await this._getRecieps()
}


_getRecieps = async () => {
    try{
       
        
    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getBlog/select/`)
     .then((res) => {
         this.setState({recipesData: res.data },()=> {
         })   
            
        })
   }
   catch (error) {
     
     console.log("errorAS" + error)
   }


}


    render() {
        const settings = {
            slidesPerView: 1,
            spaceBetween: 15,
            loop: false,
            breakpoints: {
                576: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1400: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                },
                1600: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                },
                1800: {
                    slidesPerView: 6,
                    spaceBetween: 15,
                },
                2000: {
                    slidesPerView: 7,
                    spaceBetween: 15,
                }
            },
            modules: [Navigation], // Modülleri burada belirtin
        };
        return (
            <section className="section-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-header-left flex items-center justify-between mt-2">
                                <h3 className="sm:text-[15px] md:text-[20px] lg:text-[30px]  font-[Lato-Bold] text-[#c5aa77]">Blog Yazıları</h3>
                                <Link className='font-[Lato-Medium]'  to={ "/blog/"} state={{ categoryID: null}}>Hepsini Görüntüle</Link>
                            </div>
                        </div>
                        <div className="col-12">
                            <Swiper className="kosher-delivery-slider swiper-container" {...settings} navigation>
                                {this.state.recipesData.map((item, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="product-box-3">
                                            <div className="product-img">
                                                <Link  to={ "/blog-detay/" + (item.Blog_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}}>
                                                    <img src={'https://buharasubdomain.stechomeyazilim.info/' +  item.Blog_Image} className="img-fluid full-width" alt="#" />
                                                </Link>
                                            </div>
                                                <div className="padding-tb-10">
                                                  <Link to={ "/blog-detay/"   + (item.Blog_Title).replace('?', '').replace(/\s+/g, '-')} state={{ID: item.ID, _item:item}}   className="text-light-black">
                                                    
                                                    <ul className="food-description justify-center">
                                                        
                                                            <li className="text-center  font-[Lato-Medium] sm:text-[15px] md:text-[18px] lg:text-[20px] p-1" key={i}>{item.Blog_Title}</li>
                                               
                                                    </ul>
                                                    </Link>
                                              
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Delivery;