import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Content from '../sections/blogdetails/Content';
import { useLocation, Link } from "react-router-dom";

const Blogdetails = () => {
    const location = useLocation();
    const state = location.state;

    window.scrollTo(0, 0);

        return (
            <Fragment>
                <MetaTags>
                    <title>Buhara Baharat | Blog Detay</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
             
                <Content _item={state._item}/>
                <Footer/>
            </Fragment>
        );
    
}

export default Blogdetails;