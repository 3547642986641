import React, { Component } from 'react';


class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navmethod: false,
            selectCategory : ''
        };
    }
    // Navigation
   
    
    async componentDidMount() {
        this.setState({
            selectCategory:this.props._itemSub.ID
        })
    }
    _openCategory= async (ID,type) => {

        this.setState({
            selectCategory:ID
        })
     this.props._openCategory(ID,type)
    }

    render() {
        return (
            <div className="catring parent-megamenu p-2 grid xxs:grid-cols-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 items-center">
                <a  onClick={this.toggleNav}>
                    <span className='text-[white] font-[Lato-Medium] sm:text-[12px] md:text-[17px] lg:text-[22px] '>Kategoriler:</span>
                </a>

                <button className={this.state.selectCategory == '' ? 'bg-[#FFC04B] justify-center items-center p-3 rounded-lg' : 'justify-center items-center  p-3'  } onClick={()=> this._openCategory('', 'all')}>
                    <span className='text-[white] font-[Lato-Medium] sm:text-[10px] md:text-[14px] lg:text-[18px] text-center'>Tüm Ürünler</span>
                </button>

                {this.props.productsData.map((item, i) => (

                <button className= { this.state.selectCategory == item.ID ?  'bg-[#FFC04B] justify-center items-center p-3 rounded-lg' : 'justify-center items-center  p-3'  }  onClick={()=> this._openCategory(item.ID , 'category')}>
                    <span className='text-[white] font-[Lato-Medium] sm:text-[10px] md:text-[14px] lg:text-[18px] text-center'>{item.Products_Category_Name}</span>
                </button>
                ))} 
                
            </div>
        );
    }
}

export default Menu;