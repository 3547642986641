import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navmethod: false,
            visibleFilter:false
        };
    }
    // Navigation
   


    toggleNav = async () => {
        this.setState({visibleFilter: !this.state.visibleFilter })
        this.props._openNav()
    }

    

    render() {
        return (
            <div className="catring parent-megamenu pl-4 flex items-center">
                <p  className={classNames({ "active": this.state.navmethod })}>
                    <span className='text-[#0a4022] font-[Lato-Bold] xxs:text-[14px] sm:text-[14px] md:text-[18px] lg:text-[22px]'>Ambalaj Çeşitleri:</span>
                </p>

                
            </div>
        );
    }
}

export default Menu;