import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import ShoppingListDetail from '../sections/shoppinglist/ShoppingListDetail';
import axios from 'axios';
import Sidebar from "../../Sidebar";

class Shoppinglist extends Component {

    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>Buhara Baharat | Alışveriş Listesi</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>               
                 <ShoppingListDetail /> 
                <Footer/>
            </Fragment>
        );
    }
}

export default Shoppinglist;