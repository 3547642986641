import React, { Component, Fragment } from 'react';
import About from './About';
import Details from './Details';
import Review from './Review';
import Tabs from './Tabs';
import Top from './Top';
import axios from 'axios';

import HowToPrepare from './HowToPrepare/HowToPrepare';

class Content extends Component {
    constructor() {
        super()
        this.state = {
            open: true,
            open2: true,
            recipesData:[],
            recipesDataShoppingList:[],
            ipAdress:null,
            openReview:false
        }
    }

    async componentDidMount() {
        await this._getRecieps()
    }

    _openReview= async () => {
        this.setState({ openReview :true})   
    }
    
    _getRecieps = async () => {
        try{
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();

             await axios.get(`https://buharaapi.stechomeyazilim.info:5210/shoppingListCheck/select/${this.props._item.ID}`)
              .then((res1) => {
                  this.setState({recipesDataShoppingList: res1.data, ipAdress : data.ip })   
                 })
       }
       catch (error) {
         console.log("errorccAS" + error)
       }
    }
    
    render() {
        console.log("knsdlkfs1",this.props._recipesReview)

        return (
            <Fragment>
                <div className='bg-[#f8fafa] w-full'>
              { this.props._item != null ? <Top recipesData = {this.props._item}/>: null}
              { this.props._item != null  && this.props._recipesReview != null ? <Details _recipesReview = {this.props._recipesReview} recipesData = {this.props._item}/> : null }
                <Tabs _openReview ={this._openReview}/>
               {/* <Address/> */}
                 {/*  <Meals/> */}
                 { this.props._item != null ? <About _getRecieps = {this._getRecieps} recipesData = {this.props._item}/> : null }
               
                { this.props._item != null ? <HowToPrepare ipAdress={this.state.ipAdress} _getRecieps={this._getRecieps} recipesDataShoppingList = {this.state.recipesDataShoppingList} recipesData = {this.props._item}/> : null }

                { this.props._item != null ? <Review openReview={this.state.openReview} recipesData = {this.props._item}/> : null }
                
                {/* this.props._item != null ? <Offer  recipesData = {this.props._item}/>: null */}
                </div>
            </Fragment>
        );
    }
}

export default Content;